import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { months } from '../../utils/constants'

const getCellClass = (goal, actual) => {
  if (parseFloat(goal) <= parseFloat(actual)) {
    return 'table-success';
  } else {
    return 'table-danger';
  }
};

const getMonthCells = (props, row, id) => {
  if (props.state.report === 'Booked Volume' || props.state.report === 'Closed Volume') {
    return (
      <React.Fragment key={`monthcells-${id}`}>
        <td>{`$${(parseFloat(row.MonthlyGoals[id]) / 1E6).toFixed(2)}M`}</td>
        <td>{row.MonthlyActuals[id] === '0' ? '$0M' : `$${(parseFloat(row.MonthlyActuals[id]) / 1E6).toFixed(2)}M`}</td>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment key={`monthcells-${id}`}>
      <td>{parseFloat((row.MonthlyGoals[id]*2)/2).toFixed(1)}</td>
      <td>{parseFloat(Math.round(row.MonthlyActuals[id]*2)/2)}</td>
    </React.Fragment>
  );
};

const totalReducer = (data) => {
  return data.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
}

function BasicReport(props) {
  const useStyles = makeStyles({
    mainTable: {
      borderSpacing: 0,
      border: '1px solid #D3DAE6',
      borderCollapse: 'separate',
      fontFamily: 'Arial, Helvetica, sans-serif'
    },
    table: {
      border: '1px solid black',
      borderCollapse: 'separate',
      borderSpacing: 0,
      stickyTable: {
        borderCollapse: 'separate',
        borderSpacing: 0,
        border: '1px solid black'
      },
      td: {
        border: '1px solid black',
        borderCollapse: 'separate',
        borderSpacing: 0,
      },
      stickyCol: {
        backgroundColor: '#fff',
        position: 'sticky',
        left: 0,
        border: '1px solid black'
      }
    },
    tableTopHeaders: {
      border: '2px solid #D3DAE6'
    },
    standardCellRow: {
      '& td': {
        border: '1px solid #D3DAE6',
        padding: '3px'
      },
      '& th': {
        border: '1px solid #D3DAE6',
        padding: '3px'
      }
    },
    boldCellRow: {
      fontWeight: 'bold',
      '& td': {
        border: '1px solid #D3DAE6',
        padding: '6px'
      },
      '& th': {
        border: '1px solid #D3DAE6',
        padding: '5px'
      }
    }
  })
  const classes = useStyles()
  let nonQMonths = [0,1,3,4,6,7,9,10]
  let qMonths = [2,5,8,11]
  const ytdMonths = months.slice(0,props.month)
  if ([0,1,2].includes(props.month)) {
    nonQMonths = [0,1]
    qMonths = [2]
  } else if ([4,5,6].includes(props.month)) {
    nonQMonths = [0,1,3,4]
    qMonths = [2,5]
  } else if ([7,8,9].includes(props.month)) {
    nonQMonths = [0,1,3,4,6,7]
    qMonths = [2,5,8]
  }

  return (
    <div style={{ backgroundColor: '#fff', overflow: 'scroll' }}>
      <table className={classes.mainTable}>
        <thead>
          <tr className={classes.tableTopHeaders}>
            <th />
            <th colSpan='3' className='text-center'>YTD</th>
            {ytdMonths.map((month, index) => {
              let mapQ = 4
              if ([0,1,2].includes(index)) {
                mapQ = 1
              } else if ([3,4,5].includes(index)) {
                mapQ = 2
              } else if ([6,7,8].includes(index)) {
                mapQ = 3
              }
              if (nonQMonths.includes(index)) {
                return <th colSpan='2' className='text-center'>{month}</th>
              } else if (qMonths.includes(index)) {
                return (
                  <>
                    <th colSpan='2' className='text-center'>{month}</th>
                    <th colSpan='2' className='text-center'>Q{mapQ}</th>
                  </>
                )
              }
              return null
            })}
          </tr>
          <tr className='standard-cell-row'>
            <th scope='col'>EVP/Office</th>
            <th scope='col'>Goal</th>
            <th scope='col'>Actual</th>
            <th scope='col'>Variance</th>
            {ytdMonths.map((month, index) => {
              if (nonQMonths.includes(index)) {
                return (
                  <>
                    <th scope='col'>Goal</th>
                    <th scope='col'>Actual</th>
                  </>
                )
              } else if (qMonths.includes(index)) {
                return (
                  <>
                    <th scope='col'>Goal</th>
                    <th scope='col'>Actual</th>
                    <th scope='col'>Goal</th>
                    <th scope='col'>Actual</th>
                  </>
                )
              }
              return null
            })}
          </tr>
        </thead>
        <tbody>
          {/* section */}
          {props.data.DataSections.map(section => (
            <React.Fragment key={section.EVP}>
              {/* rows */}
              {section.Rows.map(row => (
                <tr className={classes.standardCellRow} key={row.OfficeName}>
                  <td className={classes.table}>{row.OfficeName}</td>
                  {(props.state.report === 'Booked Volume' || props.state.report === 'Closed Volume') &&
                    <>
                      <td>${(parseFloat(row.YtdGoal) / 1E6).toFixed(2)}M</td>
                      <td className={getCellClass(row.YtdGoal, row.YtdActual)}>${(parseFloat(row.YtdActual) / 1E6).toFixed(2)}M</td>
                    </>
                  }
                  {(props.state.report !== 'Booked Volume' && props.state.report !== 'Closed Volume') &&
                      <>
                        <td>{row.YtdGoal}</td>
                        <td className={getCellClass(row.YtdGoal, row.YtdActual)}>{row.YtdActual}</td>
                      </>
                  }
                  <td>{row.YtdVariance && row.YtdVariance !== "0" ? row.YtdVariance + '%' : 'N/A'}</td>
                  {ytdMonths.map((month, index) => {
                    let gSlicer
                    let aSlicer
                    if (index === 2) {
                      gSlicer = row.MonthlyGoals.slice(0, 3)
                      aSlicer = row.MonthlyActuals.slice(0, 3)
                    } else if (index === 5) {
                      gSlicer = row.MonthlyGoals.slice(3, 6)
                      aSlicer = row.MonthlyActuals.slice(3, 6)
                    } else if (index === 8) {
                      gSlicer = row.MonthlyGoals.slice(6, 9)
                      aSlicer = row.MonthlyActuals.slice(6, 9)
                    } else if (index === 11) {
                      gSlicer = row.MonthlyGoals.slice(9, 12)
                      aSlicer = row.MonthlyActuals.slice(9, 12)
                    }
                    if (nonQMonths.includes(index)) {
                      return getMonthCells(props, row, index)
                    } else if (qMonths.includes(index)) {
                      if (props.state.report === 'Booked Volume' || props.state.report === 'Closed Volume') {
                        return (
                          <>
                            {getMonthCells(props, row, index)}
                            <td>{`$${(parseFloat(totalReducer(gSlicer)) / 1E6).toFixed(2)}M`}</td>
                            <td className={getCellClass(totalReducer(gSlicer), totalReducer(aSlicer))}>
                            {parseFloat(totalReducer(aSlicer)) === 0 ? '$0M' : `$${(parseFloat(totalReducer(aSlicer)) / 1E6).toFixed(2)}M`}
                            </td>
                          </>
                        )
                      }
                      return (
                        <>
                          {getMonthCells(props, row, index)}
                          <td>{parseFloat(totalReducer(gSlicer).toFixed(1))}</td>
                          <td className={getCellClass(totalReducer(gSlicer), totalReducer(aSlicer))}>
                            {parseFloat(totalReducer(aSlicer).toFixed(0))}
                          </td>
                        </>
                      )
                    }
                    return null
                  })}
                </tr>
              ))}
              {/* /rows */}
              {/* total */}
              <tr className={classes.boldCellRow}>
                <td className={classes.table}>{section.EVP}</td>
                {(props.state.report === 'Booked Volume' || props.state.report === 'Closed Volume') &&
                <>
                  <td>${(parseFloat(section.YTDGoal) / 1E6).toFixed(2)}M</td>
                  <td className={getCellClass(section.YTDGoal, section.YTDActual)}>${(parseFloat(section.YTDActual) / 1E6).toFixed(2)}M</td>
                </>
                }
                {(props.state.report !== 'Booked Volume' && props.state.report !== 'Closed Volume') &&
                    <>
                    <td>{parseFloat(section.YTDGoal).toFixed(1)}</td>
                    <td className={getCellClass(section.YTDGoal, section.YTDActual)}>{parseFloat(section.YTDActual).toFixed(1)}</td>
                  </>
                }
                <td>{section.YTDVariance ? section.YTDVariance + '%' : 'N/A'}</td>
                {ytdMonths.map((month, index) => {
                    let gSlicer
                    let aSlicer
                    if (index === 2) {
                      gSlicer = section.MonthlyGoalTotal.slice(0, 3)
                      aSlicer = section.MonthlyActualTotal.slice(0, 3)
                    } else if (index === 5) {
                      gSlicer = section.MonthlyGoalTotal.slice(3, 6)
                      aSlicer = section.MonthlyActualTotal.slice(3, 6)
                    } else if (index === 8) {
                      gSlicer = section.MonthlyGoalTotal.slice(6, 9)
                      aSlicer = section.MonthlyActualTotal.slice(6, 9)
                    } else if (index === 11) {
                      gSlicer = section.MonthlyGoalTotal.slice(9, 12)
                      aSlicer = section.MonthlyActualTotal.slice(9, 12)
                    }
                    if (nonQMonths.includes(index)) {
                      if (props.state.report === 'Booked Volume' || props.state.report === 'Closed Volume') {
                        return (
                          <>
                            <td>{`$${(parseFloat(section.MonthlyGoalTotal[index]) / 1E6).toFixed(2)}M`}</td>
                            <td>{parseFloat(section.MonthlyActualTotal[index]) === 0 ? '$0M' : `$${(parseFloat(section.MonthlyActualTotal[index]) / 1E6).toFixed(2)}M`}</td>
                          </>
                        )
                      }
                      return (
                        <>
                          <td>{Math.round(section.MonthlyGoalTotal[index]*2)/2}</td>
                          <td>{Math.round(section.MonthlyActualTotal[index]*2)/2}</td>
                        </>
                      )
                    } else if (qMonths.includes(index)) {
                      if (props.state.report === 'Booked Volume' || props.state.report === 'Closed Volume') {
                        return (
                          <>
                            <td>{`$${(parseFloat(section.MonthlyGoalTotal[index]) / 1E6).toFixed(2)}M`}</td>
                            <td>{parseFloat(section.MonthlyActualTotal[index]) === 0 ? '$0M' : `$${(parseFloat(section.MonthlyActualTotal[index]) / 1E6).toFixed(2)}M`}</td>
                            <td>{`$${(parseFloat(totalReducer(gSlicer)) / 1E6).toFixed(2)}M`}</td>
                            <td className={getCellClass(totalReducer(gSlicer), totalReducer(aSlicer))}>
                              {parseFloat(totalReducer(aSlicer)) === 0 ? '$0M' : `$${(parseFloat(totalReducer(aSlicer)) / 1E6).toFixed(2)}M`}
                            </td>
                          </>
                        )
                      }
                      return (
                        <>
                          <td>{Math.round(section.MonthlyGoalTotal[index]*2)/2}</td>
                          <td>{Math.round(section.MonthlyActualTotal[index]*2)/2}</td>
                          <td>{parseFloat(totalReducer(gSlicer)).toFixed(1)}</td>
                          <td className={getCellClass(totalReducer(gSlicer), totalReducer(aSlicer))}>
                            {parseFloat(totalReducer(aSlicer).toFixed(0))}
                          </td>
                        </>
                      )
                    }
                    return null
                  })}
              </tr>
              {/* /total */}
            </React.Fragment>
          ))}
          {/* /section */}
          {/* grand total */}
          <tr className={classes.boldCellRow}>
            <td className={classes.table}>Totals</td>
            {(props.state.report === 'Booked Volume' || props.state.report === 'Closed Volume') &&
                <>
                  <td>${(parseFloat(props.data.YTDGoalTotal) / 1E6).toFixed(2)}M</td>
                  <td className={getCellClass(props.data.YTDGoalTotal, props.data.YTDActualTotal)}>${(parseFloat(props.data.YTDActualTotal) / 1E6).toFixed(2)}M</td>
                </>
            }
            {(props.state.report !== 'Booked Volume' && props.state.report !== 'Closed Volume') &&
                <>
                <td>{props.data.YTDGoalTotal}</td>
                <td className={getCellClass(props.data.YTDGoalTotal, props.data.YTDActualTotal)}>{props.data.YTDActualTotal}</td>
              </>
            }
            <td>{props.data.YTDTotalVariance ? props.data.YTDTotalVariance + '%' : 'N/A'}</td>
            {ytdMonths.map((month, index) => {
              let gSlicer
              let aSlicer
              if (index === 2) {
                gSlicer = props.data.MonthlyGoalTotals.slice(0, 3)
                aSlicer = props.data.MonthlyActualTotals.slice(0, 3)
              } else if (index === 5) {
                gSlicer = props.data.MonthlyGoalTotals.slice(3, 6)
                aSlicer = props.data.MonthlyActualTotals.slice(3, 6)
              } else if (index === 8) {
                gSlicer = props.data.MonthlyGoalTotals.slice(6, 9)
                aSlicer = props.data.MonthlyActualTotals.slice(6, 9)
              } else if (index === 11) {
                gSlicer = props.data.MonthlyGoalTotals.slice(9, 12)
                aSlicer = props.data.MonthlyActualTotals.slice(9, 12)
              }
              if (nonQMonths.includes(index)) {
                if (props.state.report === 'Booked Volume' || props.state.report === 'Closed Volume') {
                  return (
                    <>
                      <td>{`$${(parseFloat(props.data.MonthlyGoalTotals[index]) / 1E6).toFixed(2)}M`}</td>
                      <td>{parseFloat(props.data.MonthlyActualTotals[index]) === 0 ? '$0M' : `$${(parseFloat(props.data.MonthlyActualTotals[index]) / 1E6).toFixed(2)}M`}</td>
                    </>
                  )
                }
                return (
                  <>
                    <td>{Math.round(props.data.MonthlyGoalTotals[index]*2)/2}</td>
                    <td>{Math.round(props.data.MonthlyActualTotals[index]*2)/2}</td>
                  </>
                )
              } else if (qMonths.includes(index)) {
                if (props.state.report === 'Booked Volume' || props.state.report === 'Closed Volume') {
                  return (
                    <>
                      <td>{`$${(parseFloat(props.data.MonthlyGoalTotals[index]) / 1E6).toFixed(2)}M`}</td>
                      <td>{parseFloat(props.data.MonthlyActualTotals[index]) === 0 ? '$0M' : `$${(parseFloat(props.data.MonthlyActualTotals[index]) / 1E6).toFixed(2)}M`}</td>
                      <td>{`$${(parseFloat(totalReducer(gSlicer)) / 1E6).toFixed(2)}M`}</td>
                      <td className={getCellClass(totalReducer(gSlicer), totalReducer(aSlicer))}>
                        {parseFloat(totalReducer(aSlicer)) === 0 ? '$0M' : `$${(parseFloat(totalReducer(aSlicer)) / 1E6).toFixed(2)}M`}
                      </td>
                    </>
                  )
                }
                return (
                  <>
                    <td>{Math.round(props.data.MonthlyGoalTotals[index]*2)/2}</td>
                    <td>{Math.round(props.data.MonthlyActualTotals[index]*2)/2}</td>
                    <td>{parseFloat(totalReducer(gSlicer)).toFixed(1)}</td>
                    <td className={getCellClass(totalReducer(gSlicer), totalReducer(aSlicer))}>
                      {parseFloat(totalReducer(aSlicer)).toFixed(1)}
                    </td>
                  </>
                )
              }
              return null
            })}
          </tr>
          {/* /grand total */}
        </tbody>
      </table>
    </div>
  );
}

export default BasicReport;
