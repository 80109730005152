const years = [];
const now = new Date();
const range = (now.getFullYear() - 2015) + 1
const months = ['January', 'Febuary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

do {
    for (let x = 0; x < range; x++) {
      const year = now.getFullYear() - x
      years.push(year.toString());
    }
  } while (!years.includes('2015'));

export { years, months };