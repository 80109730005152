import React, { useState, useEffect, Suspense } from 'react'
import PropTypes from 'prop-types'
import LoadingSpinner from '../../components/LoadingSpinner'
import OfficeDashContent from '../../components/OfficeDashContent'
import AgentDash from '../AgentDash'
import environment from '../../relay/Environment'
import { QueryRenderer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { styled, useTheme } from '@mui/material/styles'
import { useHistory } from 'react-router'
import { makeStyles } from '@mui/styles'
import {
  Button,
  Card,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  List,
  ListItem
} from '@mui/material'
import {
  ChevronLeft,
  ChevronRight
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu'
import MuiAppBar from '@mui/material/AppBar';
import config from '../../utils/config'
import StagingIndicator from '../../components/StagingIndicator'
import './style.css'

export default function ManagerDash(props) {
  const officeName = props.officeName
  const officeID = props.office[0].Ident
  const role = props.role
  const [agentDetailModalToggle, setDetailModal] = useState(false)
  const [agentModalID, setAgentID] = useState('')
  const [agentModalName, setAgentName] = useState('')
  const [sortColumn, setSortColumn] = useState(null)
  const [sortDirection, setSortDirection] = useState(null)
  const [listingsType, setListingsType] = useState('YTD')
  const [volumeType, setVolumeType] = useState('YTD')
  const [tradeType, setTradeType] = useState('Pending')
  const [tradeDetail, setTradeDetail] = useState('Overview')
  const [sideOpen, setOpen] = useState(false);
  const token = window.sessionStorage.getItem('userToken')
  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const drawerWidth = 240;
  const theme = useTheme();
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    backgroundColor: '#522446',
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    })
  }));

  const useStyles = makeStyles({
    root: {
      flexGrow: 1,
    },
    dashContentContainer: {
      marginTop: '115px !important',
      display: 'flex',
      flexDirection: 'column',
    },
    linkContainer: {
      display: 'flex !important',
      width: '100% !important',
      justifyContent: 'left !important'
    },
    linkP: {
      textDecoration: 'none',
      marginLeft: '15px !important',
      marginBottom: 0,
      color: 'inherit',
      '&:hover': {
        textDecoration: 'none',
        color: 'inherit'
      }
    },
    link: {
      textDecoration: 'none',
      marginLeft: '15px !important',
      color: 'inherit',
      '&:hover': {
        textDecoration: 'none',
        color: 'inherit'
      }
    }
  })
  const classes = useStyles()

  useEffect(() => {
    if (!token || token === 'undefined') {
      window.sessionStorage.clear()
      history.push('/login?timedOut=true')
    }
  });

  const getAgentDetails = (agentID, agentName) => {
    setDetailModal(!agentDetailModalToggle)
    setAgentID((agentID).trim())
    setAgentName(agentName)
  }

  const resetAgentDetails = () => {
    setDetailModal(false)
    setAgentID('')
    setAgentName('')
  }

  const handleSort = (type) => {
    if (sortColumn === type && sortDirection === 'desc') {
      setSortDirection('asc')
    } else if (sortColumn === type && sortDirection === 'asc') {
      setSortDirection('desc')
    } else {
      setSortColumn(type)
      setSortDirection('desc')
    }
  }

  const handleTradeToggle = (e, type) => {
    if (type === 'Pending' && tradeType !== 'Pending') {
      setTradeType(type)
    } else if (type === 'Closed' && tradeType !== 'Closed') {
      setTradeType(type)
    }
  }

  const handleTradeDetail = (e, type) => {
    if (type === 'Overview' && tradeType !== 'Overview') {
      setTradeDetail(type)
    } else if (type === 'Detail' && tradeType !== 'Detail') {
      setTradeDetail(type)
    }
  }

  const handleListingsToggle = (e, type) => {
    if (type === 'YTD' && listingsType !== 'YTD') {
      setListingsType(type)
    } else if (type === 'R12' && listingsType !== 'R12') {
      setListingsType(type)
    }
  }

  const handleVolumeToggle = (e, type) => {
    if (type === 'YTD' && volumeType !== 'YTD') {
      setVolumeType(type)
    } else if (type === 'R12' && volumeType !== 'R12') {
      setVolumeType(type)
    }
  }

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
      query ManagerDashQuery($Ident: String!, $isTeam: Boolean!, $teamName: String, $teamMembers: [String]) {
        officeDashMetrics (Ident: $Ident, isTeam: $isTeam, teamName: $teamName, teamMembers: $teamMembers) {
          ListingData {
            Month
            HistValue
            CurrValue
            GoalValue
          }
          VolumeData {
            Month
            HistValue
            CurrValue
            GoalValue
          }
          Listing12Rolling {
            Month
            HistValue
            CurrValue
            GoalValue
          }
          Volume12Rolling {
            Month
            HistValue
            CurrValue
            GoalValue
          }
          AvgSalePrice {
            HistValue
            CurrValue
          }
          OfficeAgents {
            Hide
            EmployeeNum
            EmployeeId
            AgentName
            Listings
            Volume
            TotalSides
            AffilDate
            AgentGoal
            FinalGoal
            Notes
            Ident
            IsTerminated
            Teams {
                TeamName
                IsTeamLead
                TeamAddDate
                isCareerDev
                isSET
                id
            }
          }
          OfficeTransactionsClosed {
            id
            Ident
            Side
            CloseDate
            Agent
            VolumeCredit
            SellPrice
            Address
          }
          OfficeTransactionsPending {
            id
            Ident
            Side
            CloseDate
            Agent
            VolumeCredit
            SellPrice
            Address
          }
          VolumeGoal
          ListGoal
        }
        Groups (office: {Ident: $Ident, OfficeState: "PA"}) {
          edges {
            node {
              TeamName
              TeamLead
              TeamMembers {
                Teams {
                    TeamName
                    IsTeamLead
                    TeamAddDate
                }
              }
            }
          }
        }
        ...OfficeDashContent_query @arguments(Ident: $Ident, isTeam: $isTeam, teamName: $teamName, teamMembers: $teamMembers)
      }
    `}
      variables={{
        Ident: (officeID).toString(),
        isTeam: false
      }}
      render={({ error, props }) => {
        if (props) {
          const aspDisplay = ((officeID.toString().slice(0,2) === 'CM') || (officeID.toString().slice(0,3) === 'SET') ? 'd-none' : 'topCards mr-3')
          const avgSaleData = [
            { name: new Date().getFullYear(), value: props.officeDashMetrics.AvgSalePrice[0].CurrValue },
            { name: '5 Year Avg', value: props.officeDashMetrics.AvgSalePrice[0].HistValue }
          ]

          let agents = props.officeDashMetrics.OfficeAgents.filter(agent => !agent.Hide)
          if (sortColumn && sortDirection && sortColumn === 'num') {
            if (sortDirection === 'asc') {
              agents = [...agents].sort(function (a, b) {
                return a.EmployeeNum - b.EmployeeNum
              })
            } else if (sortDirection === 'desc') {
              agents = [...agents].sort(function (a, b) {
                return b.EmployeeNum - a.EmployeeNum
              })
            }
          } else if (sortColumn && sortDirection && sortColumn === 'name') {
            if (sortDirection === 'asc') {
              agents = [...agents].sort(function (a, b) {
                const nameA = a.AgentName.toUpperCase()
                const nameB = b.AgentName.toUpperCase()
                if (nameA < nameB) {
                  return -1
                }
                if (nameA > nameB) {
                  return 1
                }
                return 0
              })
            } else if (sortDirection === 'desc') {
              agents = [...agents].sort(function (a, b) {
                const nameA = a.AgentName.toUpperCase()
                const nameB = b.AgentName.toUpperCase()
                if (nameA > nameB) {
                  return -1
                }
                if (nameA < nameB) {
                  return 1
                }
                return 0
              })
            }
          } else if (sortColumn && sortDirection && sortColumn === 'vol') {
            if (sortDirection === 'asc') {
              agents = [...agents].sort(function (a, b) {
                return a.Volume - b.Volume
              })
            } else if (sortDirection === 'desc') {
              agents = [...agents].sort(function (a, b) {
                return b.Volume - a.Volume
              })
            }
          } else if (sortColumn && sortDirection && sortColumn === 'list') {
            if (sortDirection === 'asc') {
              agents = [...agents].sort(function (a, b) {
                return a.Listings - b.Listings
              })
            } else if (sortDirection === 'desc') {
              agents = [...agents].sort(function (a, b) {
                return b.Listings - a.Listings
              })
            }
          } else if (sortColumn && sortDirection && sortColumn === 'date') {
            if (sortDirection === 'asc') {
              agents = [...agents].sort(function (a, b) {
                const dateA = a.AffilDate.toUpperCase()
                const dateB = b.AffilDate.toUpperCase()
                if (dateA < dateB) {
                  return -1
                }
                if (dateA > dateB) {
                  return 1
                }
                return 0
              })
            } else if (sortDirection === 'desc') {
              agents = [...agents].sort(function (a, b) {
                const dateA = a.AffilDate.toUpperCase()
                const dateB = b.AffilDate.toUpperCase()
                if (dateA > dateB) {
                  return -1
                }
                if (dateA < dateB) {
                  return 1
                }
                return 0
              })
            }
          }

          return (
            <>
              {config.IS_STAGING === 'true' ? <StagingIndicator /> : null}
              <AppBar className='w-100 d-flex justify-content-between flex-row headerContainer' position="fixed" open={sideOpen}>
                <Toolbar className='w-100 d-flex justify-content-between flex-row'>
                  <div className='d-flex w-50'>
                    <IconButton
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                      sx={{ mr: 2, color: '#FFF !important', ...(sideOpen && { display: 'none' }) }}
                    >
                      <MenuIcon sx={{ color: '#FFF !important' }} />
                    </IconButton>
                    <Typography className='headerText' variant="h4" noWrap component="div">
                      Metrics for {officeName}
                    </Typography>
                  </div>
                  <Suspense fallback={LoadingSpinner}>
                  <Card className={aspDisplay} variant='outlined'>
                    <Typography variant='h6' className='text-center'>
                      YTD ASP: {avgSaleData[0].value ? `$${avgSaleData[0].value.toLocaleString()}` : 'No Data Available'}
                    </Typography>
                    <Typography variant='h6' className='text-center'>
                      Rolling 12: {avgSaleData[1].value ? `$${avgSaleData[1].value.toLocaleString()}` : 'No Data Available'}
                    </Typography>
                  </Card>
                </Suspense>
                </Toolbar>
              </AppBar>
              <Drawer
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,
                  '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                  },
                }}
                variant='persistent'
                anchor='left'
                open={sideOpen}
              >
                <DrawerHeader>
                  <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                  </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                  <ListItem button key='home' className={classes.linkContainer}>
                    <Link to='/home' id='navButtonH' className={classes.link}>Home</Link>
                  </ListItem>
                  {(role !== 'Manager' && role !== 'CareerDevManager') &&
                    <ListItem button key='dashboards' className={classes.linkContainer} onClick={() => {window.location.reload()}}>
                      <p className={classes.linkP}>Dashboards</p>
                    </ListItem>
                  }
                  <ListItem button key='reports' className={classes.linkContainer}>
                    <Link to='/reports' id='navButtonR' className={classes.link}>Reports</Link>
                  </ListItem>
                  <ListItem button key='goals' className={classes.linkContainer}>
                    <Link to='/goals' id='navButtonG' className={classes.link}>Goals Interface</Link>
                  </ListItem>
                  <ListItem button key='logout' className={classes.linkContainer} onClick={() => {window.sessionStorage.clear(); window.location.replace('/')}}>
                    <p className={classes.linkP}>Log Out</p>
                  </ListItem>
                </List>
              </Drawer>
              <div className={classes.dashContentContainer}>
                <OfficeDashContent
                  data={props}
                  query={props}
                  agents={agents}
                  listingsType={listingsType}
                  volumeType={volumeType}
                  officeID={officeID}
                  officeName={officeName}
                  tradeType={tradeType}
                  tradeDetail={tradeDetail}
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                  getAgentDetails={getAgentDetails}
                  handleSort={handleSort}
                  handleTradeDetail={handleTradeDetail}
                  handleTradeToggle={handleTradeToggle}
                  handleListingsToggle={handleListingsToggle}
                  handleVolumeToggle={handleVolumeToggle}
                />
              </div>
              <Modal
                show={agentDetailModalToggle}
                onHide={() => resetAgentDetails()}
                dialogClassName='detailModal'
              >
                <AgentDash searchID={agentModalID} agentName={agentModalName} office={officeID} officeName={officeName} />
                <Button className='w-20 mx-auto my-2' variant='contained' onClick={() => resetAgentDetails()}>Close</Button>
              </Modal>
            </>
          )
        } else if (error) {
          return <div>There was an error: {error}</div>
        }
        return (
          <LoadingSpinner />
        )
      }}
    />
  )
}

ManagerDash.propTypes = {
  role: PropTypes.string
}