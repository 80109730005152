/* global fetch */
import {
  Environment,
  Network,
  RecordSource,
  Store
} from 'relay-runtime'
const config = require('../utils/config.js')
const { validateAuth } = require('../utils/auth')

function handleErrors (response) {
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response
}

function fetchQuery (
  operation,
  variables
) {
  if (operation.name !== 'LoginScreenMutation') {
    return validateAuth().then(handleErrors).then(response => {
      if (response) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `${window.sessionStorage.getItem('userToken')}`
        }
      
        const request = {
          method: 'POST',
          headers,
          credentials: 'include',
          body: JSON.stringify({
            query: operation.text,
            variables
          })
        }
      
        return fetch(config.GRAPHQL_URL,
          request
        ).then(
          handleErrors
        ).then(response => {
          return response.json()
        })
      }
    })
  } else {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `${window.sessionStorage.getItem('userToken')}`
    }
  
    const request = {
      method: 'POST',
      headers,
      credentials: 'include',
      body: JSON.stringify({
        query: operation.text,
        variables
      })
    }
  
    return fetch(config.GRAPHQL_URL,
      request
    ).then(
      handleErrors
    ).then(response => {
      return response.json()
    })
  }
}

const environment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource())
})

export default environment
