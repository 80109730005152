import React, { useEffect, useReducer, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Container, makeStyles, Typography, LinearProgress, Snackbar, Button } from '@material-ui/core'
import { OfficeTable } from '../../components/OfficeTable/OfficeTable'
import GroupManagementModal from '../../components/GroupManagementModal'
import fetchGraphQL from '../../utils/fetchGraphQL'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAlert from '@material-ui/lab/Alert';
import { initialState, reducer } from './reducer'
import Header from '../../components/Header/Header'
import { Modal } from 'react-bootstrap'
import AgentDetailModal from '../../components/AgentDetailModal/index'
import { Role } from '../../constants'
import { useHistory, useLocation } from 'react-router'
import config from '../../utils/config'
import StagingIndicator from '../../components/StagingIndicator'

function Goals({ role, name, office, user, saveLocation, selections }) {
    const history = useHistory();
    const location = useLocation();
    const useStyles = makeStyles({
        accordion: {
            boxShadow: 'none !important'
        },
        main: {
            display: "flex",
            flexDirection: 'column',
            boxShadow: '0'
        },
        agentListContainer: {
            padding: "0px 15px",
            display: 'flex'
        },
        executiveAccordion: { backgroundColor: '#522446', color: 'white', marginBottom: '5px', padding: '20px' },
        executiveBody: {
            height: '100%'
        }
    })
    const classes = useStyles()
    const [state, dispatch] = useReducer(reducer, { role, name, office, user, selections }, initializer)
    const token = window.sessionStorage.getItem('userToken')

    /** @todo: this needs to be shifted into the reducer function */
    const [agentModal, setAgentModal] = useState(false)
    const [agentModalDetails, setAgentModalDetails] = useState({
        modalType: 'detail',
        searchID: '',
        agentName: '',
        unHideAgent: '',
        showToast: false,
        toastMessage: '',
        toastClass: ''
    })
    const toggleAgentModal = (EmployeeNum, AgentName) => {
        setAgentModalDetails({ ...agentModalDetails, searchID: EmployeeNum, agentName: AgentName })
        setAgentModal(!agentModal)
    }

    useEffect(() => {
        if (token && token !== 'undefined') {
            saveLocation('/goals');
        } else {
            window.sessionStorage.clear()
            history.push('/login?timedOut=true')
        }
      }, [location, saveLocation, token, history]);

    useEffect(() => {
        if (!token || token === 'undefined') {
            window.sessionStorage.clear()
            history.push('/login?timedOut=true')
        }
        let isMounted = true;
        dispatch({ type: 'TOGGLE_PAGE_LOAD' })
        fetchGraphQL(createQueryBasedOnRole(state))
            .then(response => {
                if (response.errors) {
                    dispatch({ type: 'TOGGLE_SNACKBAR', payload: { type: 'error', info: 'Please contact your IT admin or report this error.', showSnackbar: !state.snackbar.showSnackbar } })
                    return
                } else {
                    if (!isMounted) {
                        return null;
                    } else {
                        dispatch({ type: 'TOGGLE_PAGE_LOAD' })
                        dispatch({ type: 'FETCH_DATA', payload: { ...response.data, role: role } })
                        return;
                    }
                }
            })
            .catch(response => dispatch({ type: 'TOGGLE_SNACKBAR', payload: { type: 'error', info: response.message, showSnackbar: !state.snackbar.showSnackbar } }))
        return () => isMounted = false
    }, [state?.endDate, state?.startDate, state?.office]) // eslint-disable-line react-hooks/exhaustive-deps


    /** @todo: explore if this should be wrapped in an effect */
    const updateAgent = agentPayload => {
        if (!token || token === 'undefined') {
            window.sessionStorage.clear()
            history.push('/login?timedOut=true')
        }
        dispatch({ type: 'TOGGLE_PAGE_LOAD' })
        const { AgentGoal, FinalGoal, Ident, OfficeName, Notes, EmployeeNum, Hide } = agentPayload
        fetchGraphQL(`mutation agentGoalSaveMutation($input: AgentGoalSaveInput!) { agentGoalSaveMutation(input: $input) { Response } }`, {
            input: {
                AgentGoal, FinalGoal, Ident, OfficeName, Notes, EmployeeNum, Hide
            }
        })
            .then(response => {
                if (!response) throw Error('Could not make a request. Please contact your IT admin or report this error.')
                if (response.errors && response.errors[0] && response.errors[0].message === 'jwt must be provided') {
                    history.push('/')
                }
                if (response.errors) throw Error('Could not make a request. Please contact your IT admin or report this error.')
                return
            })
            .then(_ => {
                dispatch({ type: 'TOGGLE_PAGE_LOAD' })
                dispatch({ type: 'UPDATE_AGENT', payload: agentPayload })
                dispatch({ type: 'TOGGLE_SNACKBAR', payload: { type: 'success', showSnackbar: !state.snackbar.showSnackbar, info: 'Updated agent' } });
            })
            .catch(err => dispatch({ type: 'TOGGLE_SNACKBAR', payload: { type: 'error', info: err.message, showSnackbar: !state.snackbar.showSnackbar } }))
    }

    const updateOfficePref = (prefPayload) => {
        if (!token || token === 'undefined') {
            window.sessionStorage.clear()
            history.push('/login?timedOut=true')
        }
        dispatch({ type: 'TOGGLE_PAGE_LOAD' })
        const { LastOffice, LastSouthOffice, LastCentralOffice, LastNorthOffice, LastCDOffice, LastSETOffice } = prefPayload
        fetchGraphQL(`mutation userPreferencesSaveMutation($input: UserPreferencesSaveInput!) { userPreferencesSaveMutation(input: $input) { Response } }`, {
            input: {
                Username: user,
                UpdateType: 'office',
                LastOffice,
                LastSouthOffice,
                LastCentralOffice,
                LastNorthOffice,
                LastCDOffice,
                LastSETOffice
            }
        })
            .then(response => {
                if (!response) throw Error('An issue occurred while trying to save office preferences. Please contact your IT admin or report this error.')
                if (response.errors && response.errors[0] && response.errors[0].message === 'jwt must be provided') {
                    history.push('/')
                }
                if (response.errors) throw Error('Something went wrong while trying to save office preferences. Please contact your IT admin or report this error.')
                return
            })
            .then(_ => {
                dispatch({ type: 'TOGGLE_PAGE_LOAD' })
                dispatch({ type: 'UPDATE_PREFERENCES', payload: prefPayload })
            })
            .catch(err => dispatch({ type: 'TOGGLE_SNACKBAR', payload: { type: 'error', info: err.message, showSnackbar: !state.snackbar.showSnackbar } }))
    }

    const updateGridPref = (prefPayload) => {
        if (!token || token === 'undefined') {
            window.sessionStorage.clear()
            history.push('/login?timedOut=true')
        }
        let updateType
        if (prefPayload.updateType === 'column') {
            updateType = 'gridColumn'
        } else if (prefPayload.updateType === 'filter') {
            updateType = 'gridFilter'
        } else if (prefPayload.updateType === 'sort') {
            updateType = 'gridSort'
        }
        dispatch({ type: 'TOGGLE_PAGE_LOAD' })
        fetchGraphQL(`mutation userPreferencesSaveMutation($input: UserPreferencesSaveInput!) { userPreferencesSaveMutation(input: $input) { Response } }`, {
            input: {
                Username: user,
                UpdateType: updateType,
                GridType: prefPayload.gridType,
                GridSettings: prefPayload.gridSettings
            }
        })
            .then(response => {
                if (!response) throw Error('An issue occurred while trying to save grid preferences. Please contact your IT admin or report this error.')
                if (response.errors && response.errors[0] && response.errors[0].message === 'jwt must be provided') {
                    history.push('/')
                }
                if (response.errors) throw Error('Something went wrong while trying to save grid preferences. Please contact your IT admin or report this error.')
                return
            })
            .then(_ => {
                dispatch({ type: 'TOGGLE_PAGE_LOAD' })
                dispatch({ type: 'UPDATE_PREFERENCES', payload: prefPayload })
            })
            .catch(err => dispatch({ type: 'TOGGLE_SNACKBAR', payload: { type: 'error', info: err.message, showSnackbar: !state.snackbar.showSnackbar } }))
    }


    /**
     * @todo: These rendering functions should be moved based on
     * React router's Auth redirection pattern. Data that is passed to the OfficeTable component
     * should only use `state` instead of `data`
     */
    const renderManagerView = state => {
        return (
            <div className={classes.agentListContainer}>
                <OfficeTable
                    displayType='Manager'
                    renderedEVP=''
                    state={state}
                    dispatch={dispatch}
                    toggleAgentModal={toggleAgentModal}
                    data={[state.Office]}
                    updateAgent={updateAgent}
                    office={JSON.parse(state.office)[0].Ident}
                    userSelections={state.UserPreferences}
                    updateOfficePref={updateOfficePref}
                    updateGridPref={updateGridPref}
                />
            </div>
        )
    }
    const renderExecutiveView = data => {
        return data.EVPs.map((exec, index) => {
            return (
                <Accordion className={classes.accordion} key={index} defaultExpanded={true} sx={{ boxShadow: 0 }}>
                    <AccordionSummary
                        className={classes.executiveAccordion}
                        expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                        aria-controls={`panel-${index}`}
                        id={`panel-${index}`}
                        onClick={() => dispatch({ type: 'TOGGLE_PANEL' })}
                    >
                        <Typography variant="h6" component="h2">{exec.EVPName}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.executiveBody}>
                        <OfficeTable
                            displayType='Executive'
                            renderedEVP={exec} state={state}
                            dispatch={dispatch}
                            toggleAgentModal={toggleAgentModal} 
                            data={exec.Offices}
                            updateAgent={updateAgent}
                            key={index}
                            userSelections={data.UserPreferences}
                            updateOfficePref={updateOfficePref}
                            updateGridPref={updateGridPref}
                        />
                    </AccordionDetails>
                </Accordion>
            )
        })
    }
    const renderEVPView = state => {
        return (
            <div className={classes.agentListContainer}>
                {<OfficeTable
                    displayType='EVP'
                    renderedEVP=''
                    state={state}
                    dispatch={dispatch}
                    toggleAgentModal={toggleAgentModal}
                    data={state.Offices}
                    updateAgent={updateAgent}
                    userSelections={state.UserPreferences}
                    updateOfficePref={updateOfficePref}
                    updateGridPref={updateGridPref}
                />}
            </div>
        )
    }
    const renderView = state => {
        switch (state.role) {
            case Role.CareerDevManager:
            case Role.Manager:
                return renderManagerView(state)
            case Role.CareerDevEVP:
            case Role.EVP:
                return renderEVPView(state)
            case Role.Executive:
            case Role.SuperAdmin:
                return renderExecutiveView(state)
            default:
                return <div>You have an invalid user role. Please contact your IT desk.</div>
        }
    }

    return (
        <>
            {config.IS_STAGING === 'true' ? <StagingIndicator /> : null}
            <Container id='mainGoalsContainer' maxWidth={false} disableGutters={true}>
                <Header dispatch={dispatch} state={state} />
                {state.isLoading ? <LinearProgress /> : null}
                <main className={classes.main}>
                    {(state && (state.EVPs || state.Offices || state.Office)) ? renderView(state) : <LinearProgress />}
                </main>
                <GroupManagementModal
                    dispatch={dispatch}
                    state={state}
                />
                {<Modal
                    show={agentModal}
                    onHide={() => setAgentModal(!agentModal)}
                    size='xl'
                    dialogClassName='detailModal w-90 mx-auto'
                >
                    <AgentDetailModal
                        searchID={agentModalDetails.searchID}
                        agentName={agentModalDetails.agentName}
                        modalType='detail'
                    />
                    <Button onClick={() => setAgentModal(!agentModal)}>Close</Button>
                </Modal>}
            </Container>
            <Snackbar open={state.snackbar.type === 'success' && state.snackbar.showSnackbar} onClose={() => dispatch({ type: 'TOGGLE_SNACKBAR', payload: { type: '', info: '', showSnackbar: !state.snackbar.showSnackbar } })} autoHideDuration={5000} >
                <MuiAlert elevation={6} variant="filled" severity="success" >
                    {state.snackbar.info}
                </MuiAlert>
            </Snackbar>
            <Snackbar open={state.snackbar.type === 'warning' && state.snackbar.showSnackbar} onClose={() => dispatch({ type: 'TOGGLE_SNACKBAR', payload: { type: '', showSnackbar: !state.snackbar.showSnackbar } })} >
                <MuiAlert elevation={6} variant="filled" severity="warning" onClose={() => dispatch({ type: 'TOGGLE_SNACKBAR', payload: { type: 'warning', info: '', showSnackbar: !state.snackbar.showSnackbar } })}>
                    {state.snackbar.info}
                </MuiAlert>
            </Snackbar>
            <Snackbar open={state.snackbar.type === 'error' && state.snackbar.showSnackbar} onClose={() => dispatch({ type: 'TOGGLE_SNACKBAR', payload: { type: '', showSnackbar: !state.snackbar.showSnackbar } })} >
                <MuiAlert elevation={6} variant="filled" severity="error" onClose={() => dispatch({ type: 'TOGGLE_SNACKBAR', payload: { type: 'error', info: '', showSnackbar: !state.snackbar.showSnackbar } })}>
                    There's an error: {state.snackbar.info}
                </MuiAlert>
            </Snackbar>
        </>
    )
}

function createQueryBasedOnRole(state) {
    switch (state.role) {
        case Role.Executive:
        case Role.SuperAdmin:
            return `
                query QueriesExecutiveQuery {
                    EVPs(startDate: "${state.startDate}", endDate: "${state.endDate}") {
                        EVPName
                        id
                        EVPOfficeIds
                        Offices {
                            id
                            OfficeName
                            Volume
                            Ident
                            TotalSides
                            FinalGoalTotal
                            AgentGoalTotal
                            Agents {
                                Hide
                                EmployeeNum
                                EmployeeId
                                AgentName
                                Listings
                                Volume
                                TotalSides
                                AffilDate
                                AgentGoal
                                FinalGoal
                                Notes
                                Ident
                                IsTerminated
                                Teams {
                                    TeamName
                                    IsTeamLead
                                    TeamAddDate
                                    isCareerDev
                                    isSET
                                    id
                                }
                                Ident
                            }
                        }
                    }
                    CareerDevLeads {
                        Ident
                        EmployeeName
                    }
                    UserPreferences(username: "${state.user}") {
                        LastSouthOffice
                        LastCentralOffice
                        LastNorthOffice
                        LastCDOffice
                        LastSETOffice
                        GridSettings {
                            Office {
                                PinnedColumns
                                HiddenColumns
                                Sort
                                Filters
                            }
                            Agent {
                                PinnedColumns
                                HiddenColumns
                                Sort
                                Filters
                            }
                        }
                    }
                }`
        case Role.Manager:
            return `
            query QueriesManagersQuery {
                Office(Ident: "${JSON.parse(state.office)[0].Ident}", startDate: "${state.startDate}", endDate: "${state.endDate}") {
                    id
                    OfficeName
                    Volume
                    Ident
                    TotalSides
                    FinalGoalTotal
                    AgentGoalTotal
                    Agents {
                        Hide
                        EmployeeNum
                        EmployeeId
                        AgentName
                        Listings
                        Volume
                        TotalSides
                        AffilDate
                        AgentGoal
                        FinalGoal
                        Notes
                        IsTerminated
                        Teams {
                            TeamName
                            IsTeamLead
                            TeamAddDate
                            isCareerDev
                            isSET
                            id
                        }
                        Ident
                        IsTerminated
                    }
                }
                UserPreferences(username: "${state.user}") {
                    LastOffice
                    GridSettings {
                        Office {
                            PinnedColumns
                            HiddenColumns
                            Sort
                            Filters
                        }
                        Agent {
                            PinnedColumns
                            HiddenColumns
                            Sort
                            Filters
                        }
                    }
                }
            }`
        case Role.CareerDevManager:
            return `
           query QueriesManagersQuery {
                Office(Ident: "${JSON.parse(state.office)[0].Ident}", startDate: "${state.startDate}", endDate: "${state.endDate}") {
                    id
                    OfficeName
                    Volume
                    Ident
                    TotalSides
                    FinalGoalTotal
                    AgentGoalTotal
                    Agents {
                        Hide
                        EmployeeNum
                        EmployeeId
                        AgentName
                        Listings
                        Volume
                        TotalSides
                        AffilDate
                        AgentGoal
                        FinalGoal
                        Notes
                        IsTerminated
                        Teams {
                            TeamName
                            IsTeamLead
                            TeamAddDate
                            isCareerDev
                            isSET
                            id
                        }
                        Ident
                        IsTerminated
                    }
                }
                CareerDevLeads {
                    Ident
                    EmployeeName
                }
                EVPs(startDate: "${state.startDate}", endDate: "${state.endDate}") {
                    EVPName
                    id
                    EVPOfficeIds
                    Offices {
                        id
                        OfficeName
                        Volume
                        Ident
                        TotalSides
                        FinalGoalTotal
                        AgentGoalTotal
                        Agents {
                            Hide
                            EmployeeNum
                            EmployeeId
                            AgentName
                            Listings
                            Volume
                            TotalSides
                            AffilDate
                            AgentGoal
                            FinalGoal
                            Notes
                            Ident
                            IsTerminated
                            Teams {
                                TeamName
                                IsTeamLead
                                TeamAddDate
                                isCareerDev
                                isSET
                                id
                            }
                            Ident
                        }
                    }
                }
                UserPreferences(username: "${state.user}") {
                    LastOffice
                    GridSettings {
                        Office {
                            PinnedColumns
                            HiddenColumns
                            Sort
                            Filters
                        }
                        Agent {
                            PinnedColumns
                            HiddenColumns
                            Sort
                            Filters
                        }
                    }
                }
            }`
        case Role.CareerDevEVP:
            const evpIds = JSON.parse(state.office).map(doc => `"${doc.Ident}"`)
            return `
            query QueriesEVPQuery {
                Offices(Idents: [${evpIds}], startDate: "${state.startDate}", endDate: "${state.endDate}") {
                    id
                    OfficeName
                    Volume
                    Ident
                    TotalSides
                    FinalGoalTotal
                    AgentGoalTotal
                    Agents {
                        Hide
                        EmployeeNum
                        EmployeeId
                        AgentName
                        Listings
                        Volume
                        TotalSides
                        AffilDate
                        AgentGoal
                        FinalGoal
                        Notes
                        Ident
                        IsTerminated
                        Teams {
                            TeamName
                            IsTeamLead
                            TeamAddDate
                            isCareerDev
                            isSET
                            id
                        }
                    }
                }
                CareerDevLeads {
                    Ident
                    EmployeeName
                }
                EVPs(startDate: "${state.startDate}", endDate: "${state.endDate}") {
                    EVPName
                    id
                    EVPOfficeIds
                    Offices {
                        id
                        OfficeName
                        Volume
                        Ident
                        TotalSides
                        FinalGoalTotal
                        AgentGoalTotal
                        Agents {
                            Hide
                            EmployeeNum
                            EmployeeId
                            AgentName
                            Listings
                            Volume
                            TotalSides
                            AffilDate
                            AgentGoal
                            FinalGoal
                            Notes
                            Ident
                            IsTerminated
                            Teams {
                                TeamName
                                IsTeamLead
                                TeamAddDate
                                isCareerDev
                                isSET
                                id
                            }
                            Ident
                        }
                    }
                }
                UserPreferences(username: "${state.user}") {
                    LastOffice
                    GridSettings {
                        Office {
                            PinnedColumns
                            HiddenColumns
                            Sort
                            Filters
                        }
                        Agent {
                            PinnedColumns
                            HiddenColumns
                            Sort
                            Filters
                        }
                    }
                }
            }`
        default:
            const ids = JSON.parse(state.office).map(doc => `"${doc.Ident}"`)
            return `
            query QueriesEVPQuery {
                Offices(Idents: [${ids}], , startDate: "${state.startDate}", endDate: "${state.endDate}") {
                    id
                    OfficeName
                    Volume
                    Ident
                    TotalSides
                    FinalGoalTotal
                    AgentGoalTotal
                    Agents {
                        Hide
                        EmployeeNum
                        EmployeeId
                        AgentName
                        Listings
                        Volume
                        TotalSides
                        AffilDate
                        AgentGoal
                        FinalGoal
                        Notes
                        Ident
                        IsTerminated
                        Teams {
                            TeamName
                            IsTeamLead
                            TeamAddDate
                            id
                        }
                    }
                }
                UserPreferences(username: "${state.user}") {
                    LastOffice
                    GridSettings {
                        Office {
                            PinnedColumns
                            HiddenColumns
                            Sort
                            Filters
                        }
                        Agent {
                            PinnedColumns
                            HiddenColumns
                            Sort
                            Filters
                        }
                    }
                }
            }`
    }
}

/**
 * This function initializes the state lazily. Since we are not passing the reducer
 * from the root of the app, we must apply the props provided in a non-mutable way
 * to the initial state.
 * @param {*} state
 */
function initializer(state) {
    return { ...state, ...initialState }
}

export default Goals
