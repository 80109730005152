import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ManagerDash from '../../dashboards/ManagerDash'
import LoadingSpinner from '../../components/LoadingSpinner'
import OfficeSelector from '../../components/OfficeSelector'
import environment from '../../relay/Environment'
import { QueryRenderer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { useLocation } from 'react-router'
import { styled, useTheme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import {
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  List,
  ListItem
} from '@mui/material'
import {
  ChevronLeft,
  ChevronRight
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu'
import MuiAppBar from '@mui/material/AppBar';
import { Link } from 'react-router-dom'
import config from '../../utils/config'
import { useHistory } from 'react-router'
import StagingIndicator from '../../components/StagingIndicator'

export default function DashWelcomeScreen({ role, saveLocation }) {
  const userOffice = window.sessionStorage.getItem('userOffice')
  const userOfficeName = window.sessionStorage.getItem('userOfficeName')
  const [selectedOffice, setSelectedOffice] = useState(userOffice ? JSON.parse(userOffice) : null)
  const [selectedOfficeName, setSelectedOfficeName] = useState(userOfficeName || null)
  const token = window.sessionStorage.getItem('userToken')
  const history = useHistory();

  const drawerWidth = 240;
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    backgroundColor: '#522446',
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    })
  }));

  const useStyles = makeStyles({
    root: {
      flexGrow: 1,
    },
    headerContainer: {
      padding: 50,
      justifyContent: 'start',
      width: '100%',
      display: 'flex',
      backgroundColor: '#522446',
      color: 'white'
    },
    headerText: {
      marginLeft: '50px !important',
      paddingTop: '10px !important',
      paddingBottom: '10px !important',
      marginTop: '10px !important',
      marginBottom: '10px !important'
    },
    link: {
      textDecoration: 'none',
      color: 'inherit',
      '&:hover': {
        textDecoration: 'none',
        color: 'inherit'
      }
    },
    oSelectContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '85px !important'
    }
  })
  const classes = useStyles()
  const theme = useTheme();

  const location = useLocation();
  const [sideOpen, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (token && token !== 'undefined') {
      saveLocation('/dashboards');
    } else {
      window.sessionStorage.clear()
      history.push('/login?timedOut=true')
  }
  }, [location, saveLocation, token, history]);

  useEffect(() => {
    if (!token || token === 'undefined') {
      window.sessionStorage.clear()
      history.push('/login?timedOut=true')
    }
  });

  const handleSelectedOffice = (e, ident, name, id) => {
    e.preventDefault(e)
    setSelectedOffice([{ Ident: ident.toString(), Id: id }])
    setSelectedOfficeName(name)
  }

  const renderView = () => {
    if (role && selectedOffice && selectedOffice.length === 1) {
      return (
        <div className='bg-light'>
          <ManagerDash role={role} office={selectedOffice} officeName={selectedOfficeName} />
        </div>
      )
    } else if (role && role !== 'Manager' && (!selectedOffice || selectedOffice.length !== 1)) {
      return (
        <>
          {config.IS_STAGING === 'true' ? <StagingIndicator /> : null}
          <div id='welcomeSelectContainer' className='w-50 mx-auto'>
            <QueryRenderer
              environment={environment}
              query={graphql`
                query DashWelcomeScreenQuery ($Ident: [OfficeInput!]) {
                  officeList (Ident: $Ident) {
                    edges {
                      node {
                        id
                        Ident
                        OfficeName
                      }
                    }
                  }
                }
              `}
              variables={{ Ident: JSON.parse(window.sessionStorage.getItem('userOffice')) }}
              render={({ error, props }) => {
                if (props && !error) {
                  if (props && (!selectedOffice || selectedOffice.length !== 1)) {
                    return (
                      <>
                      <AppBar className='w-100 d-flex justify-content-between flex-row' position="fixed" open={sideOpen}>
                        <Toolbar>
                          <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(sideOpen && { display: 'none' }) }}
                          >
                            <MenuIcon />
                          </IconButton>
                          <Typography className={classes.headerText} variant="h4" noWrap component="div">
                            Dashboards
                          </Typography>
                        </Toolbar>
                      </AppBar>
                      <Drawer
                        sx={{
                          width: drawerWidth,
                          flexShrink: 0,
                          '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                          },
                        }}
                        variant='persistent'
                        anchor='left'
                        open={sideOpen}
                      >
                        <DrawerHeader>
                          <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                          </IconButton>
                        </DrawerHeader>
                        <Divider />
                        <List>
                          <ListItem button key='home'>
                            <Link to='/home' id='navButtonH' className={classes.link}>Home</Link>
                          </ListItem>
                          <ListItem button key='reports'>
                            <Link to='/reports' id='navButtonR' className={classes.link}>Reports</Link>
                          </ListItem>
                          <ListItem button key='goals'>
                            <Link to='/goals' id='navButtonG' className={classes.link}>Goals Interface</Link>
                          </ListItem>
                          <ListItem button key='logout' onClick={() => {window.sessionStorage.clear(); window.location.replace('/')}}>Log Out</ListItem>
                        </List>
                      </Drawer>
                      <div open={sideOpen} className={classes.oSelectContainer}>
                        <h5 className='w-100 text-center mt-4'> 
                          Please select an office:
                        </h5>
                        <OfficeSelector source={props} onOfficeSelected={handleSelectedOffice} />
                      </div>
                      </>
                    )
                  }
                } else if (error) {
                  return <div>There was an error: {error}</div>
                }
                return (
                  <LoadingSpinner />
                )
              }}
            />
          </div>
        </>
      )
    }  else if (!role) {
      return <div>There was an error!</div>
    } else {
      return (
        <LoadingSpinner />
      )
    }
  }

  return (
    // Add a check here for EVP role and offices > 1
    // Route offices and selection helpers down to EVPDash
    <>
      {renderView()}
    </>
  )
}

DashWelcomeScreen.propTypes = {
  role: PropTypes.string,
  saveLocation: PropTypes.func
}
