import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@mui/material';

export default function StagingIndicator(props) {
  // style handlers
  const useStyles = makeStyles({
    stagingBarContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#FCB953',
      position: 'fixed',
      zIndex: 2000,
      top: 0
    },
  })
  const classes = useStyles()
	return(
		<div className={classes.stagingBarContainer}>
      <Typography variant='p'>STAGING SITE</Typography>
    </div>
	)
}