import React from 'react'
import environment from '../../relay/Environment'
import config from '../../utils/config'
import { Button, Form } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { checkToken } from '../../utils/auth.js'
import './style.css'

class LoginScreen extends React.Component {
  constructor (props) {
    super(props)

    const params = new URLSearchParams(document.location.search);
    const isOut = params.get('timedOut')

    this.state = {
      loginError: false,
      redirect: null,
      showSnackbar: (isOut === 'true' ? true : false)
    }
  }

  handleSnackbar = (type) => {
    if (type === 'close') {
      this.setState({
        showSnackbar: false
      })
    }
  }

  handleSubmitMutation = (e) => {
    e.preventDefault(e)
    const variables = {
      input: {
        Username: e.target.children[1].children[1].value,
        Password: e.target.children[2].children[1].value
      }
    }
    commitMutation(
      environment,
      {
        mutation: loginMutation,
        variables,
        onCompleted: (response, errors) => {
          if (!errors && response.userLoginMutation) {
            checkToken(response.userLoginMutation).then(() => {
              const lastScreen = response.userLoginMutation.SavedSelections?.LastScreen
              this.setState({
                redirect: lastScreen && lastScreen !== '' ? lastScreen : '/home'
              })
            }).catch(err => {
              this.setState({
                loginError: true
              })
              console.error(err)
            })
          } else {
            this.setState({
              loginError: true
            })
          }
        },
        onError: err => {
          console.error(err)
          this.setState({
            loginError: true
          })
        }
      }
    )
  }

  render () {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    if (config.MAINT_MODE === 'true') {
      return (
        <div id='loginContainer' className='w-100 h-100 d-flex justify-content-center align-items-center'>
        <div className='loginLeft w-100'>
          <div className='loginLogo' />
        </div>
        <div className='loginRight w-100'>
          <Form id='loginForm' onSubmit={this.handleSubmitMutation}>
            <Form.Control.Feedback id='failedLoginFeedback' type='invalid' className='d-block w-75 text-center mx-auto'>
              <p className='h5'>The system is currently down for scheduled maintenance. Try back later!</p>
            </Form.Control.Feedback>
            <div className='d-flex w-100 justify-content-around'>
              <Form.Label className='loginInput'>Username</Form.Label>
              <Form.Control id='loginUser' readOnly className='loginInputField' />
            </div>
            <div className='d-flex w-100 justify-content-around'>
              <Form.Label className='loginInput'>Password</Form.Label>
              <Form.Control id='loginPass' readOnly className='loginInputField' type='password' />
            </div>
            <Button id='loginFormSubmit' disabled className='w-50' type='submit'>Log In</Button>
          </Form>
        </div>
      </div>
      )
    }
    return (
      <div id='loginContainer' className='w-100 h-100 d-flex justify-content-center align-items-center'>
        <div className='loginLeft w-100'>
          <div className='loginLogo' />
        </div>
        <div className='loginRight w-100'>
          <Form id='loginForm' onSubmit={this.handleSubmitMutation}>
            <Form.Control.Feedback id='failedLoginFeedback' type='invalid' className={this.state.loginError ? 'd-block w-50 mx-auto' : 'd-none'}>
              <p className='h5'>That username or password is incorrect</p>
            </Form.Control.Feedback>
            <div className='d-flex w-100 justify-content-around'>
              <Form.Label className='loginInput'>Username</Form.Label>
              <Form.Control id='loginUser' required className='loginInputField' />
            </div>
            <div className='d-flex w-100 justify-content-around'>
              <Form.Label className='loginInput'>Password</Form.Label>
              <Form.Control id='loginPass' required className='loginInputField' type='password' />
            </div>
            <Button id='loginFormSubmit' className='w-50' type='submit'>Log In</Button>
          </Form>
        </div>
        <Snackbar open={this.state.showSnackbar} onClose={() => this.handleSnackbar('close')} id='timeoutBanner' >
            <MuiAlert elevation={6} variant="filled" severity="error">
              You were logged out for security purposes. Please log back in, and be aware that any changes you were attempting to make since your last save may not have been saved.
            </MuiAlert>
        </Snackbar>
      </div>
    )
  }
}

const loginMutation = graphql`
  mutation LoginScreenMutation(
    $input: UserLoginInput!
  ) {
    userLoginMutation(input: $input) {
      JWT
      Expires
      Roles
      OfficeID {
        Ident
        OfficeState
      }
      OfficeInfo
      User
      SavedSelections {
        LastScreen
        LastSouthOffice
        LastCentralOffice
        LastNorthOffice
        LastCDOffice
        LastSETOffice
        LastOffice
        GridSettings {
          Office {
            PinnedColumns
            HiddenColumns
            Sort
            Filters
          }
          Agent {
            PinnedColumns
            HiddenColumns
            Sort
            Filters
          }
        }
      }
    }
  }
`

export default LoginScreen
