/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AgentDetailModalHiddenQueryVariables = {|
  officeID: string
|};
export type AgentDetailModalHiddenQueryResponse = {|
  +hiddenAgents: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +EmployeeName: ?string,
        +Ident: ?string,
        +AffilDate: ?string,
      |}
    |}>
  |}
|};
export type AgentDetailModalHiddenQuery = {|
  variables: AgentDetailModalHiddenQueryVariables,
  response: AgentDetailModalHiddenQueryResponse,
|};
*/


/*
query AgentDetailModalHiddenQuery(
  $officeID: String!
) {
  hiddenAgents(officeID: $officeID) {
    edges {
      node {
        EmployeeName
        Ident
        AffilDate
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "officeID"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "officeID",
        "variableName": "officeID"
      }
    ],
    "concreteType": "AgentConnection",
    "kind": "LinkedField",
    "name": "hiddenAgents",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AgentEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Agent",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "EmployeeName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "Ident",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "AffilDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentDetailModalHiddenQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AgentDetailModalHiddenQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "72584d2bf28e4eff734a0fcd6b17ca26",
    "id": null,
    "metadata": {},
    "name": "AgentDetailModalHiddenQuery",
    "operationKind": "query",
    "text": "query AgentDetailModalHiddenQuery(\n  $officeID: String!\n) {\n  hiddenAgents(officeID: $officeID) {\n    edges {\n      node {\n        EmployeeName\n        Ident\n        AffilDate\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ca8789c5f08b2f6f3e0f67c4eb679d75';

module.exports = node;
