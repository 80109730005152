module.exports = {
  JWT_KEY: process.env.REACT_APP_JWT_KEY,
  GRAPHQL_URL: process.env.REACT_APP_GRAPHQL_URL,
  MAINT_MODE: process.env.REACT_APP_MAINT_MODE,
  TEST_ENV: process.env.REACT_APP_TEST_ENV,
  MUI_PRO_KEY: process.env.REACT_APP_MUI_PRO_KEY,
  EVP_SOUTH: process.env.REACT_APP_EVP_SOUTH,
  EVP_CENTRAL: process.env.REACT_APP_EVP_CENTRAL,
  EVP_NORTH: process.env.REACT_APP_EVP_NORTH,
  EVP_CD: process.env.REACT_APP_EVP_CD,
  EVP_SET: process.env.REACT_APP_EVP_SET,
  IS_STAGING: process.env.REACT_APP_IS_STAGING
}
