/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OfficeDashContent_query$ref = any;
export type OfficeDashContentTeamQueryVariables = {|
  Ident: string,
  isTeam: boolean,
  teamName?: ?string,
  teamMembers?: ?$ReadOnlyArray<?string>,
|};
export type OfficeDashContentTeamQueryResponse = {|
  +$fragmentRefs: OfficeDashContent_query$ref
|};
export type OfficeDashContentTeamQuery = {|
  variables: OfficeDashContentTeamQueryVariables,
  response: OfficeDashContentTeamQueryResponse,
|};
*/


/*
query OfficeDashContentTeamQuery(
  $Ident: String!
  $isTeam: Boolean!
  $teamName: String
  $teamMembers: [String]
) {
  ...OfficeDashContent_query_NoHVt
}

fragment OfficeDashContent_query_NoHVt on Query {
  officeDashMetrics(Ident: $Ident, isTeam: $isTeam, teamName: $teamName, teamMembers: $teamMembers) {
    ListingData {
      Month
      HistValue
      CurrValue
      GoalValue
    }
    VolumeData {
      Month
      HistValue
      CurrValue
      GoalValue
    }
    Listing12Rolling {
      Month
      HistValue
      CurrValue
      GoalValue
    }
    Volume12Rolling {
      Month
      HistValue
      CurrValue
      GoalValue
    }
    AvgSalePrice {
      HistValue
      CurrValue
    }
    OfficeAgents {
      Hide
      EmployeeNum
      EmployeeId
      AgentName
      Listings
      Volume
      TotalSides
      AffilDate
      AgentGoal
      FinalGoal
      Notes
      Ident
      IsTerminated
      Teams {
        TeamName
        IsTeamLead
        TeamAddDate
        isCareerDev
        isSET
      }
    }
    OfficeTransactionsClosed {
      id
      Ident
      Side
      CloseDate
      Agent
      VolumeCredit
      SellPrice
      Address
    }
    OfficeTransactionsPending {
      id
      Ident
      Side
      CloseDate
      Agent
      VolumeCredit
      SellPrice
      Address
    }
    VolumeGoal
    ListGoal
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "Ident"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isTeam"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamMembers"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teamName"
},
v4 = [
  {
    "kind": "Variable",
    "name": "Ident",
    "variableName": "Ident"
  },
  {
    "kind": "Variable",
    "name": "isTeam",
    "variableName": "isTeam"
  },
  {
    "kind": "Variable",
    "name": "teamMembers",
    "variableName": "teamMembers"
  },
  {
    "kind": "Variable",
    "name": "teamName",
    "variableName": "teamName"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "HistValue",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "CurrValue",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "Month",
    "storageKey": null
  },
  (v5/*: any*/),
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "GoalValue",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Ident",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  (v8/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "Side",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "CloseDate",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "Agent",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "VolumeCredit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "SellPrice",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "Address",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OfficeDashContentTeamQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "kind": "FragmentSpread",
        "name": "OfficeDashContent_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "OfficeDashContentTeamQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Office",
        "kind": "LinkedField",
        "name": "officeDashMetrics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OfficeDashboard",
            "kind": "LinkedField",
            "name": "ListingData",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OfficeDashboard",
            "kind": "LinkedField",
            "name": "VolumeData",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OfficeDashboard",
            "kind": "LinkedField",
            "name": "Listing12Rolling",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OfficeDashboard",
            "kind": "LinkedField",
            "name": "Volume12Rolling",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OfficeDashboard",
            "kind": "LinkedField",
            "name": "AvgSalePrice",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Agent",
            "kind": "LinkedField",
            "name": "OfficeAgents",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "Hide",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "EmployeeNum",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "EmployeeId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "AgentName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "Listings",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "Volume",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "TotalSides",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "AffilDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "AgentGoal",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "FinalGoal",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "Notes",
                "storageKey": null
              },
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "IsTerminated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AgentTeams",
                "kind": "LinkedField",
                "name": "Teams",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "TeamName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "IsTeamLead",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "TeamAddDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCareerDev",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isSET",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Transaction",
            "kind": "LinkedField",
            "name": "OfficeTransactionsClosed",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Transaction",
            "kind": "LinkedField",
            "name": "OfficeTransactionsPending",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "VolumeGoal",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ListGoal",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9038dec5519986fd53e814780bf3cdd2",
    "id": null,
    "metadata": {},
    "name": "OfficeDashContentTeamQuery",
    "operationKind": "query",
    "text": "query OfficeDashContentTeamQuery(\n  $Ident: String!\n  $isTeam: Boolean!\n  $teamName: String\n  $teamMembers: [String]\n) {\n  ...OfficeDashContent_query_NoHVt\n}\n\nfragment OfficeDashContent_query_NoHVt on Query {\n  officeDashMetrics(Ident: $Ident, isTeam: $isTeam, teamName: $teamName, teamMembers: $teamMembers) {\n    ListingData {\n      Month\n      HistValue\n      CurrValue\n      GoalValue\n    }\n    VolumeData {\n      Month\n      HistValue\n      CurrValue\n      GoalValue\n    }\n    Listing12Rolling {\n      Month\n      HistValue\n      CurrValue\n      GoalValue\n    }\n    Volume12Rolling {\n      Month\n      HistValue\n      CurrValue\n      GoalValue\n    }\n    AvgSalePrice {\n      HistValue\n      CurrValue\n    }\n    OfficeAgents {\n      Hide\n      EmployeeNum\n      EmployeeId\n      AgentName\n      Listings\n      Volume\n      TotalSides\n      AffilDate\n      AgentGoal\n      FinalGoal\n      Notes\n      Ident\n      IsTerminated\n      Teams {\n        TeamName\n        IsTeamLead\n        TeamAddDate\n        isCareerDev\n        isSET\n      }\n    }\n    OfficeTransactionsClosed {\n      id\n      Ident\n      Side\n      CloseDate\n      Agent\n      VolumeCredit\n      SellPrice\n      Address\n    }\n    OfficeTransactionsPending {\n      id\n      Ident\n      Side\n      CloseDate\n      Agent\n      VolumeCredit\n      SellPrice\n      Address\n    }\n    VolumeGoal\n    ListGoal\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9969f8fb693775fa8fa3ac5d4f47eca7';

module.exports = node;
