import { useEffect, useState } from 'react';
import { ModalType } from "../constants"
import { selectCareerDevAgents, selectOffice, selectSETAgents } from '../screens/Goals/stateSelectors';

/**
 * Creates a group based on modal type and whether an employe id is provided or not.
 * Different group types can be created based on which inputs are provided.
 * @param {Object} state
 * @param {ModalType} modalType
 * @param {Number} employeeId - the EmployeeNum attribute
 */
export default function useGroup(state) {
    const [group, innerSetGroup] = useState(createGroup(state))
    useEffect(() => {
        innerSetGroup(createGroup(state))
        // eslint-disable-next-line
    }, [state.modalType])
    const setGroup = newState => {
        innerSetGroup(_ => {
            return {
                ...group,
                ...newState
            }
        })
    }
    return [group, setGroup]
}

function createGroup(state) {

    const blankState = {
        groupAgents: [],
        groupName: '',
        groupLead: {},
        groupLeadList: [],
        agentSearchList: [],
        query: '',
        groupId: ''
    }
    let selectedAgents, groupLead, groupLeadList
    const userName = window.sessionStorage.getItem('userOfficeName')
    switch (state.modalType) {
        case ModalType.CreateCareerDevGroup:
            return {
                ...blankState,
                groupLeadList: state.CareerDevLeads.map(lead => {
                    return {
                        EmployeeNum: lead.Ident,
                        AgentName: lead.EmployeeName
                    }
                })
            }
        case ModalType.CreateSET:
            return {
                ...blankState,
                groupLeadList: [{
                    Username: userName
                }]
            }
        case ModalType.CreateGroup:
            return blankState
        case ModalType.EditCareerDevGroup:
            selectedAgents = selectCareerDevAgents(state)
                .filter(agent => agent.Teams
                    .find(team => team.TeamName === state.selectedTeamName))
            groupLeadList = state.CareerDevLeads.map(lead => {
                return {
                    EmployeeNum: lead.Ident,
                    AgentName: lead.EmployeeName
                }
            })
            groupLead = groupLeadList.filter(lead => lead.EmployeeNum === state.selectedOfficeIdent)[0]
            return {
                groupAgents: selectedAgents,
                groupName: state.selectedTeamName,
                groupLead,
                agentSearchList: [],
                groupLeadList,
                query: '',
                groupId: state.selectedTeamID
            }
        case ModalType.EditSET:
            selectedAgents = selectSETAgents(state)
                .filter(agent => agent.Teams
                    .find(team => team.TeamName === state.selectedTeamName))
            return {
                groupAgents: selectedAgents,
                groupName: state.selectedTeamName,
                agentSearchList: [],
                query: '',
                groupId: state.selectedTeamID
            }
        case ModalType.EditGroup:
            if (!state.selectedOfficeIdent || !state.selectedTeamName) return blankState
            // creates a list of agents each with an array of Teams containing only one object: the team that
            // corresponds to the state.selectedTeamName
            selectedAgents = selectOffice(state.selectedOfficeIdent, state).Agents
                .filter(agent => agent.Teams
                    .filter(team => team.TeamName === state.selectedTeamName).length > 0)
            if (selectedAgents.length > 0) {
                groupLead = selectedAgents.find(agent => agent.Teams[0].IsTeamLead === "true" || agent.Teams[0].IsTeamLead.slice(0, 2) === 'CM')
                if (!groupLead) groupLead = ''
                groupLeadList = selectedAgents
                    .map(agent => {
                        return {
                            EmployeeNum: agent.EmployeeNum,
                            AgentName: agent.AgentName.trim()
                        }
                    })
                return {
                    groupAgents: selectedAgents,
                    groupName: selectedAgents[0].Teams[0].TeamName,
                    groupLead,
                    agentSearchList: [],
                    groupLeadList,
                    query: '',
                    groupId: selectedAgents[0].Teams[0].id
                }
            }
            return blankState
        default:
            return blankState
    }
}