import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import { Role, TableViewType } from '../constants.js'
import { currencyFormatter, millionsFormatter } from '../utils/formatters.js';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

/**
 * Creates columns based on the input type
 * @param { TableViewType } type
 * @param { Role } role
 * @returns {Array}
 */
export default function useColumns(type = {}, role, devLeads, state) {
    const [columns, innerSetColumns] = useState(createColumns(type, role, devLeads, state))
    const setColumns = newState => {
        innerSetColumns(_ => createColumns(newState, role, devLeads, state))
    }
    return [columns, setColumns, devLeads]
}

function createColumns(type, role, devLeads, state) {
    switch (type) {
        case TableViewType.Office:
            return [
                {
                    field: 'Hide',
                    headerName: 'Hide?',
                    width: 150,
                    editable: true,
                    type: 'boolean',
                    hide: false
                },
                {
                    field: 'EmployeeNum',
                    headerName: 'Employee #',
                    type: 'string',
                    width: 170,
                    editable: false,
                    hide: true
                },
                {
                    field: 'AgentName',
                    headerName: 'Agent Name',
                    width: 280,
                    editable: false,
                    renderCell: (params) => {
                        return (
                            <div style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>
                                {(params.row.AgentName)}
                                {params.row.IsTerminated ? <Tooltip title='Employee is no longer affiliated' aria-label='lead' placement='bottom'><span><FontAwesomeIcon className='icons' icon='exclamation-circle' color="red" /></span></Tooltip> : null}
                            </div>
                        );
                    }
                },
                {
                    field: 'TeamName',
                    headerName: 'Team Name(s)',
                    type: 'string',
                    width: 300,
                    editable: false,
                    hide: role === Role.CareerDevManager || role === Role.CareerDevEVP,
                    renderCell: (params) => {
                        if ((role === Role.CareerDevManager) && (state.Office.Ident.includes('CM') || state.Office.Ident.includes('SET'))) {
                            return (<span >
                                {state.Office.OfficeName}
                            </span>)
                        }
                        return (
                            <div style={{ overflow: 'auto', flex: true, flexDirection: 'column' }}>
                                <span key={params.row.Teams[0]?.id}>
                                    <span id={`teamName-${params.row.Teams[0]?.id}`} style={((role === Role.Manager && !state.Office.Ident.includes('CM')) || (role === Role.EVP || role === Role.CareerDevEVP || role === Role.SuperAdmin)) ? { textDecoration: 'underline', color: 'blue', cursor: 'pointer' } : {}}>
                                        {params.row.Teams[0]?.TeamName}
                                    </span>
                                </span>
                            </div>
                        );
                    },
                    sortComparator: (v1, v2, param1, param2) => {
                        const p1Team = param1.api.getRow(param1.id).Teams[0]?.TeamName || ''
                        const p2Team = param2.api.getRow(param2.id).Teams[0]?.TeamName || ''
                        return p1Team.localeCompare(p2Team)
                    },
                    valueGetter: (params) => params.row.Teams[0]?.TeamName || ''
                },
                {
                    field: 'TeamLead',
                    headerName: 'Team Lead?',
                    type: 'string',
                    width: 170,
                    editable: false,
                    hide: role === Role.CareerDevManager || role === Role.CareerDevEVP,
                    renderCell: (params) => {
                        if (params.row.Teams[0] && params.row.Teams[0].IsTeamLead === 'true') {
                            return 'Yes'
                        } else if (params.row.Teams[0]) {
                            return 'No'
                        } else {
                            return ''
                        }
                    },
                    sortComparator: (v1, v2, param1, param2) => {
                        const p1Team = param1.api.getRow(param1.id).Teams[0]?.IsTeamLead || ''
                        const p2Team = param2.api.getRow(param2.id).Teams[0]?.IsTeamLead || ''
                        return p1Team.localeCompare(p2Team)
                    },
                    valueGetter: (params) => params.row.Teams[0]?.IsTeamLead || ''
                },
                {
                    field: 'TeamAddDate',
                    headerName: 'Date Added to Team',
                    type: 'string',
                    width: 230,
                    editable: false,
                    renderCell: (params) => {
                        return (
                            params.row.Teams[0]?.TeamAddDate || false
                        );
                    },
                    sortComparator: (v1, v2, param1, param2) => {
                        const p1Team = param1.api.getRow(param1.id).Teams[0]?.TeamAddDate || ''
                        const p2Team = param2.api.getRow(param2.id).Teams[0]?.TeamAddDate || ''
                        return p1Team.localeCompare(p2Team)
                    },
                    valueGetter: (params) => params.row.Teams[0]?.TeamAddDate || ''
                },
                {
                    field: 'IsTerminated',
                    headerName: 'Is Terminated?',
                    editable: false,
                    hide: true
                },
                {
                    field: 'AffilDate',
                    headerName: 'Affiliation Date',
                    width: 200,
                    editable: false,
                    hide: true
                },
                {
                    field: 'Listings',
                    headerName: 'Listings',
                    width: 150,
                    editable: false,
                    type: 'number'
                },
                {
                    field: 'ATotalSides',
                    headerName: 'Total Sides',
                    width: 150,
                    editable: false,
                    type: 'number'
                },
                {
                    field: 'AVolume',
                    headerName: 'Volume (MM)',
                    width: 180,
                    editable: false,
                    type: 'number',
                    valueFormatter: ({ value }) => millionsFormatter(value),
                    sortComparator: currencyComparator('AVolume')
                },
                {
                    field: 'AAvgSalePrice',
                    headerName: 'Avg Sale Price',
                    width: 180,
                    editable: false,
                    type: 'number',
                    valueFormatter: ({ value }) => currencyFormatter(value),
                    sortComparator: currencyComparator('AAvgSalePrice')
                },
                {
                    field: 'AgentGoal',
                    width: 200,
                    editable: true,
                    type: 'number',
                    valueFormatter: ({ value }) => millionsFormatter(value),
                    renderHeader: (params) => (
                        <>
                            <Tooltip sx={{color: '#fff'}} title="Goal columns are dynamic and are based on the selected time range. If part of the selected range is this year and during the Goal Setting window (September - December), the columns will reflect the next year's goals. Otherwise, the columns default to the year reflected in the selected End Date.">
                                <IconButton aria-label="progress to goal">
                                    <HelpOutlineIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                            <div>Agent Goal (MM)</div>
                        </>
                    )
                },
                {
                    field: 'FinalGoal',
                    width: 200,
                    editable: state.role === Role.SuperAdmin || state.role === Role.EVP,
                    type: 'number',
                    valueFormatter: ({ value }) => millionsFormatter(value),
                    renderHeader: (params) => (
                        <>
                            <Tooltip sx={{color: '#fff'}} title="Goal columns are dynamic and are based on the selected time range. If part of the selected range is this year and during the Goal Setting window (September - December), the columns will reflect the next year's goals. Otherwise, the columns default to the year reflected in the selected End Date.">
                                <IconButton aria-label="progress to goal">
                                    <HelpOutlineIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                            <div>Final Goal (MM)</div>
                        </>
                    )
                },
                {
                    field: 'Notes',
                    headerName: 'Notes',
                    width: 300,
                    editable: true
                }
            ]
        case TableViewType.Offices:
            return [
                {
                    field: 'OfficeName',
                    headerName: 'Office Name',
                    width: 250,
                    editable: false,
                    type: 'string'
                },
                {
                    field: 'OTotalSides',
                    headerName: 'Total Sides',
                    width: 180,
                    editable: false,
                    type: 'number',
                    sortComparator: currencyComparator('OTotalSides')
                },
                {
                    field: 'OVolume',
                    headerName: 'Volume (MM)',
                    width: 170,
                    type: 'number',
                    valueFormatter: ({ value }) => millionsFormatter(value),
                    editable: false,
                    sortComparator: currencyComparator('OVolume')
                },
                {
                    field: 'OAvgSalePrice',
                    headerName: 'Avg Sale Price',
                    width: 200,
                    type: 'number',
                    valueFormatter: ({ value }) => currencyFormatter(value),
                    editable: false,
                    sortComparator: currencyComparator('OAvgSalePrice')
                },
                {
                    field: 'AgentCount',
                    headerName: 'Agent Count',
                    width: 170,
                    type: 'number'
                },
                {
                    field: 'AgentGoalTotal',
                    width: 200,
                    valueFormatter: ({ value }) => millionsFormatter(value),
                    sortComparator: currencyComparator('AgentGoalTotal'),
                    renderHeader: (params) => (
                        <>
                            <Tooltip sx={{color: '#fff'}} title="Goal columns are dynamic and are based on the selected time range. If part of the selected range is this year and during the Goal Setting window (September - December), the columns will reflect the next year's goals. Otherwise, the columns default to the year reflected in the selected End Date.">
                                <IconButton aria-label="progress to goal">
                                    <HelpOutlineIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                            <div>Agent Goal (MM)</div>
                        </>
                    )
                },
                {
                    field: 'FinalGoalTotal',
                    width: 200,
                    valueFormatter: ({ value }) => millionsFormatter(value),
                    sortComparator: currencyComparator('FinalGoalTotal'),
                    renderHeader: (params) => (
                        <>
                            <Tooltip sx={{color: '#fff'}} title="Goal columns are dynamic and are based on the selected time range. If part of the selected range is this year and during the Goal Setting window (September - December), the columns will reflect the next year's goals. Otherwise, the columns default to the year reflected in the selected End Date.">
                                <IconButton aria-label="progress to goal">
                                    <HelpOutlineIcon fontSize='small' />
                                </IconButton>
                            </Tooltip>
                            <div>Final Goal (MM)</div>
                        </>
                    )
                }
            ]
        default:
            console.error('Unrecognized type')
            break;
    }
}

const currencyComparator = (cell) => (v1, v2, param1, param2) => {
    return Number(param1.api.getCellValue(param1.id, cell))
        - Number(param2.api.getCellValue(param2.id, cell))
}
