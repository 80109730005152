import React from 'react'
import { Form, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import './style.css'

class OfficeSelector extends React.Component {
  constructor (props) {
    super(props)
    const {Ident, OfficeName, OfficeState} = props.source.officeList.edges[0].node
    this.state = {
      selectedName: OfficeName,
      selectedID: Ident,
      selectedState: OfficeState
    }

    this.handleSelect = this.handleSelect.bind(this)
  }

  handleSelect = (e) => {
    e.preventDefault(e)
    let parsedName, parsedIdent, parsedID
    if (e && e.target && e.target.value) {
      parsedName = /(?<=%)(.*?)(?=\?)/.exec(e.target.value)
      parsedIdent = /(^.+(?=%))/.exec(e.target.value)
      parsedID = /([^?]+$)/.exec(e.target.value)
      this.setState({
        selectedName: parsedName[0],
        selectedIdent: parsedIdent[0],
        selectedID: parsedID[0]
      })
    }
  }

  render () {
    const officeOptions = []
    const backupOffice = this.props.source.officeList.edges[0]
    if (this.props.source && this.props.source.officeList && this.props.source.officeList.edges) {
      this.props.source.officeList.edges.map(office => {
        if (office.node.OfficeName && office.node.OfficeName !== '') {
          if (!officeOptions.some(filteredOffice => filteredOffice.props.label === `${office.node.OfficeName}`)) {
            return (
              officeOptions.push(
                <option className='officeRow' key={`${office.node.OfficeName}${office.node.id}`} value={`${office.node.Ident}%${office.node.OfficeName}?${office.node.id}`} label={`${office.node.OfficeName}`} />
              )
            )
          }
          return null
        }
        return null
      })
    }

    return (
      <Form className='d-flex flex-column align-items-center' onSubmit={(e) => this.props.onOfficeSelected(e, this.state.selectedIdent ? this.state.selectedIdent : backupOffice.node.Ident, this.state.selectedName ? this.state.selectedName : backupOffice.node.OfficeName)}>
        <Form.Control className='m-2' as='select' htmlSize='10' onChange={this.handleSelect}>
          {officeOptions}
        </Form.Control>
        <Button className='officeSubmit' type='submit'>Submit</Button>
      </Form>
    )
  }
}

OfficeSelector.propTypes = {
  source: PropTypes.shape({
    officeList: PropTypes.shape({
      edges: PropTypes.array
    })
  }),
  onOfficeSelected: PropTypes.func
}

export default OfficeSelector
