import produce from "immer"
import { DateTime } from "luxon"
import { TableViewType, Role, ModalType } from "../../constants"
import { selectAgent } from "./stateSelectors"

export const initialState = {
    snackbar: {
        showSnackbar: false,
        info: '',
        type: ''
    },
    showModal: false,
    startDate: DateTime.now().minus({ years: 1 }).toISO(),
    endDate: DateTime.now().toISO(),
    content: {
        dynamicTotals: {
            officesKeys: [
                {
                    headerName: 'Total Sides',
                    key: 'DynamicSides',
                    type: 'number'
                },
                {
                    headerName: 'Total Volume (MM)',
                    key: 'DynamicVolume',
                    type: 'currency'
                },
                {
                    headerName: 'Total Agent Count',
                    key: 'DynamicAgentCount',
                    type: 'number'
                },
                {
                    headerName: 'Total Agent Goal (MM)',
                    key: 'DynamicAgentGoal',
                    type: 'currency'
                },
                {
                    headerName: 'Total Final Goal (MM)',
                    key: 'DynamicFinalGoal',
                    type: 'currency'
                }
            ],
            officeKeys: [
                {
                    headerName: 'Total Sides',
                    key: 'DynamicSides',
                    type: 'number'
                },
                {
                    headerName: 'Total Volume (MM)',
                    key: 'DynamicVolume',
                    type: 'currency'
                },
                {
                    headerName: 'Total Listings',
                    key: 'DynamicListings',
                    type: 'number'
                },
                {
                    headerName: 'Total Agent Goal (MM)',
                    key: 'DynamicAgentGoal',
                    type: 'currency'
                },
                {
                    headerName: 'Total Final Goal (MM)',
                    key: 'DynamicFinalGoal',
                    type: 'currency'
                }
            ]
        }
    },
    showPanel: false,
    modalType: '',
    selectedEmployeeNum: '',
    isLoading: false
}

const getExecIdx = (state, Ident) => state.EVPs.findIndex(evp => evp.EVPOfficeIds.includes(Ident))
const getOfficeIdxFromExec = (state, execIdx, Ident) => state.EVPs[execIdx].Offices.findIndex(office => office.Ident === Ident)
const getOfficeIdxFromNSExec = (state, execIdx, Ident) => state.EVPs[execIdx].Offices.findIndex(office => office.id === Ident)

export function reducer(state, action) {
    switch (action.type) {
        case 'TOGGLE_SNACKBAR':
            return { ...state, snackbar: action.payload }
        case 'FETCH_DATA':
            return { ...state, ...action.payload }
        case 'CHANGE_DATE':
            return { ...state, ...action.payload }
        case 'UPDATE_AGENT':
            if (state.role === Role.Executive || state.role === Role.SuperAdmin) {
                let execIdx, officeIdx, agentIdx
                if (ModalType.isCareerDevType(state.modalType)) {
                    const agent = selectAgent(agent => agent.AgentName === action.payload.AgentName, state)
                    execIdx = 3
                    if (action.payload.Ident.toString().slice(0,2) === 'CM') {
                        officeIdx = getOfficeIdxFromExec(state, execIdx, action.payload.Ident)
                    } else {
                        officeIdx = getOfficeIdxFromNSExec(state, execIdx, action.payload.Ident)
                    }
                    if (!agent) {
                        agentIdx = -1
                    } else {
                        agentIdx = state.EVPs[execIdx].Offices[officeIdx].Agents.findIndex(_agent => _agent.EmployeeNum === agent.EmployeeNum)
                    }
                } else if (ModalType.isSETType(state.modalType)) {
                    const agent = selectAgent(agent => agent.AgentName === action.payload.AgentName, state)
                    execIdx = 4
                    if (action.payload.Ident.toString().slice(0,3) === 'SET') {
                        officeIdx = getOfficeIdxFromExec(state, execIdx, action.payload.Ident)
                    } else {
                        officeIdx = getOfficeIdxFromNSExec(state, execIdx, action.payload.Ident)
                    }
                    if (!agent) {
                        agentIdx = -1
                    } else {
                        agentIdx = state.EVPs[execIdx].Offices[officeIdx].Agents.findIndex(_agent => _agent.EmployeeNum === agent.EmployeeNum)
                    }
                } else {
                    execIdx = getExecIdx(state, action.payload.Ident)
                    officeIdx = state.EVPs[execIdx].Offices.findIndex(office => office.Ident === action.payload.Ident)
                    agentIdx = state.EVPs[execIdx].Offices[officeIdx].Agents.findIndex(agent => agent.EmployeeNum === action.payload.EmployeeNum);
                }
                return produce(state, draft => {
                    if (agentIdx === -1 || action.payload.addAgent) {
                        const newAgentIdx = draft.EVPs[execIdx].Offices[officeIdx].Agents.length
                        draft.EVPs[execIdx].Offices[officeIdx].FinalGoalTotal = String(Number(draft.EVPs[execIdx].Offices[officeIdx].FinalGoalTotal) - Number(0) + Number(action.payload.FinalGoal))
                        draft.EVPs[execIdx].Offices[officeIdx].AgentGoalTotal = String(Number(draft.EVPs[execIdx].Offices[officeIdx].AgentGoalTotal) - Number(0) + Number(action.payload.AgentGoal))
                        draft.EVPs[execIdx].Offices[officeIdx].Agents[newAgentIdx] = action.payload
                    } else if (action.payload.removeAgent) {
                        draft.EVPs[execIdx].Offices[officeIdx].FinalGoalTotal = String(Number(draft.EVPs[execIdx].Offices[officeIdx].FinalGoalTotal) - Number(draft.EVPs[execIdx].Offices[officeIdx].Agents[agentIdx].FinalGoal))
                        draft.EVPs[execIdx].Offices[officeIdx].AgentGoalTotal = String(Number(draft.EVPs[execIdx].Offices[officeIdx].AgentGoalTotal) - Number(draft.EVPs[execIdx].Offices[officeIdx].Agents[agentIdx].AgentGoal))
                        draft.EVPs[execIdx].Offices[officeIdx].Agents = action.payload.Teams
                    } else {
                        draft.EVPs[execIdx].Offices[officeIdx].FinalGoalTotal = String(Number(draft.EVPs[execIdx].Offices[officeIdx].FinalGoalTotal) - Number(draft.EVPs[execIdx].Offices[officeIdx].Agents[agentIdx].FinalGoal) + Number(action.payload.FinalGoal))
                        draft.EVPs[execIdx].Offices[officeIdx].AgentGoalTotal = String(Number(draft.EVPs[execIdx].Offices[officeIdx].AgentGoalTotal) - Number(draft.EVPs[execIdx].Offices[officeIdx].Agents[agentIdx].AgentGoal) + Number(action.payload.AgentGoal))
                        draft.EVPs[execIdx].Offices[officeIdx].Agents[agentIdx] = action.payload
                    }
                })
            } else if (state.role === Role.Manager || state.role === Role.CareerDevManager) {
                const agentIdx = state.Office.Agents.findIndex(agent => agent.EmployeeNum === action.payload.EmployeeNum)
                return produce(state, draft => {
                    if (agentIdx === -1) {
                        const newAgentIdx = draft.Office.Agents.length
                        draft.Office.FinalGoalTotal = String(Number(draft.Office.FinalGoalTotal) - Number(0) + Number(action.payload.FinalGoal))
                        draft.Office.AgentGoalTotal = String(Number(draft.Office.AgentGoalTotal) - Number(0) + Number(action.payload.AgentGoal))
                        draft.Office.Agents[newAgentIdx] = action.payload
                    } else if (action.payload.removeAgent) {
                        draft.Office.FinalGoalTotal = String(Number(draft.Office.FinalGoalTotal) - Number(draft.Office.Agents[agentIdx].FinalGoal))
                        draft.Office.AgentGoalTotal = String(Number(draft.Office.AgentGoalTotal) - Number(draft.Office.Agents[agentIdx].AgentGoal))
                        draft.Office.Agents = action.payload.Teams
                    } else {
                        draft.Office.FinalGoalTotal = String(Number(draft.Office.FinalGoalTotal) - Number(draft.Office.Agents[agentIdx].FinalGoal) + Number(action.payload.FinalGoal))
                        draft.Office.AgentGoalTotal = String(Number(draft.Office.AgentGoalTotal) - Number(draft.Office.Agents[agentIdx].AgentGoal) + Number(action.payload.AgentGoal))
                        draft.Office.Agents[agentIdx] = action.payload
                    }
                })
            } else if (state.role === Role.EVP || state.role === Role.CareerDevEVP) {
                let officeIdx
                if (action.payload.Ident.toString().slice(0,2) === 'CM') {
                    officeIdx = state.Offices.findIndex(office => office.Ident === action.payload.Ident)
                } else {
                    officeIdx = state.Offices.findIndex(office => office.id === action.payload.Ident)
                }
                const agentIdx = state.Offices[officeIdx]?.Agents.findIndex(agent => agent.EmployeeNum === action.payload.EmployeeNum)
                return produce(state, draft => {
                    if (agentIdx === -1 || action.payload.addAgent) {
                        const newAgentIdx = draft.Offices[officeIdx].Agents.length
                        draft.Offices[officeIdx].FinalGoalTotal = String(Number(draft.Offices[officeIdx].FinalGoalTotal) - Number(0) + Number(action.payload.FinalGoal))
                        draft.Offices[officeIdx].AgentGoalTotal = String(Number(draft.Offices[officeIdx].AgentGoalTotal) - Number(0) + Number(action.payload.AgentGoal))
                        draft.Offices[officeIdx].Agents[newAgentIdx] = action.payload
                    } else if (action.payload.removeAgent) {
                        draft.Offices[officeIdx].FinalGoalTotal = String(Number(draft.Offices[officeIdx].FinalGoalTotal) - Number(draft.Offices[officeIdx].Agents[agentIdx].FinalGoal))
                        draft.Offices[officeIdx].AgentGoalTotal = String(Number(draft.Offices[officeIdx].AgentGoalTotal) - Number(draft.Offices[officeIdx].Agents[agentIdx].AgentGoal))
                        draft.Offices[officeIdx].Agents = action.payload.Teams
                    } else {
                        draft.Offices[officeIdx].FinalGoalTotal = String(Number(draft.Offices[officeIdx].FinalGoalTotal) - Number(draft.Offices[officeIdx].Agents[agentIdx].FinalGoal) + Number(action.payload.FinalGoal))
                        draft.Offices[officeIdx].AgentGoalTotal = String(Number(draft.Offices[officeIdx].AgentGoalTotal) - Number(draft.Offices[officeIdx].Agents[agentIdx].AgentGoal) + Number(action.payload.AgentGoal))
                        draft.Offices[officeIdx].Agents[agentIdx] = action.payload
                    }
                })
            }
            break
        case 'UPDATE_OFFICES':
            if (action.payload?.updateType === 'add') {
                if (ModalType.isCareerDevType(state.modalType)) {
                    return produce(state, draft => {
                        draft.EVPs[3].Offices = draft.EVPs[3].Offices.concat(action.payload.team)
                        draft.EVPs[3].EVPOfficeIds = draft.EVPs[3].EVPOfficeIds.concat([action.payload.evpOffice])
                    })
                } else if (ModalType.isSETType(state.modalType)) {
                    return produce(state, draft => {
                        draft.EVPs[4].Offices = draft.EVPs[4].Offices.concat(action.payload.team)
                        draft.EVPs[4].EVPOfficeIds = draft.EVPs[4].EVPOfficeIds.concat([action.payload.evpOffice])
                    })
                }
            } else if (action.payload?.updateType === 'remove') {
                let officeIdx
                let officeIdent
                let evpIdent
                if (ModalType.isCareerDevType(state.modalType)) {
                    evpIdent = 3
                    officeIdx = state.EVPs[3].Offices.findIndex(office => office.OfficeName === action.payload?.evpOffice)
                    officeIdent = state.EVPs[3].Offices[officeIdx].Ident
                } else if (ModalType.isSETType(state.modalType)) {
                    evpIdent = 4
                    officeIdx = state.EVPs[4].Offices.findIndex(office => office.OfficeName === action.payload?.evpOffice)
                    officeIdent = state.EVPs[4].Offices[officeIdx].Ident
                }
                return produce(state, draft => {
                    draft.EVPs[evpIdent].Offices = draft.EVPs[evpIdent].Offices.filter(office => office.OfficeName !== action.payload.evpOffice)
                    draft.EVPs[evpIdent].EVPOfficeIds = draft.EVPs[evpIdent].EVPOfficeIds.filter(office => office !== officeIdent)
                    draft.selectedOfficeIdent = ""
                    draft.selectedTeamID = ""
                    draft.selectedTeamName = ""
                })
            }
            break
        case 'UPDATE_PREFERENCES':
            return produce(state, draft => {
                draft.userSelections = action.payload
            })
        case 'TOGGLE_PAGE_LOAD':
            return { ...state, isLoading: !state.isLoading }
        case 'TOGGLE_PANEL':
            return { ...state, showPanel: !state.showPanel }
        case 'CALCULATE_DYNAMIC_TOTAL':
            /**
             * The following approach relies on an expected number of headers. If needed, this can be generalized.
             */
            if (state.role === Role.Executive || state.role === Role.SuperAdmin) {
                const execIdx = getExecIdx(state, action.payload.Ident)
                if (action.payload.viewType === TableViewType.Offices) {
                    return produce(state, draft => {
                        draft.EVPs[execIdx].DynamicSides = draft.EVPs[execIdx]?.Offices
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + curr.TotalSides, 0)
                        draft.EVPs[execIdx].DynamicVolume = draft.EVPs[execIdx]?.Offices
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + Number(curr.Volume), 0)
                        draft.EVPs[execIdx].DynamicAgentCount = draft.EVPs[execIdx]?.Offices
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + (curr.Agents.filter(doc => !doc.Hide).length), 0)
                        draft.EVPs[execIdx].DynamicFinalGoal = draft.EVPs[execIdx]?.Offices
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + Number(curr.FinalGoalTotal), 0)
                        draft.EVPs[execIdx].DynamicAgentGoal = draft.EVPs[execIdx]?.Offices
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + Number(curr.AgentGoalTotal), 0)
                    })
                } else if (action.payload.viewType === TableViewType.Office) {
                    const officeIdx = getOfficeIdxFromExec(state, execIdx, action.payload.Ident)
                    return produce(state, draft => {
                        draft.EVPs[execIdx].Offices[officeIdx].DynamicFinalGoal = draft.EVPs[execIdx]?.Offices[officeIdx]?.Agents
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + Number(curr.FinalGoal), 0)
                        draft.EVPs[execIdx].Offices[officeIdx].DynamicAgentGoal = draft.EVPs[execIdx]?.Offices[officeIdx]?.Agents
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + Number(curr.AgentGoal), 0)
                        draft.EVPs[execIdx].Offices[officeIdx].DynamicListings = draft.EVPs[execIdx]?.Offices[officeIdx]?.Agents
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + curr.Listings, 0)
                        draft.EVPs[execIdx].Offices[officeIdx].DynamicSides = draft.EVPs[execIdx]?.Offices[officeIdx]?.Agents
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + Number(curr.TotalSides), 0)
                        draft.EVPs[execIdx].Offices[officeIdx].DynamicVolume = draft.EVPs[execIdx]?.Offices[officeIdx]?.Agents
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + Number(curr.Volume), 0)
                    })
                }
            } else if (state.role === Role.EVP || state.role === Role.CareerDevEVP) {
                if (action.payload.viewType === TableViewType.Offices) {
                    return produce(state, draft => {
                        draft.DynamicFinalGoal = draft.Offices
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + Number(curr.FinalGoalTotal), 0)
                        draft.DynamicAgentGoal = draft.Offices
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + Number(curr.AgentGoalTotal), 0)
                        draft.DynamicSides = draft.Offices
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + Number(curr.TotalSides), 0)
                        draft.DynamicVolume = draft.Offices
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + Number(curr.Volume), 0)
                        draft.DynamicAgentCount = draft.Offices
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + Number(curr.Agents.filter(doc => !doc.Hide).length), 0)
                    })
                } else if (action.payload.viewType === TableViewType.Office) {
                    const officeIdx = state.Offices.findIndex(office => office.Ident === action.payload.Ident)
                    return produce(state, draft => {
                        draft.Offices[officeIdx].DynamicFinalGoal = draft.Offices[officeIdx].Agents
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + Number(curr.FinalGoal), 0)
                        draft.Offices[officeIdx].DynamicAgentGoal = draft.Offices[officeIdx].Agents
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + Number(curr.AgentGoal), 0)
                        draft.Offices[officeIdx].DynamicListings = draft.Offices[officeIdx].Agents
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + curr.Listings, 0)
                        draft.Offices[officeIdx].DynamicSides = draft.Offices[officeIdx].Agents
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + Number(curr.TotalSides), 0)
                        draft.Offices[officeIdx].DynamicVolume = draft.Offices[officeIdx].Agents
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + Number(curr.Volume), 0)
                    })

                }
            } else if (state.role === Role.Manager || state.role === Role.CareerDevManager) {
                if (action.payload.viewType === TableViewType.Offices) {
                    return produce(state, draft => {
                        draft.DynamicFinalGoal = state.Office.FinalGoalTotal
                        draft.DynamicAgentGoal = state.Office.AgentGoalTotal
                        draft.DynamicSides = state.Office.TotalSides
                        draft.DynamicVolume = state.Office.Volume
                        draft.DynamicAgentCount = state.Office.Agents.filter(doc => !doc.Hide).length
                    })
                } else if (action.payload.viewType === TableViewType.Office) {
                    return produce(state, draft => {
                        draft.Office.DynamicFinalGoal = draft.Office.Agents
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + Number(curr.FinalGoal), 0)
                        draft.Office.DynamicAgentGoal = draft.Office.Agents
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + Number(curr.AgentGoal), 0)
                        draft.Office.DynamicListings = draft.Office.Agents
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + curr.Listings, 0)
                        draft.Office.DynamicSides = draft.Office.Agents
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + Number(curr.TotalSides), 0)
                        draft.Office.DynamicVolume = draft.Office.Agents
                            .filter((_, index) => action.payload.idsToFilterFor.includes(index))
                            .reduce((acc, curr) => acc + Number(curr.Volume), 0)
                    })
                }
            }
            break
        case 'TOGGLE_MODAL':
            if (action.payload)
                return {
                    ...state,
                    showModal: !state.showModal,
                    ...action.payload
                }
            return {
                ...state,
                showModal: !state.showModal,
                modalType: '',
                selectedEmployeeNum: '',
                selectedTeamName: ''
            }
        case 'AGENT_SEARCH_RESULTS':
            return {
                ...state, groupManagement: {
                    ...state.groupManagement,
                    agentResults: action.payload.agents
                }
            }
        default:
            return state
    }
}