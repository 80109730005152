import React from 'react'
import { Role, TableViewType } from "../../constants";
import { Flare, Edit } from '@mui/icons-material';
import { GridToolbar } from "@mui/x-data-grid-pro";
import { Button } from "@material-ui/core";
import { ModalType } from '../../constants';
import useGroup from '../../hooks/useGroup'

export default function CustomToolbar({ officeName, viewType, role, data, dispatch, state }) {
    const [group, setGroup] = useGroup(state)
    if (viewType === TableViewType.Offices) {
        return <GridToolbar />
    }
    if (role === Role.Executive) {
        return <GridToolbar />
    }
    let selectedOfficeIdent
    let selectedOfficeId
    if (role === Role.SuperAdmin || role === Role.EVP || role === Role.CareerDevEVP) {
        selectedOfficeIdent = data.filter(office => office.OfficeName === officeName)[0]?.Ident
        selectedOfficeId = data.filter(office => office.OfficeName === officeName)[0]?.id
    } else {
        selectedOfficeIdent = data[0]?.Ident
        selectedOfficeId = data[0]?.id
    }
    const selectedOfficeIndex = data.findIndex(office => office.Ident === selectedOfficeIdent)
    const handleGroupButton = () => {
        dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
                modalType: ModalType.CreateGroup,
                selectedOfficeIdent: selectedOfficeIdent
            }
        })
    }
    const handleEditGroupButton = () => {
        let type
        if (selectedOfficeIdent.slice(0,2) === 'CM') {
            type = ModalType.EditCareerDevGroup
            setGroup({
                ...group,
                groupAgents: data[selectedOfficeIndex]?.Agents ? data[selectedOfficeIndex].Agents : [],
                groupName: data[selectedOfficeIndex].OfficeName,
                groupId: selectedOfficeId,
                groupLeadList: state.CareerDevLeads,
                groupLead: { EmployeeNum: selectedOfficeIdent }
            })
        } else if (selectedOfficeIdent.slice(0,3) === 'SET') {
            type = ModalType.EditSET
            setGroup({
                ...group,
                groupAgents: data[selectedOfficeIndex]?.Agents ? data[selectedOfficeIndex].Agents : [],
                groupName: data[selectedOfficeIndex].OfficeName,
                groupId: selectedOfficeId
            })
        }
        dispatch({
            type: 'TOGGLE_MODAL',
            payload: {
                modalType: type,
                selectedOfficeIdent: selectedOfficeIdent,
                selectedTeamName: data[selectedOfficeIndex].OfficeName,
                selectedTeamID: selectedOfficeId
            }
        })
    }
    return (
        <div className='d-flex'>
            <GridToolbar />
            {role !== Role.Manager && !data[0].Ident.includes('CM') && !data[0].Ident.includes('SET') ? <div className='MuiDataGridPro-toolbarContainer pt-1'>
                <Button className='MuiButton-textSizeSmall' color='primary' onClick={handleGroupButton}>
                    <span className='MuiButton-startIcon MuiButton-iconSizeSmall'><Flare className='MuiSvgIcon-root' /></span>
                    Create New Group
                </Button>
            </div> : null}
            {(data[0].Ident.includes('CM') || data[0].Ident.includes('SET')) ? <div className='MuiDataGridPro-toolbarContainer pt-1'>
                <Button className='MuiButton-textSizeSmall' color='primary' onClick={handleEditGroupButton}>
                    <span className='MuiButton-startIcon MuiButton-iconSizeSmall'><Edit className='MuiSvgIcon-root' /></span>
                    Edit Group
                </Button>
            </div> : null}
        </div>
    );
}
