/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserLoginInput = {|
  Username: string,
  Password: string,
  clientMutationId?: ?string,
|};
export type LoginScreenMutationVariables = {|
  input: UserLoginInput
|};
export type LoginScreenMutationResponse = {|
  +userLoginMutation: ?{|
    +JWT: ?string,
    +Expires: ?number,
    +Roles: ?$ReadOnlyArray<?string>,
    +OfficeID: ?$ReadOnlyArray<?{|
      +Ident: ?string,
      +OfficeState: ?string,
    |}>,
    +OfficeInfo: ?string,
    +User: ?string,
    +SavedSelections: ?{|
      +LastScreen: ?string,
      +LastSouthOffice: ?string,
      +LastCentralOffice: ?string,
      +LastNorthOffice: ?string,
      +LastCDOffice: ?string,
      +LastSETOffice: ?string,
      +LastOffice: ?string,
      +GridSettings: ?{|
        +Office: ?{|
          +PinnedColumns: ?$ReadOnlyArray<?string>,
          +HiddenColumns: ?$ReadOnlyArray<?string>,
          +Sort: ?$ReadOnlyArray<?string>,
          +Filters: ?$ReadOnlyArray<?string>,
        |},
        +Agent: ?{|
          +PinnedColumns: ?$ReadOnlyArray<?string>,
          +HiddenColumns: ?$ReadOnlyArray<?string>,
          +Sort: ?$ReadOnlyArray<?string>,
          +Filters: ?$ReadOnlyArray<?string>,
        |},
      |},
    |},
  |}
|};
export type LoginScreenMutation = {|
  variables: LoginScreenMutationVariables,
  response: LoginScreenMutationResponse,
|};
*/


/*
mutation LoginScreenMutation(
  $input: UserLoginInput!
) {
  userLoginMutation(input: $input) {
    JWT
    Expires
    Roles
    OfficeID {
      Ident
      OfficeState
    }
    OfficeInfo
    User
    SavedSelections {
      LastScreen
      LastSouthOffice
      LastCentralOffice
      LastNorthOffice
      LastCDOffice
      LastSETOffice
      LastOffice
      GridSettings {
        Office {
          PinnedColumns
          HiddenColumns
          Sort
          Filters
        }
        Agent {
          PinnedColumns
          HiddenColumns
          Sort
          Filters
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "PinnedColumns",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "HiddenColumns",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "Sort",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "Filters",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserLoginPayload",
    "kind": "LinkedField",
    "name": "userLoginMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "JWT",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "Expires",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "Roles",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Office",
        "kind": "LinkedField",
        "name": "OfficeID",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "Ident",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "OfficeState",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "OfficeInfo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "User",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SavedSelections",
        "kind": "LinkedField",
        "name": "SavedSelections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "LastScreen",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "LastSouthOffice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "LastCentralOffice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "LastNorthOffice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "LastCDOffice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "LastSETOffice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "LastOffice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ColumnSelections",
            "kind": "LinkedField",
            "name": "GridSettings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ColumnSelectionsSubtype",
                "kind": "LinkedField",
                "name": "Office",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ColumnSelectionsSubtype",
                "kind": "LinkedField",
                "name": "Agent",
                "plural": false,
                "selections": (v1/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginScreenMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoginScreenMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c706419cfe0cd6f3e1c4cac74ebf5b2c",
    "id": null,
    "metadata": {},
    "name": "LoginScreenMutation",
    "operationKind": "mutation",
    "text": "mutation LoginScreenMutation(\n  $input: UserLoginInput!\n) {\n  userLoginMutation(input: $input) {\n    JWT\n    Expires\n    Roles\n    OfficeID {\n      Ident\n      OfficeState\n    }\n    OfficeInfo\n    User\n    SavedSelections {\n      LastScreen\n      LastSouthOffice\n      LastCentralOffice\n      LastNorthOffice\n      LastCDOffice\n      LastSETOffice\n      LastOffice\n      GridSettings {\n        Office {\n          PinnedColumns\n          HiddenColumns\n          Sort\n          Filters\n        }\n        Agent {\n          PinnedColumns\n          HiddenColumns\n          Sort\n          Filters\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0c19c99f0ea47d8ae28651ee2fb8390a';

module.exports = node;
