import React, { useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  ResponsiveContainer
} from 'recharts'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Tooltip as Tip
} from '@material-ui/core'
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab'
import { createRefetchContainer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleDown, faAngleUp, faPrint, faClock, faCheckCircle, faExpand, faCompress, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(faAngleDown, faAngleUp, faPrint, faClock, faCheckCircle, faExpand, faCompress, faExclamationCircle)

function OfficeDashContent (props) {
  const [selectedTeam] = useState(props.data.Groups && props.data.Groups.edges.length > 0 ? 'Entire Office' : 'No Teams Found')
  // const [loading, setLoading] = useState(false)

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  // const handleSelectTeam = (name) => {
  //   setSelectedTeam(name)
  //   let members = []
  //   const parsedAgents = props.data.officeDashMetrics.OfficeAgents.filter(agent => agent.Teams[0]?.TeamName === name)
  //   if (parsedAgents) {
  //     members = parsedAgents.map(agent => {
  //       return agent.EmployeeNum
  //     })
  //   }
  //   if (name !== '' && name !== 'Entire Office') {
  //     setLoading(true)
  //     props.relay.refetch({
  //       office: props.officeID,
  //       isTeam: true,
  //       teamName: name,
  //       teamMembers: members
  //     }, null, () => setLoading(false), {force: true})
  //   } else if (name !== '' && name === 'Entire Office') {
  //     setLoading(true)
  //     props.relay.refetch({
  //       office: props.officeID,
  //       isTeam: false
  //     }, null, () => setLoading(false), {force: true})
  //   }
  // }

  const lists = (props.listingsType === 'R12' ? props.data.officeDashMetrics.Listing12Rolling : props.data.officeDashMetrics.ListingData)
  const vols = (props.volumeType === 'R12' ? props.data.officeDashMetrics.Volume12Rolling : props.data.officeDashMetrics.VolumeData)
  const tradesClosed = props.data.officeDashMetrics.OfficeTransactionsClosed
  const tradesPending = props.data.officeDashMetrics.OfficeTransactionsPending
  const listingsProgress = (props.data.officeDashMetrics.ListGoal * 100)
  const volumeProgress = (props.data.officeDashMetrics.VolumeGoal * 100)
  const highestVol = Math.max.apply(Math, vols.map(month => {
    if (month.GoalValue > month.CurrValue) {
      return (month.GoalValue / 1E6).toFixed(2)
    } else {
      return (month.CurrValue / 1E6).toFixed(2)
    }
  }))
  const highestList = Math.max.apply(Math, lists.map(month => {
    if (month.GoalValue > month.CurrValue) {
      return (month.GoalValue / 1E6).toFixed(2)
    } else {
      return (month.CurrValue / 1E6).toFixed(2)
    }
  }))
  const listData = lists.map(list => {
    return {
      month: list.Month,
      Five_Year: list.HistValue,
      Listings: list.CurrValue,
      Goals: list.GoalValue
    }
  })
  const volData = vols.map(vol => {
    return {
      month: vol.Month,
      Five_Year: (vol.HistValue / 1E6).toFixed(2),
      Volume: (vol.CurrValue / 1E6).toFixed(2),
      Goals: (vol.GoalValue / 1E6).toFixed(2)
    }
  })

  // const teamPlaceholder = [
  //   <MenuItem value={props.data.Groups && props.data.Groups.edges.length > 0 ? 'Entire Office' : 'No Teams Found'}>
  //     <em>{props.data.Groups && props.data.Groups.edges.length > 0 ? 'Entire Office' : 'No Teams Found'}</em>
  //   </MenuItem>
  // ]
  // const teamRows = props.data.Groups?.edges.map((group, index) => {
  //   if (group) {
  //     return (
  //       <MenuItem value={group.node.TeamName}>
  //         <em>{`${group.node.TeamName} (${group.node.TeamMembers.length} Members)`}</em>
  //       </MenuItem>
  //     )
  //   }
  //   return null
  // })
  // const finalTeamRows = teamPlaceholder.concat(teamRows)
  const isSales = (props.officeID?.slice(0,2) !== 'CM') && (props.officeID?.slice(0,3) !== 'SET')
  return (
    <div className='listContainer w-100'>
      {/* {isSales && 
        <div className='d-flex w-100 align-items-center justify-content-center'>
        <Card variant='outlined' className='w-50'>
        <FormControl variant='filled' className='w-100 p-2'>
          <InputLabel id="team-selection-parent-label">
            Select Team
          </InputLabel>
          <Select
            labelId='team-selection-parent-label'
            id='team-selection-parent'
            value={selectedTeam}
            defaultValue={selectedTeam}
            onChange={(e) => handleSelectTeam(e.target.value)}
            label='Select Team'
          >
            {finalTeamRows}
          </Select>
        </FormControl>
        {loading && 
          <h4 className='text-center'>Please wait...</h4>
        }
        </Card>
      </div>
      } */}
      <div className='d-flex dashRow w-100 justify-content-around align-items-center'>
        <Card className='topCards col-sm-6 pt-3 mb-3 d-flex flex-column align-items-center' variant='outlined'>
          <div className='d-flex w-75 align-items-center justify-content-around'>
              {(!props.officeName.toLowerCase().includes('career') && selectedTeam === 'Entire Office') &&
                <div className='d-flex align-items-center'>
                  <Typography variant='h5' className='text-center'>
                    {`${listingsProgress.toFixed(0)}%`}
                  </Typography>
                  <Tip title="Progress to YTD Goal">
                    <IconButton aria-label="progress to goal">
                      <HelpOutlineIcon fontSize='small' />
                    </IconButton>
                  </Tip>
                </div>
              }
            <ToggleButtonGroup
              value={props.listingsType}
              exclusive
              onChange={props.handleListingsToggle}
              aria-label="volume type"
              className='pb-1'
            >
              <ToggleButton value="YTD" aria-label="year to date listings">
                Listings (YTD)
              </ToggleButton>
              <ToggleButton value="R12" aria-label="listings for rolling 12 months">
                Listings (Rolling 12)
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        <CardContent className='d-flex align-items-center justify-content-center w-100 pt-0'>
          <ResponsiveContainer aspect={2.6} width='99%'>
          <LineChart
            className='chart'
            data={listData}
            margin={{
              top: 10, right: 30, left: 20, bottom: 20,
            }}
          >
            {!props.officeName.toLowerCase().includes('career') &&
              <Line name='5 Year Seasonality' type='monotone' dataKey='Five_Year' stroke='#8884d8' />
            }
            {!props.officeName.toLowerCase().includes('career') &&
              <Line name='Goal Trend' type='monotone' dataKey='Goals' stroke='#3dc54a' />
            }
            <Line name='Current Listings' type='monotone' dataKey='Listings' stroke='#9c438b' />
            <CartesianGrid stroke='#ccc' />
            <XAxis dataKey='month'>
              <Label value='Months' offset={0} position='bottom' />
            </XAxis>
            <YAxis domain={[0, Math.ceil(highestList)]} interval="preserveEnd" label={{ value: 'Listings', angle: -90, position: 'insideLeft' }} />
            <Tooltip />
          </LineChart>
          </ResponsiveContainer>
        </CardContent>
        </Card>
        <Card className='topCards col-sm-6 pt-3 mb-3 d-flex flex-column align-items-center' variant='outlined'>
          <div className='d-flex w-75 align-items-center justify-content-around'>
          {(!props.officeName.toLowerCase().includes('career') && selectedTeam === 'Entire Office') &&
                <div className='d-flex align-items-center'>
                  <Typography variant='h5' className='text-center'>
                    {`${volumeProgress.toFixed(0)}%`}
                  </Typography>
                  <Tip title="Progress to YTD Goal">
                    <IconButton aria-label="progress to goal">
                      <HelpOutlineIcon fontSize='small' />
                    </IconButton>
                  </Tip>
                </div>
              }
            <ToggleButtonGroup
              value={props.volumeType}
              exclusive
              onChange={props.handleVolumeToggle}
              aria-label="volume type"
              className='pb-1'
            >
              <ToggleButton value="YTD" aria-label="year to date volume">
                Volume (YTD)
              </ToggleButton>
              <ToggleButton value="R12" aria-label="volume for rolling 12 months">
                Volume (Rolling 12)
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <CardContent className='d-flex align-items-center justify-content-center w-100 pt-0'>
            <ResponsiveContainer aspect={2.6} width='99%'>
            <LineChart
              data={volData}
              margin={{
                top: 0, right: 30, left: 20, bottom: 20,
              }}
            >
              {isSales &&
                <Line name='5 Year Seasonality' type="monotone" dataKey="Five_Year" stroke="#8884d8" />
              }
              {isSales &&
                <Line name='Goal Trend' type="monotone" dataKey="Goals" stroke="#3dc54a" />
              }
              <Line name='Current Volume' type="monotone" dataKey="Volume" stroke="#9c438b" />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="month">
                <Label value="Months" offset={0} position="bottom" />
              </XAxis>
              <YAxis domain={[0, Math.ceil(highestVol) + 2]} label={{ value: 'Volume', angle: -90, position: 'insideLeft' }} />
              <Tooltip />
            </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </div>
      <div className='d-flex dashRow w-100 justify-content-around align-items-center'>
        <Card variant='outlined' className='col-sm-6 d-flex flex-column align-items-center bottomCards'>
          <div className='w-100 d-flex justify-content-around align-items-center'>
            <div className='tradesButtons'>
            <ToggleButtonGroup
              value={props.tradeType}
              exclusive
              onChange={props.handleTradeToggle}
              aria-label="transaction type"
            >
              <ToggleButton title='Pending Trades' value="Pending" aria-label="pending transactions">
                <FontAwesomeIcon className='icons' icon='clock' />
              </ToggleButton>
              <ToggleButton title='Closed Trades' value="Closed" aria-label="closed transactions">
                <FontAwesomeIcon className='icons' icon='check-circle' />
              </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup
              value={props.tradeDetail}
              exclusive
              onChange={props.handleTradeDetail}
              aria-label="transaction detail"
            >
              <ToggleButton title='Overview' value="Overview" aria-label="overview">
                <FontAwesomeIcon className='icons' icon='expand' />
              </ToggleButton>
              <ToggleButton title='Detail' value="Detail" aria-label="detail view">
                <FontAwesomeIcon className='icons' icon='compress' />
              </ToggleButton>
            </ToggleButtonGroup>
            </div>
            <CardHeader title='Transactions' className='text-center w-50'/>
            <Button className='printButton' variant='contained' onClick={handlePrint}>
              <FontAwesomeIcon className='icons' icon='print' />
            </Button>
          </div>
          <CardContent className='w-100'>
            <TableContainer className='agentTable' ref={componentRef}>
            {props.tradeDetail === 'Overview' &&
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Agent Name</TableCell>
                    <TableCell>Close Date</TableCell>
                    <TableCell>Agent Side</TableCell>
                    <TableCell>Volume Credit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.tradeType === 'Closed' && tradesClosed.map(trade => {
                    return (
                      <TableRow key={trade.id}>
                        <TableCell>{trade.Agent}</TableCell>
                        <TableCell>{trade.CloseDate.slice(0, 10)}</TableCell>
                        <TableCell>{trade.Side}</TableCell>
                        <TableCell>{`$${parseInt(trade.VolumeCredit).toLocaleString()}`}</TableCell>
                      </TableRow>
                    )
                  })}
                  {props.tradeType === 'Pending' && tradesPending.map(trade => {
                    return (
                      <TableRow key={trade.id}>
                        <TableCell>{trade.Agent}</TableCell>
                        <TableCell>{trade.CloseDate.slice(0, 10)}</TableCell>
                        <TableCell>{trade.Side}</TableCell>
                        <TableCell>{`$${parseInt(trade.VolumeCredit).toLocaleString()}`}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            }
            {props.tradeDetail === 'Detail' &&
              <List className='expandedList'>
                {props.tradeType === 'Closed' && tradesClosed.map((trade, index) => {
                  if (Number.isInteger(index / 10)) {
                    return (
                      <div key={trade.id} className='printBreak'>
                        <ListItem key={`${trade.id}-1`}>
                          <ListItemText
                            primary={`Agent: ${trade.Agent} ${trade.Side ? `(${trade.Side})` : ''}`}
                          />
                          <ListItemText
                            primary={`Close Date: ${trade.CloseDate.slice(0, 10)}`}
                          />
                        </ListItem>
                        <ListItem key={`${trade.id}-2`}>
                          <ListItemText
                            primary={`Sell Price: $${parseInt(trade.SellPrice).toLocaleString()}`}
                          />
                          <ListItemText
                            primary={`Volume Credit: $${parseInt(trade.VolumeCredit).toLocaleString()}`}
                          />
                        </ListItem>
                        <ListItem key={`${trade.id}-3`}>
                          <ListItemText
                            primary={`Address: ${trade.Address}`}
                          />
                        </ListItem>
                        <Divider component="li"/>
                      </div>
                    )
                  }
                    return (
                      <div key={trade.id}>
                        <ListItem key={`${trade.id}-1`}>
                          <ListItemText
                            primary={`Agent: ${trade.Agent} (${trade.Side})`}
                          />
                          <ListItemText
                            primary={`Close Date: ${trade.CloseDate.slice(0, 10)}`}
                          />
                        </ListItem>
                        <ListItem key={`${trade.id}-2`}>
                          <ListItemText
                            primary={`Sell Price: $${parseInt(trade.SellPrice).toLocaleString()}`}
                          />
                          <ListItemText
                            primary={`Volume Credit: $${parseInt(trade.VolumeCredit).toLocaleString()}`}
                          />
                        </ListItem>
                        <ListItem key={`${trade.id}-3`}>
                          <ListItemText
                            primary={`Address: ${trade.Address}`}
                          />
                        </ListItem>
                        <Divider component="li"/>
                      </div>
                    )
                  })}
                  {props.tradeType === 'Pending' && tradesPending.map((trade, index) => {
                    if (Number.isInteger(index / 10)) {
                      return (
                        <div key={trade.id} className='printBreak'>
                          <ListItem key={`${trade.id}-1`}>
                            <ListItemText
                              primary={`Agent: ${trade.Agent} ${trade.Side ? `(${trade.Side})` : ''}`}
                            />
                            <ListItemText
                              primary={`Close Date: ${trade.CloseDate.slice(0, 10)}`}
                            />
                          </ListItem>
                          <ListItem key={`${trade.id}-2`}>
                            <ListItemText
                              primary={`Sell Price: $${parseInt(trade.SellPrice).toLocaleString()}`}
                            />
                            <ListItemText
                              primary={`Volume Credit: $${parseInt(trade.VolumeCredit).toLocaleString()}`}
                            />
                          </ListItem>
                          <ListItem key={`${trade.id}-3`}>
                            <ListItemText
                              primary={`Address: ${trade.Address}`}
                            />
                          </ListItem>
                          <Divider component="li"/>
                        </div>
                      )
                    }
                    return (
                      <div key={trade.id}>
                        <ListItem key={`${trade.id}-1`}>
                          <ListItemText
                            primary={`Agent: ${trade.Agent} (${trade.Side})`}
                          />
                          <ListItemText
                            primary={`Close Date: ${trade.CloseDate.slice(0, 10)}`}
                          />
                        </ListItem>
                        <ListItem key={`${trade.id}-2`}>
                          <ListItemText
                            primary={`Sell Price: $${parseInt(trade.SellPrice).toLocaleString()}`}
                          />
                          <ListItemText
                            primary={`Volume Credit: $${parseInt(trade.VolumeCredit).toLocaleString()}`}
                          />
                        </ListItem>
                        <ListItem key={`${trade.id}-3`}>
                          <ListItemText
                            primary={`Address: ${trade.Address}`}
                          />
                        </ListItem>
                        <Divider component="li"/>
                      </div>
                    )
                  })}
              </List>
            }
            </TableContainer>
          </CardContent>
        </Card>
        <Card variant='outlined' className='col-sm-6 bottomCards'>
          <CardHeader title={`${isSales ? 'Office' : 'Team'} Agents`} className='text-center'/>
          <CardContent>
            <TableContainer className='agentTable'>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className='headerHover' onClick={() => props.handleSort('num')}>{props.sortColumn === 'num' && props.sortDirection === 'desc' ? <><FontAwesomeIcon className='icons' icon='angle-down' />&nbsp;{'Emp. #'}</> : props.sortColumn === 'num' && props.sortDirection === 'asc' ? <><FontAwesomeIcon className='icons' icon='angle-up' />&nbsp;{'Emp. #'}</> : 'Emp. #'}</TableCell>
                    <TableCell className='headerHover' onClick={() => props.handleSort('name')}>{props.sortColumn === 'name' && props.sortDirection === 'desc' ? <><FontAwesomeIcon className='icons' icon='angle-down' />&nbsp;{'Agent Name'}</> : props.sortColumn === 'name' && props.sortDirection === 'asc' ? <><FontAwesomeIcon className='icons' icon='angle-up' />&nbsp;{'Agent Name'}</> : 'Agent Name'}</TableCell>
                    <TableCell className='headerHover' onClick={() => props.handleSort('vol')}>{props.sortColumn === 'vol' && props.sortDirection === 'desc' ? <><FontAwesomeIcon className='icons' icon='angle-down' />&nbsp;{'Total Volume'}</> : props.sortColumn === 'vol' && props.sortDirection === 'asc' ? <><FontAwesomeIcon className='icons' icon='angle-up' />&nbsp;{'Total Volume'}</> : 'Total Volume'}</TableCell>
                    <TableCell className='headerHover' onClick={() => props.handleSort('list')}>{props.sortColumn === 'list' && props.sortDirection === 'desc' ? <><FontAwesomeIcon className='icons' icon='angle-down' />&nbsp;{'Total Listings'}</> : props.sortColumn === 'list' && props.sortDirection === 'asc' ? <><FontAwesomeIcon className='icons' icon='angle-up' />&nbsp;{'Total Listings'}</> : 'Total Listings'}</TableCell>
                    <TableCell className='headerHover' onClick={() => props.handleSort('date')}>{props.sortColumn === 'date' && props.sortDirection === 'desc' ? <><FontAwesomeIcon className='icons' icon='angle-down' />&nbsp;{'Aff. Date'}</> : props.sortColumn === 'date' && props.sortDirection === 'asc' ? <><FontAwesomeIcon className='icons' icon='angle-up' />&nbsp;{'Aff. Date'}</> : 'Aff. Date'}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.agents.map((agent, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{agent.EmployeeNum}</TableCell>
                        <TableCell>
                          <Button
                          variant='outlined'
                          onClick={() => props.getAgentDetails(agent.EmployeeNum, agent.AgentName)}
                          className='rowCellAgentButton'
                          id={agent.EmployeeNum}
                          size='small'
                          key={agent.EmployeeNum + 'name'}>
                            {agent.AgentName}&nbsp;
                              { agent.IsTerminated ? <Tip title='Employee is no longer affiliated' aria-label='lead' placement='bottom'>
                                  <span><FontAwesomeIcon className='icons' icon='exclamation-circle' color="red"/></span>
                                </Tip>
                                : null }
                          </Button>
                        </TableCell>
                        <TableCell>{agent.Volume ? (parseInt(agent.Volume) / 1E6).toFixed(2) : 0}</TableCell>
                        <TableCell>{agent.Listings}</TableCell>
                        <TableCell>{agent.AffilDate.slice(0, 10)}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default createRefetchContainer(
  OfficeDashContent,
  {
    query: graphql`
      fragment OfficeDashContent_query on Query @argumentDefinitions(
        Ident: { type: "String!", defaultValue: "" },
        isTeam: { type: "Boolean!", defaultValue: false },
        teamName: { type: "String" },
        teamMembers: { type: "[String]" }
      ) {
        officeDashMetrics (Ident: $Ident, isTeam: $isTeam, teamName: $teamName, teamMembers: $teamMembers) {
          ListingData {
            Month
            HistValue
            CurrValue
            GoalValue
          }
          VolumeData {
            Month
            HistValue
            CurrValue
            GoalValue
          }
          Listing12Rolling {
            Month
            HistValue
            CurrValue
            GoalValue
          }
          Volume12Rolling {
            Month
            HistValue
            CurrValue
            GoalValue
          }
          AvgSalePrice {
            HistValue
            CurrValue
          }
          OfficeAgents {
            Hide
            EmployeeNum
            EmployeeId
            AgentName
            Listings
            Volume
            TotalSides
            AffilDate
            AgentGoal
            FinalGoal
            Notes
            Ident
            IsTerminated
            Teams {
                TeamName
                IsTeamLead
                TeamAddDate
                isCareerDev
                isSET
            }
          }
          OfficeTransactionsClosed {
            id
            Ident
            Side
            CloseDate
            Agent
            VolumeCredit
            SellPrice
            Address
          }
          OfficeTransactionsPending {
            id
            Ident
            Side
            CloseDate
            Agent
            VolumeCredit
            SellPrice
            Address
          }
          VolumeGoal
          ListGoal
        }
      }
    `
  },
  graphql`
    query OfficeDashContentTeamQuery($Ident: String!, $isTeam: Boolean!, $teamName: String, $teamMembers: [String]) {
      ...OfficeDashContent_query @arguments(Ident: $Ident, isTeam: $isTeam, teamName: $teamName, teamMembers: $teamMembers)
    }
  `
)