const { Role } = require("../../constants");


const selectExecutive = (officeId, state) => {
    if (state.role !== Role.Executive && state.role !== Role.SuperAdmin) throw Error(`role is not valid`)
    const execIdx = state.EVPs.findIndex(evp => evp.EVPOfficeIds.includes(officeId))
    if (execIdx === -1) throw Error(`cannot find executive with an office id of ${officeId}`)
    return state.EVPs[execIdx]
}
const selectOffice = (officeId, state) => {
    let officeIdx
    switch (state.role) {
        case Role.Executive:
        case Role.SuperAdmin:
            const office = selectExecutive(officeId, state).Offices.find(office => office.Ident === officeId)
            if (!office) throw Error('cannot find office with the given id')
            return office
        case Role.CareerDevEVP:
        case Role.EVP:
            officeIdx = state.Offices.findIndex(office => office.Ident === officeId)
            if (officeIdx === -1) throw Error(`cannot find office with id of ${officeId}`)
            return state.Offices[officeIdx]
        case Role.CareerDevManager:
        case Role.Manager:
            if (state.Office.Ident !== officeId && state.Office.Agents[0].Ident !== officeId) throw Error(`cannot find office with id of ${officeId}`)
            return state.Office
        default:
            throw Error(`role is not valid`)
    }
}
const selectAgent = (comparator, state) => {
    const isCareerDev = state.selectedOfficeIdent.slice(0, 2) === 'CM'
    const isSET = state.selectedOfficeIdent.slice(0, 3) === 'SET'
    switch (state.role) {
        case Role.Executive:
        case Role.SuperAdmin:
        case Role.CareerDevManager:
        case Role.CareerDevEVP:
            if (isCareerDev) {
                return state.EVPs
                    .map(evp => evp.Offices
                        .map(office => office.Agents
                            .filter(agent => agent.AgentName !== "" && agent.AgentName !== null)
                            .filter(comparator)).flat()).flat()[0] //assumes there is only one agent found
            } else if (isSET) {
                return state.EVPs
                    .map(evp => evp.Offices
                        .map(office => office.Agents
                            .filter(agent => agent.AgentName !== "" && agent.AgentName !== null)
                            .filter(comparator)).flat()).flat()[0]
            } else {
                return selectOffice(state.selectedOfficeIdent, state).Agents
                    .filter(agent => agent.AgentName !== null && agent.AgentName !== "")
                    .filter(comparator)[0]
            }
        case Role.EVP:
            return selectOffice(state.selectedOfficeIdent, state).Agents
                    .filter(agent => agent.AgentName !== null && agent.AgentName !== "")
                    .filter(comparator)[0]
        case Role.Manager:
            return state.Office.Agents
                .filter(agent => agent.AgentName !== "" && agent.AgentName !== null)
                .filter(comparator)[0] //assumes there is only one agent found
        default:
            break
    }
}

const selectCareerDevAgents = state => {
    switch (state.role) {
        case Role.Executive:
        case Role.SuperAdmin:
        case Role.CareerDevEVP:
            return state.EVPs.map(evp => evp.Offices
                .map(office => office.Agents
                    .filter(agent => agent.Teams.find(team => team.isCareerDev))
                    .filter(agent => !agent.deleted)
                    .map(agent => {
                        return {
                            ...agent,
                            Teams: agent.Teams.filter(team => team.isCareerDev)
                        }
                    })).flat()).flat()
        case Role.CareerDevManager:
        case Role.Manager:
            return state.Office.Agents
                .filter(agent => agent.Teams.find(team => team.isCareerDev))
                .map(agent => {
                    return {
                        ...agent,
                        Teams: agent.Teams.filter(team => team.isCareerDev)
                    }
                })
        default:
            throw Error(`role is not valid`)
    }
}

const selectSETAgents = state => {
    switch (state.role) {
        case Role.Executive:
        case Role.SuperAdmin:
            return state.EVPs.map(evp => evp.Offices
                .map(office => office.Agents
                    .filter(agent => agent.Teams.find(team => team.isSET))
                    .map(agent => {
                        return {
                            ...agent,
                            Teams: agent.Teams.filter(team => team.isSET)
                        }
                    })).flat()).flat()
        case Role.Manager:
            return state.Office.Agents
                .filter(agent => agent.Teams.find(team => team.isSET))
                .map(agent => {
                    return {
                        ...agent,
                        Teams: agent.Teams.filter(team => team.isSET)
                    }
                })
        default:
            throw Error(`role is not valid`)
    }
}

export { selectExecutive, selectOffice, selectAgent, selectCareerDevAgents, selectSETAgents }