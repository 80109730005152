import React from 'react'
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import LoginScreen from './screens/LoginScreen'
import Goals from './screens/Goals'
import DashWelcomeScreen from './screens/DashWelcomeScreen'
import SplashScreen from './screens/SplashScreen'
import Reports from './screens/Reports'
import NotFoundPage from './screens/NotFoundPage'
import { LicenseInfo } from '@mui/x-license-pro';
import config from './utils/config.js'
import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import environment from './relay/Environment'

import './App.css'

LicenseInfo.setLicenseKey(config.MUI_PRO_KEY);

class App extends React.Component {
  checkUser = () => {
    const storage = window.sessionStorage
    const hasToken = storage.getItem('userToken')
    const tokenExpiration = storage.getItem('tokenExpire')
    // check to ensure userSession and token are still valid
    if (hasToken && tokenExpiration) {
      return true
    } else {
      storage.clear()
      return false
    }
  }

  saveLocation = (locale) => {
    const isAuthd = this.checkUser()
    const userName = window.sessionStorage.getItem('userName')
    if (isAuthd) {
      const variables = {
        input: {
          Username: userName,
          Location: locale
        }
      }
      commitMutation(
        environment,
        {
          mutation: saveLocationMutation,
          variables,
          onCompleted: (response, errors) => {
            if (!errors && response.saveLocationMutation) {
              return true
            } else {
              return false
            }
          },
          onError: err => {
            console.error(err)
            this.setState({
              loginError: true
            })
          }
        }
      )
    }
  }

  render() {
    const userRole = window.sessionStorage.getItem('userRoles')
    const userOfficeName = window.sessionStorage.getItem('userOfficeName')
    const userOffice = window.sessionStorage.getItem('userOffice')
    const userName = window.sessionStorage.getItem('userName')
    const selections = window.sessionStorage.getItem('savedSelections')
    const parsedSelections = JSON.parse(selections)

    return (
      <div>
        <div>
          <Switch>
            <Route exact path='/'>
              <Redirect to='/login' />
            </Route>
            <Route exact path='/login' component={LoginScreen} />
            <Route path='/goals'>
              {this.checkUser() ? <Goals role={userRole} name={userOfficeName} office={userOffice} user={userName} saveLocation={this.saveLocation} selections={parsedSelections} /> : <Redirect to='/login' />}
            </Route>
            <Route path='/dashboards'>
              {this.checkUser() ? <DashWelcomeScreen role={userRole} saveLocation={this.saveLocation} /> : <Redirect to='/login' />}
            </Route>
            <Route path='/reports'>
              {this.checkUser() ? <Reports role={userRole} office={userOffice} saveLocation={this.saveLocation} /> : <Redirect to='/login' />}
            </Route>
            <Route path='/home'>
              {this.checkUser() ? <SplashScreen execName={userOfficeName} role={userRole} office={userOffice} saveLocation={this.saveLocation} /> : <Redirect to='/login' />}
            </Route>
            <Route component={NotFoundPage} />
          </Switch>
        </div>
      </div>
    )
  }
}

const saveLocationMutation = graphql`
  mutation AppMutation(
    $input: SaveLocationInput!
  ) {
    saveUserLocationMutation(input: $input) {
      Response
    }
  }
`

export default withRouter(App)
