import React from 'react'
import './style.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(faCircleNotch)

class LoadingSpinner extends React.Component {
  render () {
    return (
      <div className='listContainer w-100 d-flex justify-content-center align-items-center flex-column'>
        <h3>Please wait...</h3>
        <div className='loadingSpinner mt-3'>
          <FontAwesomeIcon className='icon' icon='circle-notch' spin />
        </div>
      </div>
    )
  }
}

export { LoadingSpinner }

export default LoadingSpinner
