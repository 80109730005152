module.exports = {

    /**
     * Takes an incoming number represented as a string and returns a formatted
     * number formatted in millions as currency. Example:
     * "23000000.1234" ->  "$23.1"
     * @param {string} value
     * @returns string
     */
    millionsFormatter: value => new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 1,
        minimumFractionDigits: 1
    }).format(Number(value)),

    /**
     * Converts incoming number into a foramtted number. Example:
     * 1322 -> 1,322
     */
    numberFormatter: value => new Intl.NumberFormat().format(value),

    /**
     * Takes an incoming number represented as a string and returns
     * a currency-formatted string without decimal place.
     * Example: "1232.12" -> $1,232
     * @param {string} value
     * @returns
     */
    currencyFormatter: value => new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        //maximumFractionDigits: 0
    }).format(Number(value)),
}