import React, { useState } from 'react'
import LoadingSpinner from '../../components/LoadingSpinner'
import environment from '../../relay/Environment'
import { commitMutation } from 'react-relay'
import { QueryRenderer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Label, ResponsiveContainer } from 'recharts'
import { Card, CardContent, CardHeader, IconButton, TextField, Tooltip as Tip } from '@material-ui/core'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Form, Col, Button, Toast } from 'react-bootstrap'
import './style.css'

export default function AgentDash(props) {
  const [agentGoal, setAgentGoal] = useState('')
  const [finalGoal, setfinalGoal] = useState('')
  const [agentNotes, setAgentNotes] = useState('')
  const [toastOpenStatus, setToastStatus] = useState(false)
  const [toastDismissStatus, setToastDismiss] = useState(true)
  const [toastMessage, setToastMessage] = useState('')
  const [toastClass, setToastClass] = useState('')

  const agentName = props.agentName
  const agentID = props.searchID
  const officeID = props.office
  const officeName = props.officeName

  const handleGoalChange = (e, type) => {
    if (type === 'agent') {
      setAgentGoal(e.target.value)
    } else if (type === 'final') {
      setfinalGoal(e.target.value)
    }
  }

  const handleNoteChange = (e) => {
    const noteVal = (e.target.value ? e.target.value : ' ')
    setAgentNotes(noteVal)
  }

  const handleSaveMutation = (e) => {
    e.preventDefault(e)
    const variables = {
      input: {
        AgentGoal: agentGoal ? agentGoal : 0,
        FinalGoal: finalGoal ? finalGoal : 0,
        EmployeeNum: agentID,
        OfficeName: officeName,
        Ident: officeID,
        Notes: agentNotes ? agentNotes : '',
        Hide: false
      }
    }
    commitMutation(
      environment,
      {
        mutation: goalSaveMutation,
        variables,
        onCompleted: (response, errors) => {
          if (!errors && response.agentGoalSaveMutation.Response) {
            setToastStatus(true)
            setToastDismiss(true)
            setToastMessage(`Goal for ${agentName} saved successfully!`)
            setToastClass('success')
          } else {
            setToastStatus(true)
            setToastDismiss(false)
            setToastMessage(`Failed to save data: ${errors[0].message}`)
            setToastClass('error')
            console.error(errors)
          }
        },
        onError: err => {
          setToastStatus(true)
          setToastDismiss(false)
          setToastMessage('Failed to save data.' + err.toString())
          setToastClass('error')
          console.error(err)
        }
      }
    )
  }

  const handleCloseToast = () => {
    setToastStatus(false)
    setToastDismiss(true)
    setToastMessage('')
    setToastClass('')
  }

  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
      query AgentDashQuery($agentID: String!, $Ident: String!) {
        agentDashMetrics (agentID: $agentID, Ident: $Ident) {
          DashListingData {
            Month
            HistValue
            CurrValue
          }
          DashVolumeData {
            Month
            HistValue
            CurrValue
          }
          AvgSalePrice {
            HistValue
            CurrValue
          }
          CurrentGoal
          AffilDate
          UnfinishedSaleCount
        }
      }
    `}
      variables={{
        agentID: (agentID).toString(),
        Ident: officeID
      }}
      render={({ errors, props }) => {
        if (props && props.agentDashMetrics === null) {
          return <div className='mt-4 mb-1 text-center text-danger'>Something went wrong with that request.</div>
        }
        if (props && props.agentDashMetrics && !errors) {
          const lists = props.agentDashMetrics.DashListingData
          const vols = props.agentDashMetrics.DashVolumeData
          const salePrice = props.agentDashMetrics.AvgSalePrice[0].CurrValue
          const histSalePrice = props.agentDashMetrics.AvgSalePrice[0].HistValue
          const goalForCalc = props.agentDashMetrics.CurrentGoal[1]
          const queriedAgentNotes = props.agentDashMetrics.CurrentGoal[2]
          const highestVol = Math.max.apply(Math, vols.map(function (month) { return (month.GoalValue / 1E6).toFixed(2) }))
          const highestList = Math.max.apply(Math, lists.map(function (month) { return (month.GoalValue / 1E6).toFixed(2) }))
          const salePriceForCalc = (salePrice && salePrice !== 0 ? salePrice : histSalePrice)
          const listingsTarget = (goalForCalc && goalForCalc !== 0 && salePriceForCalc && salePriceForCalc !== 0 ? (parseFloat(goalForCalc) * 1E6) / parseFloat(salePriceForCalc) : null)
          const listData = [
            { month: lists[0].Month, Five_Year: lists[0].HistValue, Listings: lists[0].CurrValue, Goals: lists[0].GoalValue },
            { month: lists[1].Month, Five_Year: lists[1].HistValue, Listings: lists[1].CurrValue, Goals: lists[1].GoalValue },
            { month: lists[2].Month, Five_Year: lists[2].HistValue, Listings: lists[2].CurrValue, Goals: lists[2].GoalValue },
            { month: lists[3].Month, Five_Year: lists[3].HistValue, Listings: lists[3].CurrValue, Goals: lists[3].GoalValue },
            { month: lists[4].Month, Five_Year: lists[4].HistValue, Listings: lists[4].CurrValue, Goals: lists[4].GoalValue },
            { month: lists[5].Month, Five_Year: lists[5].HistValue, Listings: lists[5].CurrValue, Goals: lists[5].GoalValue },
            { month: lists[6].Month, Five_Year: lists[6].HistValue, Listings: lists[6].CurrValue, Goals: lists[6].GoalValue },
            { month: lists[7].Month, Five_Year: lists[7].HistValue, Listings: lists[7].CurrValue, Goals: lists[7].GoalValue },
            { month: lists[8].Month, Five_Year: lists[8].HistValue, Listings: lists[8].CurrValue, Goals: lists[8].GoalValue },
            { month: lists[9].Month, Five_Year: lists[9].HistValue, Listings: lists[9].CurrValue, Goals: lists[9].GoalValue },
            { month: lists[10].Month, Five_Year: lists[10].HistValue, Listings: lists[10].CurrValue, Goals: lists[10].GoalValue },
            { month: lists[11].Month, Five_Year: lists[11].HistValue, Listings: lists[11].CurrValue, Goals: lists[11].GoalValue }
          ]
          const volData = [
            { month: vols[0].Month, Five_Year: (vols[0].HistValue / 1E6).toFixed(2), Volume: (vols[0].CurrValue / 1E6).toFixed(2) },
            { month: vols[1].Month, Five_Year: (vols[1].HistValue / 1E6).toFixed(2), Volume: (vols[1].CurrValue / 1E6).toFixed(2) },
            { month: vols[2].Month, Five_Year: (vols[2].HistValue / 1E6).toFixed(2), Volume: (vols[2].CurrValue / 1E6).toFixed(2) },
            { month: vols[3].Month, Five_Year: (vols[3].HistValue / 1E6).toFixed(2), Volume: (vols[3].CurrValue / 1E6).toFixed(2) },
            { month: vols[4].Month, Five_Year: (vols[4].HistValue / 1E6).toFixed(2), Volume: (vols[4].CurrValue / 1E6).toFixed(2) },
            { month: vols[5].Month, Five_Year: (vols[5].HistValue / 1E6).toFixed(2), Volume: (vols[5].CurrValue / 1E6).toFixed(2) },
            { month: vols[6].Month, Five_Year: (vols[6].HistValue / 1E6).toFixed(2), Volume: (vols[6].CurrValue / 1E6).toFixed(2) },
            { month: vols[7].Month, Five_Year: (vols[7].HistValue / 1E6).toFixed(2), Volume: (vols[7].CurrValue / 1E6).toFixed(2) },
            { month: vols[8].Month, Five_Year: (vols[8].HistValue / 1E6).toFixed(2), Volume: (vols[8].CurrValue / 1E6).toFixed(2) },
            { month: vols[9].Month, Five_Year: (vols[9].HistValue / 1E6).toFixed(2), Volume: (vols[9].CurrValue / 1E6).toFixed(2) },
            { month: vols[10].Month, Five_Year: (vols[10].HistValue / 1E6).toFixed(2), Volume: (vols[10].CurrValue / 1E6).toFixed(2) },
            { month: vols[11].Month, Five_Year: (vols[11].HistValue / 1E6).toFixed(2), Volume: (vols[11].CurrValue / 1E6).toFixed(2) }
          ]
          return (
            <>
              <div className='headerContainer'>
                <h3 className='headerText'>
                  Metrics for {agentName}
                </h3>
              </div>
              <div className='modalContent w-100 d-flex'>
                <div className='d-flex dashRow w-100 justify-content-around align-items-center'>
                  <Card className='infoCard overflow-auto' variant='outlined'>
                    <CardHeader className='text-center' />
                    <CardContent className='d-flex align-items-center w-100'>
                      <Form onSubmit={(e) => handleSaveMutation(e)} className='w-100 mx-auto text-center'>
                        <Form.Group className='d-flex w-100' controlId="affilDate">
                          <Form.Label column sm='6' className="text-left">
                            Affiliation Date
                          </Form.Label>
                          <Col sm='6'>
                            <Form.Control plaintext readOnly defaultValue={(props.agentDashMetrics.AffilDate).slice(0, 10)} />
                          </Col>
                        </Form.Group>
                        <Form.Group className='d-flex w-100' controlId='agentGoalInfo'>
                          <div className='d-flex  align-items-center w-50'>
                            <Form.Label column sm='6' className="text-left">
                              Agent Goal
                            </Form.Label>
                            <Tip title='Use abbreviated notation (1.25 instead of 1250000)'>
                              <IconButton aria-label='Use abbreviated notation (1.25 instead of 1250000)'>
                                <HelpOutlineIcon fontSize='small' />
                              </IconButton>
                            </Tip>
                          </div>
                          <Col sm='6'>
                            <Form.Control onChange={(e) => handleGoalChange(e, 'agent')} defaultValue={(props.agentDashMetrics.CurrentGoal[0])} />
                          </Col>
                        </Form.Group>
                        <Form.Group className='d-flex w-100' controlId='finalGoalInfo'>
                          <div className='d-flex  align-items-center w-50'>
                            <Form.Label column sm='6' className="text-left">
                              Final Goal
                            </Form.Label>
                            <Tip title='Use abbreviated notation (1.25 instead of 1250000)'>
                              <IconButton aria-label='Use abbreviated notation (1.25 instead of 1250000)'>
                                <HelpOutlineIcon fontSize='small' />
                              </IconButton>
                            </Tip>
                          </div>
                          <Col sm='6'>
                            <Form.Control disabled onChange={(e) => handleGoalChange(e, 'final')} defaultValue={(props.agentDashMetrics.CurrentGoal[1])} />
                          </Col>
                        </Form.Group>
                        <Form.Group className='d-flex w-100' controlId='listings'>
                          <Form.Label column sm='6' className="text-left">
                            Listings to Reach Final Goal
                          </Form.Label>
                          <Col sm='6'>
                            <Form.Control plaintext readOnly defaultValue={listingsTarget ? listingsTarget.toFixed(2) : 'Provide Goals'} />
                          </Col>
                        </Form.Group>
                        <Form.Group className='d-flex w-100' controlId='avgSale'>
                          <Form.Label column sm='6' className="text-left">
                            Average Sale Price
                          </Form.Label>
                          <Col sm='6'>
                            <Form.Control plaintext readOnly defaultValue={`$${salePriceForCalc.toLocaleString()}`} />
                          </Col>
                        </Form.Group>
                        <Form.Group className='d-flex w-100' controlId='avgSale'>
                          <Form.Label column sm='6' className="text-left">
                            Number of fall-throughs in sales
                          </Form.Label>
                          <Col sm='6'>
                            <Form.Control plaintext readOnly defaultValue={props.agentDashMetrics.UnfinishedSaleCount} />
                          </Col>
                        </Form.Group>
                        <Form.Group className='d-flex w-100' controlId='notes'>
                          <Form.Label column sm='6' className="text-left">
                            Notes
                          </Form.Label>
                          <Col sm='6'>
                            <TextField className='w-100' multiline variant='outlined' onChange={(e) => handleNoteChange(e)} defaultValue={queriedAgentNotes} />
                          </Col>
                        </Form.Group>
                        <Form.Group controlId='submit'>
                          <Button className='col-sm-6' disabled={!(agentGoal || finalGoal || agentNotes)} size='sm' variant='secondary' type='submit'>Submit Changes</Button>
                        </Form.Group>
                        <Toast key='submitToast' onClose={handleCloseToast} show={toastOpenStatus} delay={3000} autohide={toastDismissStatus} className={`toast ${toastClass}`}>
                          <Toast.Header key='toast-header'>
                            {toastMessage}
                          </Toast.Header>
                        </Toast>
                      </Form>
                    </CardContent>
                  </Card>
                </div>
                <div className='d-flex dashRow w-100 flex-column align-items-center'>
                  <Card className='topCards' variant='outlined'>
                    <CardContent className='d-flex align-items-center  w-100'>
                      <ResponsiveContainer aspect={2} width='99%' height='99%'>
                        <LineChart
                          className='chart'
                          data={listData}
                          margin={{
                            top: 10, right: 30, left: 20, bottom: 20,
                          }}
                        >
                          <Line name='5 Year Seasonality' type='monotone' dataKey='Five_Year' stroke='#8884d8' />
                          <Line name='Current Listings' type='monotone' dataKey='Listings' stroke='#9c438b' />
                          <Line name='2021 Goals' type='monotone' dataKey='Goals' stroke='#3dc54a' />
                          <CartesianGrid stroke='#ccc' />
                          <XAxis dataKey='month'>
                            <Label value='Months' offset={0} position='bottom' />
                          </XAxis>
                          <YAxis domain={[0, Math.ceil(highestList)]} label={{ value: 'Listings', angle: -90, position: 'insideLeft' }} />
                          <Tooltip />
                        </LineChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </Card>
                  <Card className='topCards' variant='outlined'>
                    <CardContent className='d-flex  w-100'>
                      <ResponsiveContainer aspect={2} width='99%' height='99%'>
                        <LineChart
                          data={volData}
                          margin={{
                            top: 0, right: 30, left: 20, bottom: 20,
                          }}
                        >
                          <Line name='5 Year Seasonality' type="monotone" dataKey="Five_Year" stroke="#8884d8" />
                          <Line name='Current Volume' type="monotone" dataKey="Volume" stroke="#9c438b" />
                          <Line name='2021 Goals' type="monotone" dataKey="Goals" stroke="#3dc54a" />
                          <CartesianGrid stroke="#ccc" />
                          <XAxis dataKey="month">
                            <Label value="Months" offset={0} position="bottom" />
                          </XAxis>
                          <YAxis domain={[0, Math.ceil(highestVol)]} label={{ value: 'Volume', angle: -90, position: 'insideLeft' }} />
                          <Tooltip />
                        </LineChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </>
          )
        }
        return (
          <LoadingSpinner />
        )
      }}
    />
  )
}

const goalSaveMutation = graphql`
  mutation AgentDashMutation(
    $input: AgentGoalSaveInput!
  ) {
    agentGoalSaveMutation(input: $input) {
      Response
    }
  }
`
