import React, { useState } from 'react'
import { Input } from '@material-ui/core'

export default function AgentSearchBox(props) {

  const [state, setState] = useState('')

  const handleSearch = event => {
    event.preventDefault()
    props.handleSearch(state)
  }

  const handleChange = event => {
    setState(event.target.value)
  }

  return (
    <div>
      <form noValidate autoComplete="off" onSubmit={handleSearch}>
        <Input
          placeholder="Search for agents"
          color="primary"
          fullWidth
          variant='outlined'
          onChange={handleChange}
        />
      </form>
    </div>
  )
}
