import { Role } from '../constants'
import moment from 'moment'
import config from './config'

const validateAuth = async () => {
  const token = window.sessionStorage.getItem('tokenExpire')
  if (token) {
    if (token <= parseInt(moment().valueOf())) {
      // refresh token for user
      const response = await fetch(config.GRAPHQL_URL, {
        method: 'POST',
        headers: {
          Authorization: `${window.sessionStorage.getItem('userToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `
            query authTokenQuery {
              refreshToken {
                JWT
              }
            }`
        })
      });
      if (response.errors) {
        console.error('There was a problem refreshing your session')
        window.sessionStorage.clear()
        window.location.reload()
      } else {
        const parsedResp = await response.json()
        const newExpire = 1200000 + parseInt(moment().valueOf())
        window.sessionStorage.setItem('userToken', parsedResp.data?.refreshToken?.JWT)
        window.sessionStorage.setItem('tokenExpire', newExpire)
        return {
          ok: true
        }
      }
    } else {
      return {
        ok: true
      }
    }
  } else {
    console.error('Invalid or Missing Token')
    return false
  }
}

const checkToken = async (auth) => {
  const storage = window.sessionStorage
  if (auth) {
    try {
      const tokenAge = (parseInt(auth.Expires) * 1000)
      const willExpireAt = tokenAge + parseInt(moment().valueOf())
      const userRole = auth.Roles.filter(value => Object.keys(Role).includes(value))[0]
      return (
        storage.setItem('userToken', auth.JWT),
        storage.setItem('tokenExpire', willExpireAt),
        storage.setItem('userRoles', Role[userRole]),
        storage.setItem('userOffice', JSON.stringify(auth.OfficeID)),
        storage.setItem('userOfficeName', auth.OfficeInfo),
        storage.setItem('userName', auth.User),
        storage.setItem('savedSelections', JSON.stringify(auth.SavedSelections))
      )
    } catch (err) {
      console.error(err)
    }
  } else {
    console.error('Invalid or Missing Token')
  }
}

export { checkToken, validateAuth }
