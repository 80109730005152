import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

export default function NotFoundPage() {
  // style handlers
  const useStyles = makeStyles({
    main404Container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '100px'
    },
    notFoundTextContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    },
    nontFoundElementsCon: {
      width: '50%',
      display: 'flex',
      flexDirection: 'column'
    }
  })
  const classes = useStyles()

  return (
    <div className={classes.main404Container}>
      <div className={classes.notFoundTextContainer}>
        <div className={classes.nontFoundElementsCon}>
          <h2 className='text-center'>Sorry, that page doesn't seem to exist.</h2>
          <p className='text-center'>If you believe that this message has been shown in error, please submit a help ticket through the blue Question Mark icon in the bottom right of your screen.</p>
        </div>
      </div>
    </div>
  )
}
