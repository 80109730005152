/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EditTeamInput = {|
  TeamName?: ?string,
  OfficeID?: ?string,
  TeamLeadID?: ?string,
  TeamAgents?: ?$ReadOnlyArray<?AgentInput>,
  IsCareerDev?: ?boolean,
  IsSET?: ?boolean,
  id?: ?string,
  clientMutationId?: ?string,
|};
export type AgentInput = {|
  AgentNum?: ?string,
  TeamAddDate?: ?string,
|};
export type GroupManagementModalEditMutationVariables = {|
  input: EditTeamInput
|};
export type GroupManagementModalEditMutationResponse = {|
  +editTeamMutation: ?{|
    +Response: ?string
  |}
|};
export type GroupManagementModalEditMutation = {|
  variables: GroupManagementModalEditMutationVariables,
  response: GroupManagementModalEditMutationResponse,
|};
*/


/*
mutation GroupManagementModalEditMutation(
  $input: EditTeamInput!
) {
  editTeamMutation(input: $input) {
    Response
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EditTeamPayload",
    "kind": "LinkedField",
    "name": "editTeamMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "Response",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GroupManagementModalEditMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GroupManagementModalEditMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "646ef285c568dce74a1ced2335d9fdd5",
    "id": null,
    "metadata": {},
    "name": "GroupManagementModalEditMutation",
    "operationKind": "mutation",
    "text": "mutation GroupManagementModalEditMutation(\n  $input: EditTeamInput!\n) {\n  editTeamMutation(input: $input) {\n    Response\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '10affea8fdeb052aca44c44594c101bd';

module.exports = node;
