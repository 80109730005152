import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import environment from './relay/Environment'
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import LoadingSpinner from './components/LoadingSpinner'

ReactDOM.render(
  <BrowserRouter>
    <RelayEnvironmentProvider environment={environment}>
      <Suspense fallback={<LoadingSpinner />}>
        <App />
      </Suspense>
    </RelayEnvironmentProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
