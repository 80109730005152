export const TableViewType = Object.freeze({
    Offices: 'offices',
    Office: 'office',
    Agent: 'agent',
    Transactions: 'transactions'
})

export const Role = Object.freeze({
    Executive: 'Executive',
    EVP: 'EVP',
    Manager: 'Manager',
    CareerDevManager: 'CareerDevManager',
    CareerDevEVP: 'CareerDevEVP',
    SuperAdmin: 'SuperAdmin',
})

export const ModalType = Object.freeze({
    CreateCareerDevGroup: 'cdcreate',
    CreateSET: 'setcreate',
    CreateGroup: 'create',
    EditCareerDevGroup: 'cdedit',
    EditSET: 'setedit',
    EditGroup: 'edit',
    isCareerDevType: role => role === ModalType.CreateCareerDevGroup || role === ModalType.EditCareerDevGroup,
    isSETType: role => role === ModalType.CreateSET || role === ModalType.EditSET,
    isEditType: role => role === ModalType.EditGroup || role === ModalType.EditCareerDevGroup || role === ModalType.EditSET
})