/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OfficeDashContent_query$ref: FragmentReference;
declare export opaque type OfficeDashContent_query$fragmentType: OfficeDashContent_query$ref;
export type OfficeDashContent_query = {|
  +officeDashMetrics: ?{|
    +ListingData: ?$ReadOnlyArray<?{|
      +Month: ?string,
      +HistValue: ?number,
      +CurrValue: ?number,
      +GoalValue: ?number,
    |}>,
    +VolumeData: ?$ReadOnlyArray<?{|
      +Month: ?string,
      +HistValue: ?number,
      +CurrValue: ?number,
      +GoalValue: ?number,
    |}>,
    +Listing12Rolling: ?$ReadOnlyArray<?{|
      +Month: ?string,
      +HistValue: ?number,
      +CurrValue: ?number,
      +GoalValue: ?number,
    |}>,
    +Volume12Rolling: ?$ReadOnlyArray<?{|
      +Month: ?string,
      +HistValue: ?number,
      +CurrValue: ?number,
      +GoalValue: ?number,
    |}>,
    +AvgSalePrice: ?$ReadOnlyArray<?{|
      +HistValue: ?number,
      +CurrValue: ?number,
    |}>,
    +OfficeAgents: ?$ReadOnlyArray<?{|
      +Hide: ?boolean,
      +EmployeeNum: ?string,
      +EmployeeId: ?string,
      +AgentName: ?string,
      +Listings: ?number,
      +Volume: ?string,
      +TotalSides: ?string,
      +AffilDate: ?string,
      +AgentGoal: ?string,
      +FinalGoal: ?string,
      +Notes: ?string,
      +Ident: ?string,
      +IsTerminated: ?boolean,
      +Teams: ?$ReadOnlyArray<?{|
        +TeamName: ?string,
        +IsTeamLead: ?string,
        +TeamAddDate: ?string,
        +isCareerDev: ?boolean,
        +isSET: ?boolean,
      |}>,
    |}>,
    +OfficeTransactionsClosed: ?$ReadOnlyArray<?{|
      +id: string,
      +Ident: ?string,
      +Side: ?string,
      +CloseDate: ?string,
      +Agent: ?string,
      +VolumeCredit: ?string,
      +SellPrice: ?string,
      +Address: ?string,
    |}>,
    +OfficeTransactionsPending: ?$ReadOnlyArray<?{|
      +id: string,
      +Ident: ?string,
      +Side: ?string,
      +CloseDate: ?string,
      +Agent: ?string,
      +VolumeCredit: ?string,
      +SellPrice: ?string,
      +Address: ?string,
    |}>,
    +VolumeGoal: ?number,
    +ListGoal: ?number,
  |},
  +$refType: OfficeDashContent_query$ref,
|};
export type OfficeDashContent_query$data = OfficeDashContent_query;
export type OfficeDashContent_query$key = {
  +$data?: OfficeDashContent_query$data,
  +$fragmentRefs: OfficeDashContent_query$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "HistValue",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "CurrValue",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "Month",
    "storageKey": null
  },
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "GoalValue",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Ident",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "Side",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "CloseDate",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "Agent",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "VolumeCredit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "SellPrice",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "Address",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "Ident"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "isTeam"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamMembers"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "OfficeDashContent_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "Ident",
          "variableName": "Ident"
        },
        {
          "kind": "Variable",
          "name": "isTeam",
          "variableName": "isTeam"
        },
        {
          "kind": "Variable",
          "name": "teamMembers",
          "variableName": "teamMembers"
        },
        {
          "kind": "Variable",
          "name": "teamName",
          "variableName": "teamName"
        }
      ],
      "concreteType": "Office",
      "kind": "LinkedField",
      "name": "officeDashMetrics",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OfficeDashboard",
          "kind": "LinkedField",
          "name": "ListingData",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OfficeDashboard",
          "kind": "LinkedField",
          "name": "VolumeData",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OfficeDashboard",
          "kind": "LinkedField",
          "name": "Listing12Rolling",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OfficeDashboard",
          "kind": "LinkedField",
          "name": "Volume12Rolling",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OfficeDashboard",
          "kind": "LinkedField",
          "name": "AvgSalePrice",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Agent",
          "kind": "LinkedField",
          "name": "OfficeAgents",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "Hide",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "EmployeeNum",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "EmployeeId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "AgentName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "Listings",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "Volume",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "TotalSides",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "AffilDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "AgentGoal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "FinalGoal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "Notes",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "IsTerminated",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AgentTeams",
              "kind": "LinkedField",
              "name": "Teams",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "TeamName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "IsTeamLead",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "TeamAddDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isCareerDev",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isSET",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Transaction",
          "kind": "LinkedField",
          "name": "OfficeTransactionsClosed",
          "plural": true,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Transaction",
          "kind": "LinkedField",
          "name": "OfficeTransactionsPending",
          "plural": true,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "VolumeGoal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ListGoal",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '96a0cd66d9fcc13d8b2a8eae0edd9fa1';

module.exports = node;
