import { makeStyles, TextField } from '@material-ui/core'
import { GridFooter } from "@mui/x-data-grid-pro";
import React from 'react'
import { Role, TableViewType } from '../../constants'
import { millionsFormatter, numberFormatter } from '../../utils/formatters'
import { selectExecutive, selectOffice } from '../../screens/Goals/stateSelectors'

/**
 * Footer creates the correct dynamic text fields based on view type and role.
 * If selection is for "All Offices", any officeId for that role is used.
 * @param {Object} state
 * @param {TableViewType} viewType
 * @param {String} officeId
 * @returns
 */
export default function CustomFooter({ state, viewType, officeId }) {
    const useStyles = makeStyles({
        root: {
            display: 'inline-flex',
        },
        item: {
            padding: '10px'
        },
        paginate: {
            justifyContent: 'flex-start!important',
            '& p': {
                marginBottom: 0
            }
        }
    })
    const classes = useStyles()
    const dynamicData = assembleDynamicData(state, viewType, officeId)
    return (
        <>
            <GridFooter className={classes.paginate} />
            <div className={classes.root}>
                {
                    dynamicData.map((item, index) => {
                        return (
                            <div className={classes.item} key={index}>
                                <TextField
                                    label={item.headerName}
                                    variant="outlined"
                                    value={item.data}
                                    InputProps={{
                                        readOnly: true,
                                        'data-testid': item.headerName
                                    }}
                                    inputProps={{ "name": item.headerName }}
                                />
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

function assembleDynamicData(state, viewType, officeId) {
    let data, keys;
    switch (state.role) {
        case Role.SuperAdmin:
        case Role.Executive:
            if (viewType === TableViewType.Office) {
                keys = state.content.dynamicTotals.officeKeys
                data = selectOffice(officeId, state)
            } else {
                keys = state.content.dynamicTotals.officesKeys
                data = selectExecutive(officeId, state)
            }
            break
        case Role.CareerDevEVP:
        case Role.EVP:
            if (viewType === TableViewType.Office) {
                keys = state.content.dynamicTotals.officeKeys
                data = selectOffice(officeId, state)
            } else {
                keys = state.content.dynamicTotals.officesKeys
                data = state
            }
            break
        case Role.CareerDevManager:
        case Role.Manager:
            if (viewType === TableViewType.Office) {
                keys = state.content.dynamicTotals.officeKeys
                data = state.Office
            } else {
                keys = state.content.dynamicTotals.officesKeys
                data = state
            }
            break
        default:
            break
    }

    return keys.map(item => {
        return {
            ...item,
            data: data[item.key]
        }
    })
        .map(item => {
            if (item.type === 'currency') {
                if (item.key === 'DynamicAgentGoal' || item.key === 'DynamicFinalGoal') {
                    return { ...item, data: millionsFormatter(item.data) }
                }
                return { ...item, data: millionsFormatter(Number(item.data / 1000000)) }
            }
            if (item.type === 'number') return { ...item, data: numberFormatter(item.data) }
            return item
        })
}
