import React, { useState, useEffect, useReducer } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { styled, useTheme } from '@mui/material/styles';
import fetchGraphQL from '../../utils/fetchGraphQL'
import { years, months } from '../../utils/constants'
import { useHistory, useLocation } from 'react-router'
import { initialState, reducer } from './reducer'
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import { Link } from 'react-router-dom'
import BasicReport from '../../reports/BasicReport'
import YOYReport from '../../reports/YOYReport'
import { DateTime } from "luxon"
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import config from '../../utils/config';
import StagingIndicator from '../../components/StagingIndicator';

const drawerWidth = 240;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    })
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  backgroundColor: '#522446',
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function Reports({role, office, saveLocation}) {
  const history = useHistory();
  const token = window.sessionStorage.getItem('userToken')
  const [state, dispatch] = useReducer(reducer, { role, office }, initializer)
  const location = useLocation();
    useEffect(() => {
      if (token && token !== 'undefined') {
        saveLocation('/reports');
      } else {
        window.sessionStorage.clear()
        history.push('/login?timedOut=true')
      }
      }, [location, saveLocation, token, history]);

  // style handlers
  const useStyles = makeStyles({
    root: {
      flexGrow: 1,
    },
    agentListContainer: {},
    bodyBuffer: {
      height: '92px'
    },
    headerContainer: {
      padding: 50,
      justifyContent: 'start',
      width: '100%',
      display: 'flex',
      backgroundColor: '#522446',
      color: 'white'
    },
    headerText: {
      marginLeft: 50
    },
    helpText: {
      paddingLeft: '20px'
    },
    container: {
      marginTop: 50
    },
    dateContainers: {
      backgroundColor: 'white',
      padding: '10px'
    },
    gridItem: {
      flexGrow: 1
    },
    image: {
      height: '200px'
    },
    link: {
      textDecoration: 'none',
      color: 'inherit',
      '&:hover': {
        textDecoration: 'none',
        color: 'inherit'
      }
    },
    pickerContainer: {
      paddingRight: '10px'
    },
    pickers: {
      display: 'flex',
      width: '100%',
      padding: '10px 20px',
      flexDirection: 'row-reverse'
    },
    reportHeading: {
      width: '30%',
      alignSelf: 'center'
    },
    reportContainer: {
      width: '95vw'
    },
    selectButtons: {
      backgroundColor: 'white',
      '&.Mui-focused fieldset': {
        border: 'none'
      }
    },
    YoYPickers: {
      display: 'flex',
      width: '100%',
      padding: '10px 20px'
    }
  })
  const classes = useStyles()
  const theme = useTheme();

  // drawer handlers
  const [sideOpen, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleReportToggle = (report) => {
    handleDrawerClose();
    const currMonth = DateTime.now().get('month')
    const currYear = DateTime.now().get('year')
    if (report !== 'Office Year-Over-Year') {
      if (state.month === currMonth && state.year === currYear) {
        dispatch({ type: 'TOGGLE_REPORT', payload: { report: report } })
        if (state.iteration === 'y') { // the fetching won't repeat the same call so we have to switch between the two
          dispatch({ type: 'TOGGLE_M_ITERATION' })
          dispatch({ type: 'CHANGE_DATE_MONTH', payload: { month: { target: { value: currMonth }} } })
        } else {
          dispatch({ type: 'TOGGLE_Y_ITERATION' })
          dispatch({ type: 'CHANGE_DATE_YEAR', payload: { year: { target: { value: currYear }} } })
        }
      } else {
        dispatch({
          type: 'CHANGE_DATE_REPORT',
          payload: {
            month: currMonth,
            year: currYear,
            report: report
          }
        })
      }
    } else {
      dispatch({ type: 'TOGGLE_REPORT', payload: { report: report } })
    }
  }

  useEffect(() => {
    if (!token || token === 'undefined') {
      window.sessionStorage.clear()
      history.push('/login?timedOut=true')
    }
    let isMounted = true;
    dispatch({ type: 'TOGGLE_PAGE_LOAD' })
    fetchGraphQL(createQueryBasedOnReport(state))
      .then(response => {
        if (response.errors) {
          dispatch({ type: 'TOGGLE_PAGE_LOAD' })
          dispatch({ type: 'TOGGLE_SNACKBAR', payload: { type: 'error', info: 'Please contact your IT admin or report this error.', showSnackbar: !state.snackbar.showSnackbar } })
          return
        } else {
          if (!isMounted) {
            return null;
          } else {
            dispatch({ type: 'TOGGLE_PAGE_LOAD' })
            dispatch({ type: 'FETCH_DATA', payload: { ...response.data } })
            return;
          }
        }
      })
      .catch(response => console.log(response))
    return () => isMounted = false
  }, [state.report, state.endDate, state.startDate, office, state.firstDay, state.lastDay])  // eslint-disable-line react-hooks/exhaustive-deps

  // Render reports section
  const renderReport = (state, report) => {
    if (report !== 'Office Year-Over-Year') {
      return (
        <div key='mainReportContainer' className={classes.agentListContainer}>
          <br />
          <div key='divReportContainer' className={classes.reportContainer}>
            <BasicReport state={state} dispatch={dispatch} data={state.Reports} office={office} month={state.month} />
          </div>
        </div>
      )
    } else {
      return (
        <div key='mainReportContainer' className={classes.agentListContainer}>
          <br />
          <div key='divReportContainer' className={classes.reportContainer}>
            <YOYReport state={state} dispatch={dispatch} data={state.YOYReport} office={office} month={state.month} />
          </div>
        </div>
      )
    }
  }

  const renderView = state => {
    switch (state.report) {
      case 'Listings':
      case 'Closed Units':
      case 'Booked Units':
      case 'Booked Volume':
      case 'Closed Volume':
      case 'Office Year-Over-Year':
        return renderReport(state, state.report)
      default:
        return <div>Invalid report type. Please contact your IT desk.</div>
    }
  }

  return (
    <>
      {config.IS_STAGING === 'true' ? <StagingIndicator /> : null}
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar className='w-100 d-flex justify-content-between flex-row' position="fixed" open={sideOpen}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(sideOpen && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography className={classes.headerText} variant="h4" noWrap component="div">
              {state.report} Report
            </Typography>
          </Toolbar>
          <div className={classes.pickerContainer}>
            {/* Add conditional section here to render out YOY date picker if YOY is selected */}
            {state.report !== 'Office Year-Over-Year' &&
              <>
                <div className={classes.pickers}>
                  <span>
                    <Select
                      labelId="year-select-label"
                      id="year-selector"
                      value={state.year}
                      label="Year"
                      onChange={selected => {
                        dispatch({ type: 'TOGGLE_Y_ITERATION' });
                        dispatch({ type: 'CHANGE_DATE_YEAR', payload: { year: selected } });
                      }}
                      className={classes.selectButtons}
                      disabled={state.isLoading}
                    >
                      {years.map(year => {
                        return <MenuItem key={`year-${year}`} value={year}>{year}</MenuItem>
                      })}
                    </Select>
                  </span>
                  <span>
                    <Select
                      labelId="month-select-label"
                      id="month-selector"
                      value={state.month}
                      label="Month"
                      onChange={selected => {
                        dispatch({ type: 'TOGGLE_M_ITERATION' });
                        dispatch({ type: 'CHANGE_DATE_MONTH', payload: { month: selected } });
                      }}
                      className={classes.selectButtons}
                      disabled={state.isLoading}
                    >
                      {months.map((month, index) => {
                        if (index === 2) {
                          return <MenuItem key={`month-${index + 1}`} value={index + 1}>{`${month} (Q1)`}</MenuItem>
                        } else if (index === 5) {
                          return <MenuItem key={`month-${index + 1}`} value={index + 1}>{`${month} (Q2)`}</MenuItem>
                        } else if (index === 8) {
                          return <MenuItem key={`month-${index + 1}`} value={index + 1}>{`${month} (Q3)`}</MenuItem>
                        } else if (index === 11) {
                          return <MenuItem key={`month-${index + 1}`} value={index + 1}>{`${month} (Q4)`}</MenuItem>
                        } else {
                          return <MenuItem key={`month-${index + 1}`} value={index + 1}>{month}</MenuItem>
                        }
                      })}
                    </Select>
                  </span>
                </div>
                <p className={classes.helpText}>End date to include in totals</p>
              </>
            }
            {state.report === 'Office Year-Over-Year' &&
              <>
                <div className={classes.YoYPickers}>
                  <span className={classes.dateContainers}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                          label="Start Date"
                          inputFormat="MM/dd/yyyy"
                          format="MM/dd/yyyy"
                          value={state.firstDay}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={selected => {
                            dispatch({ type: 'CHANGE_DATE_YOY_START', payload: { firstDay: new Date(selected) } });
                          }}
                          className={classes.yoySelectButtons}
                          disabled={state.isLoading}
                        />
                    </LocalizationProvider>
                  </span>
                  <span className={classes.dateContainers}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                          label="End Date"
                          inputFormat="MM/dd/yyyy"
                          format="MM/dd/yyyy"
                          value={state.lastDay}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={selected => {
                            dispatch({ type: 'CHANGE_DATE_YOY_END', payload: { lastDay: new Date(selected) } });
                          }}
                          className={classes.yoySelectButtons}
                          disabled={state.isLoading}
                        />
                    </LocalizationProvider>
                  </span>
                </div>
              </>
            }
          </div>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant='persistent'
          anchor='left'
          open={sideOpen}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <ListItem button key='home'>
              <Link to='/home' id='navButton' className={classes.link}>Home</Link>
            </ListItem>
            <ListItem button key='dashboards'>
              <Link to='/dashboards' id='navButton' className={classes.link}>Dashboards</Link>
            </ListItem>
            <ListItem button key='goals'>
              <Link to='/goals' id='navButton' className={classes.link}>Goals Interface</Link>
            </ListItem>
            <ListItem button key='logout' onClick={() => {window.sessionStorage.clear(); window.location.replace('/')}}>Log Out</ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button key='Listings' onClick={() => handleReportToggle('Listings')}>
              <ListItemText primary='Listings' />
            </ListItem>
            <ListItem button key='Booked Units' onClick={() => handleReportToggle('Booked Units')}>
              <ListItemText primary='Booked Units' />
            </ListItem>
            <ListItem button key='Booked Volume' onClick={() => handleReportToggle('Booked Volume')}>
              <ListItemText primary='Booked Volume' />
            </ListItem>
            <ListItem button key='Closed Units' onClick={() => handleReportToggle('Closed Units')}>
              <ListItemText primary='Closed Units' />
            </ListItem>
            <ListItem button key='Closed Volume' onClick={() => handleReportToggle('Closed Volume')}>
              <ListItemText primary='Closed Volume' />
            </ListItem>
            <ListItem button key='Office Year-Over-Year' onClick={() => handleReportToggle('Office Year-Over-Year')}>
              <ListItemText primary='Office Year-Over-Year' />
            </ListItem>
          </List>
        </Drawer>
        <Main open={sideOpen} className='d-flex flex-column'>
          <div className={classes.bodyBuffer} />
          {state.isLoading ? 
            <Backdrop sx={{ display: 'flex', flexDirection: 'column', color: '#fff', zIndex: '999' }} open={state.isLoading}>
              <Typography className={classes.headerText} variant="h5" noWrap component="div">
                Please wait...
              </Typography>
              <CircularProgress color="secondary" />
            </Backdrop> : 
            null
          }
          {(state && (state.Reports || state.YearOverYear)) ? renderView(state) : 
            <Backdrop sx={{ display: 'flex', flexDirection: 'column', color: '#fff', zIndex: '999' }} open={state.isLoading}>
              <Typography className={classes.headerText} variant="h5" noWrap component="div">
                Please wait...
              </Typography>
              <CircularProgress color="secondary" />
            </Backdrop>
          }
        </Main>
      </Box>
      <Snackbar open={state.snackbar.type === 'error' && state.snackbar.showSnackbar} onClose={() => dispatch({ type: 'TOGGLE_SNACKBAR', payload: { type: '', showSnackbar: !state.snackbar.showSnackbar } })} >
          <Alert elevation={6} variant='filled' severity='error' onClose={() => dispatch({ type: 'TOGGLE_SNACKBAR', payload: { type: 'error', info: '', showSnackbar: !state.snackbar.showSnackbar } })}>
            There's an error: {state.snackbar.info}
          </Alert>
      </Snackbar>
    </>
  )
}

function createQueryBasedOnReport(state) {
  switch (state.report) {
    case 'Listings':
    case 'Closed Units':
    case 'Booked Units':
    case 'Booked Volume':
    case 'Closed Volume':
      return `
      query QueriesReportsQuery {
        Reports(startDate: "${state.startDate}", endDate: "${state.endDate}", reportType: "${state.report}") {
          DataSections {
            EVP
            Rows {
              Ident
              OfficeName
              YtdGoal
              YtdActual
              YtdVariance
              MonthlyGoals
              MonthlyActuals
            }
            YTDGoal
            YTDActual
            YTDVariance
            MonthlyActualTotal
            MonthlyGoalTotal
          }
          YTDGoalTotal
          YTDActualTotal
          YTDTotalVariance
          MonthlyGoalTotals
          MonthlyActualTotals
        }
      }`
    case 'Office Year-Over-Year':
      return `
      query QueriesReportsQuery {
        YOYReport(startDate: "${state.firstDay}", endDate: "${state.lastDay}") {
          GrandTotals {
            Name
            YTDGoal
            YTDActual
            YTDActualPrev
            YOYPerc
          }
          EVPs {
            Name
            GrandTotals {
              Name
              YTDGoal
              YTDActual
              YTDActualPrev
              YOYPerc
            }
            Offices {
              Ident
              Name
              Reports {
                Name
                YTDGoal
                YTDActual
                YTDActualPrev
                YOYPerc
              }
            }
          }
        }
      }`
    default:
      return
  }
}

/**
 * This function initializes the state lazily. Since we are not passing the reducer
 * from the root of the app, we must apply the props provided in a non-mutable way
 * to the initial state.
 * @param {*} state
 */
 function initializer(state) {
  return { ...state, ...initialState }
}

export default Reports