/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AgentDetailModalDetailQueryVariables = {|
  agentID: string
|};
export type AgentDetailModalDetailQueryResponse = {|
  +agentDetails: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +Ident: ?string,
        +Address: ?string,
        +CloseDate: ?string,
        +SellPrice: ?string,
        +VolumeCredit: ?string,
        +Side: ?string,
        +SaleStatus: ?string,
      |}
    |}>
  |}
|};
export type AgentDetailModalDetailQuery = {|
  variables: AgentDetailModalDetailQueryVariables,
  response: AgentDetailModalDetailQueryResponse,
|};
*/


/*
query AgentDetailModalDetailQuery(
  $agentID: String!
) {
  agentDetails(agentID: $agentID) {
    edges {
      node {
        id
        Ident
        Address
        CloseDate
        SellPrice
        VolumeCredit
        Side
        SaleStatus
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "agentID"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "agentID",
        "variableName": "agentID"
      }
    ],
    "concreteType": "TransactionConnection",
    "kind": "LinkedField",
    "name": "agentDetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TransactionEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Transaction",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "Ident",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "Address",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "CloseDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "SellPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "VolumeCredit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "Side",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "SaleStatus",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentDetailModalDetailQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AgentDetailModalDetailQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "61ba2c8f2987ce9d5ac723d940b75660",
    "id": null,
    "metadata": {},
    "name": "AgentDetailModalDetailQuery",
    "operationKind": "query",
    "text": "query AgentDetailModalDetailQuery(\n  $agentID: String!\n) {\n  agentDetails(agentID: $agentID) {\n    edges {\n      node {\n        id\n        Ident\n        Address\n        CloseDate\n        SellPrice\n        VolumeCredit\n        Side\n        SaleStatus\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bc41019e12714807886e7d931a08766c';

module.exports = node;
