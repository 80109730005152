import { Box, List, ListItem, ListItemText, makeStyles } from '@material-ui/core'
import React from 'react'

export default function AgentResult({ agentList, selectAgent }) {
    const useStyles = makeStyles({
        box: {
            border: '1px solid lightgrey',
            borderRadius: '4px',
            padding: '10px',
            height: '250px',
            overflow: 'auto'
        },
        item: {
            borderBottom: '1px solid lightgrey',
            paddingBottom: '5px'
        }
    })
    const classes = useStyles()

    return (
        <Box className={classes.box}>
            <h6>Results</h6>
            <List>
                {agentList?.length > 0 ? agentList.map((agent, index) => {
                    return (
                        <ListItem button key={index} className={classes.item} onClick={() => selectAgent(agent)}>
                            <ListItemText primary={agent.EmployeeName} />
                        </ListItem>
                    )
                }) : <ListItem><ListItemText primary="No results." /></ListItem>
                }
            </List>
        </Box>
    )
}