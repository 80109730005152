import { DateTime } from "luxon"

const currMonth = DateTime.now().get('month')
const currYear = DateTime.now().get('year')
const today = DateTime.now().get('day')
const parsedToday = parseInt(today) > 9 ? today : `0${today}`

export const initialState = {
    snackbar: {
      showSnackbar: false,
      info: '',
      type: ''
    },
    showModal: false,
    startDate: `${currYear}-01-01T05:00:00.000Z`,
    endDate: DateTime.now().toISO(),
    firstDay: DateTime.now().minus({ years: 1 }).toISO(),
    lastDay: DateTime.now().toISO(),
    report: 'Listings',
    isLoading: false,
    month: currMonth,
    year: currYear,
    iteration: ''
}

export function reducer(state, action) {
    switch (action.type) {
        case 'TOGGLE_SNACKBAR':
          return { ...state, snackbar: action.payload }
        case 'TOGGLE_Y_ITERATION':
          return { ...state, iteration: 'y' }
        case 'TOGGLE_M_ITERATION':
          return { ...state, iteration: 'm' }
        case 'TOGGLE_REPORT':
          return { ...state, report: action.payload.report }
        case 'FETCH_DATA':
          return { ...state, ...action.payload }
        case 'CHANGE_DATE_REPORT':
          const formattedMonth = parseInt(action.payload.month) > 9 ? action.payload.month : `0${action.payload.month}`
          return {
            ...state,
            endDate: `${action.payload.year}-${formattedMonth}-${parsedToday}T05:00:00.000Z`,
            startDate: `${action.payload.year}-01-01T05:00:00.000Z`,
            month: action.payload.month,
            year: action.payload.year,
            report: action.payload.report
          }
        case 'CHANGE_DATE_MONTH':
          const parsedMonth = parseInt(action.payload.month.target.value) > 9 ? action.payload.month.target.value : `0${action.payload.month.target.value}`
          if (state.month === action.payload.month.target.value && state.year === currYear) {
            return {
              ...state,
              endDate: `${state.year}-${parsedMonth}-${parsedToday}T05:00:00.000Z`,
              startDate: `${state.year}-01-01T05:00:00.000Z`,
              month: action.payload.month.target.value
            }
          } else {
            const dayAfter = action.payload.month.target.value === 12
              ? `${state.year}-12-31T05:00:00.000Z`
              : DateTime.local(parseInt(state.year), (parseInt(parsedMonth) + 1), 1).minus({ days: 1 }).toISO()
            return {
              ...state,
              endDate: dayAfter,
              startDate: `${state.year}-01-01T05:00:00.000Z`,
              month: action.payload.month.target.value
            }
          }
        case 'CHANGE_DATE_YEAR':
          if (state.year === action.payload.year.target.value && state.month === currMonth) {
            const nowEnd = DateTime.local(parseInt(action.payload.year.target.value), parseInt(state.month), today).toISO()
            return {
              ...state,
              endDate: nowEnd,
              startDate: `${action.payload.year.target.value}-01-01T05:00:00.000Z`,
              year: action.payload.year.target.value
            }
          } else {
            const dayAfter = state.month === 12 
              ? `${action.payload.year.target.value}-12-31T05:00:00.000Z`
              : DateTime.local(parseInt(action.payload.year.target.value), (parseInt(state.month) + 1), 1).minus({ days: 1 }).toISO()
            return {
              ...state,
              endDate: dayAfter,
              startDate: `${action.payload.year.target.value}-01-01T05:00:00.000Z`,
              year: action.payload.year.target.value
            }
          }
        case 'CHANGE_DATE_YOY_START':
          const currFYear = action.payload.firstDay.toString().slice(11,15)
          const parsedFirst = DateTime.fromJSDate(action.payload.firstDay).toISO()
          if (state.firstDay.includes(currFYear)) {
            return { ...state, firstDay: parsedFirst }
          } else {
            return {
              ...state,
              firstDay: parsedFirst
            }
          }
        case 'CHANGE_DATE_YOY_END':
          const parsedLast = DateTime.fromJSDate(action.payload.lastDay).toISO()
          const calcdFirst = DateTime.fromJSDate(action.payload.lastDay).minus({ years: 1 }).toISO()
          if (Date.parse(parsedLast) < Date.parse(state.firstDay)) {
            return {
              ...state,
              firstDay: calcdFirst,
              lastDay: parsedLast
            }
          } else {
            return { ...state, lastDay: parsedLast }
          }
        case 'TOGGLE_PAGE_LOAD':
          return { ...state, isLoading: !state.isLoading }
        case 'TOGGLE_PANEL':
          return { ...state, showPanel: !state.showPanel }
        default:
          return state
    }
}