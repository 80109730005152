/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AgentDashQueryVariables = {|
  agentID: string,
  Ident: string,
|};
export type AgentDashQueryResponse = {|
  +agentDashMetrics: ?{|
    +DashListingData: ?$ReadOnlyArray<?{|
      +Month: ?string,
      +HistValue: ?number,
      +CurrValue: ?number,
    |}>,
    +DashVolumeData: ?$ReadOnlyArray<?{|
      +Month: ?string,
      +HistValue: ?number,
      +CurrValue: ?number,
    |}>,
    +AvgSalePrice: ?$ReadOnlyArray<?{|
      +HistValue: ?number,
      +CurrValue: ?number,
    |}>,
    +CurrentGoal: ?$ReadOnlyArray<?string>,
    +AffilDate: ?string,
    +UnfinishedSaleCount: ?number,
  |}
|};
export type AgentDashQuery = {|
  variables: AgentDashQueryVariables,
  response: AgentDashQueryResponse,
|};
*/


/*
query AgentDashQuery(
  $agentID: String!
  $Ident: String!
) {
  agentDashMetrics(agentID: $agentID, Ident: $Ident) {
    DashListingData {
      Month
      HistValue
      CurrValue
    }
    DashVolumeData {
      Month
      HistValue
      CurrValue
    }
    AvgSalePrice {
      HistValue
      CurrValue
    }
    CurrentGoal
    AffilDate
    UnfinishedSaleCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "Ident"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "agentID"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "HistValue",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "CurrValue",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "Month",
    "storageKey": null
  },
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "Ident",
        "variableName": "Ident"
      },
      {
        "kind": "Variable",
        "name": "agentID",
        "variableName": "agentID"
      }
    ],
    "concreteType": "Agent",
    "kind": "LinkedField",
    "name": "agentDashMetrics",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OfficeDashboard",
        "kind": "LinkedField",
        "name": "DashListingData",
        "plural": true,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OfficeDashboard",
        "kind": "LinkedField",
        "name": "DashVolumeData",
        "plural": true,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OfficeDashboard",
        "kind": "LinkedField",
        "name": "AvgSalePrice",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "CurrentGoal",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "AffilDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "UnfinishedSaleCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AgentDashQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AgentDashQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "9cea57b28eda226f2e9ab7671cf5259b",
    "id": null,
    "metadata": {},
    "name": "AgentDashQuery",
    "operationKind": "query",
    "text": "query AgentDashQuery(\n  $agentID: String!\n  $Ident: String!\n) {\n  agentDashMetrics(agentID: $agentID, Ident: $Ident) {\n    DashListingData {\n      Month\n      HistValue\n      CurrValue\n    }\n    DashVolumeData {\n      Month\n      HistValue\n      CurrValue\n    }\n    AvgSalePrice {\n      HistValue\n      CurrValue\n    }\n    CurrentGoal\n    AffilDate\n    UnfinishedSaleCount\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '68cd9ff9d8beae1d45d471002c4d661f';

module.exports = node;
