import React, { useEffect, useState } from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { 
  Button,
  makeStyles,
  TextField,
  FormControl,
  MenuItem,
  Modal,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import graphql from 'babel-plugin-relay/macro'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useGroup from '../../hooks/useGroup'
import { ModalType, Role } from '../../constants'
import { commitMutation } from 'react-relay'
import environment from '../../relay/Environment'
import AgentSearchBox from '../AgentSearchBox/AgentSearchBox'
import fetchGraphQL from '../../utils/fetchGraphQL'
import AgentSearchResults from '../AgentResults/AgentResults'
import { selectAgent, selectOffice } from '../../screens/Goals/stateSelectors'
import { useHistory } from 'react-router'
import produce from 'immer'
import config from '../../utils/config'
library.add(faTrashAlt)

export default function GroupManagementModal({ state, dispatch }) {
  const history = useHistory();
  const token = window.sessionStorage.getItem('userToken')
  const useStyles = makeStyles(theme => ({
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      backgroundColor: '#fff',
      overflow: 'scroll',
      height: '80vh'
    },
    subtitle: {
      color: 'grey',
      textAlign: 'center'
    },
    footer: {
      flexDirection: 'row',
      justifyContent: 'center',
      display: 'flex',
      padding: '10px',
      '& > *': {
        margin: theme.spacing(1),
      },
    }
  }))
  const classes = useStyles()
  const [group, setGroup] = useGroup(state)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [errors, setErrors] = useState({
    groupLead: ''
  });
  const [removedAgents, setRemovedAgents] = useState([])
  const [addedAgents, setAddedAgents] = useState([])

  const handleConfirmOpen = () => {
    setConfirmOpen(true)
  }

  const handleConfirmClose = () => {
    setConfirmOpen(false)
  }

  const handleSearch = query => {
    setGroup({
      ...group,
      query: query
    })
  }

  useEffect(() => {
    if (config.TEST_ENV !== 'true') {
      if (!token || token === 'undefined') {
        window.sessionStorage.clear();
        history.push('/login?timedOut=true')
      }
    }
    if (group.query === '') return
    const queryType = agentSearchQuery(state, group.query, state.selectedOfficeIdent)
    if (queryType === null) return
    fetchGraphQL(queryType)
      .then(response => {
        if (!response || response.errors) {
          dispatch({ type: 'TOGGLE_SNACKBAR', payload: { type: 'error', info: 'There was a problem with GraphQL Server. Please contact your IT admin.', showSnackbar: true } })
        }
        setGroup({
          ...group,
          agentSearchList: response.data.QueryAgent
        })
      })
      .catch(error => {
        dispatch({ type: 'TOGGLE_SNACKBAR', payload: { type: 'error', info: error.message, showSnackbar: true } })
      })
    // eslint-disable-next-line
  }, [group.query])
  const handleGroupLeadChange = event => {
    if (!token || token === 'undefined') {
      window.sessionStorage.clear();
      history.push('/login?timedOut=true')
    }
    if (event.target.value === '' || event.target.value === 'None') {
      setGroup({
        ...group,
        groupLead: {
          AgentName: '',
          EmployeeNum: ''
        }
      })
      setErrors({ ...errors, groupLead: 'This field is required' })
      return
    }
    let groupLead
    if (ModalType.isCareerDevType(state.modalType)) {
      groupLead = state.CareerDevLeads.filter(lead => lead.Ident === event.target.value).map(lead => {
        return {
          AgentName: lead.EmployeeName,
          EmployeeNum: lead.Ident
        }
      })[0]
    } else if (ModalType.isSETType(state.modalType)) {
      const userName = window.sessionStorage.getItem('userOfficeName')
      groupLead = { Username: userName }
    } else {
      groupLead = selectOffice(state.selectedOfficeIdent, state).Agents
        .filter(agent => agent.EmployeeNum === event.target.value)[0]
    }
    setGroup({
      ...group,
      groupLead: groupLead
    })
    setErrors({ ...errors, groupLead: '' })
    return groupLead.AgentName
  }

  const handleGroupNameChange = event => {
    if (!token || token === 'undefined') {
      window.sessionStorage.clear();
      history.push('/login?timedOut=true')
    }
    setGroup({
      ...group,
      groupName: event.target.value
    })
    if (event.target.value === '') {
      setErrors({ ...errors, groupName: 'This field is required' })
    } else {
      setErrors({ ...errors, groupName: '' })
    }
  }

  const handleCloseModal = () => {
    dispatch({ type: 'TOGGLE_MODAL' })
    setGroup({
      groupAgents: [],
      groupName: '',
      groupLead: '',
      agentSearchList: [],
      query: ''
    })
  }
  const handleAgentSelect = selectedAgent => {
    const agentName = selectedAgent.EmployeeName
    if (!token || token === 'undefined') {
      window.sessionStorage.clear();
      history.push('/login?timedOut=true')
    }
    if (group.groupAgents.length > 0 &&
      group.groupAgents.map(agent => agent.AgentName.trim()).filter(agent => agent === agentName).length > 0) return
    const comparator = agent => agent.AgentName.trim() === agentName
    let agent = selectAgent(comparator, state)
    if (agent && (ModalType.isSETType(state.modalType) || ModalType.isCareerDevType(state.modalType))) {
      const now = new Date()
      const addMonth = now.getMonth() + 1
      agent = {
        ...agent,
        Teams: [{
          TeamName: group.groupName,
          IsTeamLead: false,
          TeamAddDate: `${now.getFullYear()}-${addMonth < 10 ? `0${addMonth}` : addMonth}`
        }]
      }
      let ident
      if (group.groupId) {
        ident = ModalType.isCareerDevType(state.modalType) ? group.groupLead.EmployeeNum : ModalType.isSETType(state.modalType) ? `SET${group.groupName}` : group.groupId
      } else {
        ident = agent.Ident
      }
      if (ModalType.isEditType(state.modalType)) {
        dispatch({
          type: 'UPDATE_AGENT', payload: {
            ...agent,
            addAgent: true,
            Ident: ident,
            backupIdent: agent.Ident
          }
        })
      }
      setGroup({
        ...group,
        groupAgents: [...group.groupAgents, agent],
        groupLeadList: ModalType.isCareerDevType(state.modalType) ? group.groupLeadList : []
      })
    } else if (ModalType.isSETType(state.modalType) || ModalType.isCareerDevType(state.modalType)) {
      const now = new Date()
      const addMonth = now.getMonth() + 1
      agent = {
        AffilDate: selectedAgent.AffilDate.slice(0,10),
        AgentGoal: "0",
        AgentName: agentName,
        EmployeeId: selectedAgent.EmployeeId,
        EmployeeNum: selectedAgent.EmployeeNum,
        FinalGoal: "0",
        Hide: false,
        Ident: selectedAgent.Ident,
        IsTerminated: selectedAgent.IsTerminated,
        Listings: 0,
        Notes: "",
        TotalSides: "0",
        Volume: "0",
        Teams: [{
          TeamName: group.groupName,
          IsTeamLead: false,
          TeamAddDate: `${now.getFullYear()}-${addMonth < 10 ? `0${addMonth}` : addMonth}`
        }]
      }
      let ident
      if (group.groupId) {
        ident = ModalType.isCareerDevType(state.modalType) ? group.groupLead.EmployeeNum : ModalType.isSETType(state.modalType) ? `SET${group.groupName}` : group.groupId
      } else {
        ident = agent.Ident
      }
      if (ModalType.isEditType(state.modalType)) {
        dispatch({
          type: 'UPDATE_AGENT', payload: {
            ...agent,
            addAgent: true,
            Ident: ident,
            backupIdent: agent.Ident
          }
        })
      }
      dispatch({
        type: 'TOGGLE_SNACKBAR', payload: {
          type: 'warning',
          showSnackbar: !state.snackbar.showSnackbar,
          info: `This agent's goal and metric data may not be accurate without a page refresh.`
        }
      })
      setGroup({
        ...group,
        groupAgents: [...group.groupAgents, agent],
        groupLeadList: ModalType.isCareerDevType(state.modalType) ? group.groupLeadList : []
      })
    }
    if (agent) {
      setAddedAgents([{
        ...agent
      }])
      setGroup({
        ...group,
        groupAgents: [...group.groupAgents, agent]
      })
    }
  }
  const handleGroupSave = (e) => {
    if (!token || token === 'undefined') {
      window.sessionStorage.clear();
      history.push('/login?timedOut=true')
    }
    e.preventDefault(e)
    let leadType
    let leadStructure
    // Start validation snackbar messages
    if (!ModalType.isSETType(state.modalType) && Object.keys(group.groupLead).length === 0) {
      dispatch({
        type: 'TOGGLE_SNACKBAR', payload: {
          type: 'error',
          showSnackbar: !state.snackbar.showSnackbar,
          info: `Group Lead must be selected!`
        }
      })
      return
    }
    if (ModalType.isCareerDevType(state.modalType) && state.EVPs[3].Offices.find(office => office.Ident === group.groupLead.EmployeeNum && office.id !== group.groupId)) {
      dispatch({
        type: 'TOGGLE_SNACKBAR', payload: {
          type: 'error',
          showSnackbar: !state.snackbar.showSnackbar,
          info: `This Career Dev Manager is already associated with an office!`
        }
      })
      return
    }
    if (!group.groupName && !state.selectedTeamName) {
      dispatch({
        type: 'TOGGLE_SNACKBAR', payload: {
          type: 'error',
          showSnackbar: !state.snackbar.showSnackbar,
          info: `Group Name must be valid!`
        }
      })
      return
    }
    if (!group.groupAgents || group.groupAgents.length === 0) {
      dispatch({
        type: 'TOGGLE_SNACKBAR', payload: {
          type: 'error',
          showSnackbar: !state.snackbar.showSnackbar,
          info: `Group must contain at least one Agent!`
        }
      })
      return
    }
    // End validation snackbar messages
    const now = new Date()
    const addMonth = now.getMonth() + 1
    const teamAgents = group.groupAgents.map(agent => {
      return {
        AgentNum: agent.EmployeeNum,
        TeamAddDate: `${now.getFullYear()}-${addMonth < 10 ? `0${addMonth}` : addMonth}`
      }
    })
    const variables = {
      input: {
        TeamAgents: teamAgents,
        IsCareerDev: ModalType.isCareerDevType(state.modalType),
        IsSET: ModalType.isSETType(state.modalType),
        OfficeID: (ModalType.isSETType(state.modalType) || ModalType.isCareerDevType(state.modalType)) ? null : group.groupLead.Ident,
        TeamName: group.groupName,
        TeamLeadID: ModalType.isSETType(state.modalType) ? null : group.groupLead.EmployeeNum,
        id: group.groupId
      }
    }
    const mutationType = group.groupId ? editTeamMutation : createTeamMutation
    const mutationTypeStr = group.groupId ? 'editTeamMutation' : 'createTeamMutation'
    commitMutation(
      environment,
      {
        mutation: mutationType,
        variables,
        onCompleted: (response, errors) => {
          if (errors || !response) {
            dispatch({
              type: 'TOGGLE_SNACKBAR', payload: {
                type: 'error',
                showSnackbar: !state.snackbar.showSnackbar,
                info: `Failed to create group: ${errors[0].message}`
              }

            })
            return
          }
          dispatch({
            type: 'TOGGLE_SNACKBAR', payload: {
              type: 'success',
              showSnackbar: !state.snackbar.showSnackbar,
              info: 'Group successfully saved'
            }
          })
          // if NOT a sales office and we're creating a new team...
          if ((ModalType.isSETType(state.modalType) || ModalType.isCareerDevType(state.modalType)) && !ModalType.isEditType(state.modalType)) {
            if (ModalType.isCareerDevType(state.modalType)) {
              const newOffice = [
                {
                  AgentGoalTotal: '0',
                  FinalGoalTotal: '0',
                  Ident: group.groupLead?.EmployeeNum,
                  OfficeName: group.groupName,
                  TotalSides: 0,
                  Volume: '0',
                  id: group.groupId,
                  Agents: group.groupAgents
                }
              ]
              const updateOffices = dispatch({
                type: 'UPDATE_OFFICES', payload: {
                  ...state,
                  updateType: 'add',
                  team: newOffice,
                  evpOffice: group.groupName
                }
              })
              if (updateOffices) {
                group.groupAgents.forEach(agent => {
                  const now = new Date()
                  const teamAddDate = `${now.getFullYear()}-${now.getMonth() + 1}`
                  const teamLead = ModalType.isCareerDevType(state.modalType) ? group.groupLead?.EmployeeNum : ModalType.isSETType(state.modalType) ? '' : String(group.groupLead?.AgentName === agent.AgentName)
                  const teamIdx = agent.Teams.findIndex(elem => elem.id === group.groupId);
                  const newState = produce(agent, draft => {
                    draft.Teams[teamIdx] = {
                      TeamName: group.groupName,
                      IsTeamLead: teamLead,
                      TeamAddDate: (draft.Teams[teamIdx] && draft.Teams[teamIdx].TeamAddDate ? draft.Teams[teamIdx].TeamAddDate : teamAddDate),
                      isCareerDev: true,
                      isSET: false,
                      id: group.groupId
                    }
                  })
                  dispatch({
                    type: 'UPDATE_AGENT', payload: {
                      ...newState,
                      Ident: group.groupLead.EmployeeNum
                    }
                  })
                })
              }
            } else if (ModalType.isSETType(state.modalType)) {
              const newOffice = [
                {
                  AgentGoalTotal: '0',
                  FinalGoalTotal: '0',
                  Ident: `SET${group.groupName}`,
                  OfficeName: group.groupName,
                  TotalSides: 0,
                  Volume: '0',
                  id: response.createTeamMutation?.Response,
                  Agents: group.groupAgents
                }
              ]
              dispatch({
                type: 'UPDATE_OFFICES', payload: {
                  ...state,
                  updateType: 'add',
                  team: newOffice,
                  evpOffice: `SET${group.groupName}`
                }
              })
              group.groupAgents.forEach(agent => {
                const now = new Date()
                const teamAddDate = `${now.getFullYear()}-${now.getMonth() + 1}`
                const teamIdx = agent.Teams.findIndex(elem => elem.id === group.groupId);
                const newState = produce(agent, draft => {
                  draft.Teams[teamIdx] = {
                    TeamName: group.groupName,
                    TeamAddDate: (draft.Teams[teamIdx] && draft.Teams[teamIdx].TeamAddDate ? draft.Teams[teamIdx].TeamAddDate : teamAddDate),
                    isCareerDev: false,
                    isSET: true,
                    id: response.createTeamMutation?.Response
                  }
                })
                dispatch({
                  type: 'UPDATE_AGENT', payload: {
                    ...newState,
                    Ident: `SET${group.groupName}`
                  }
                })
              })
            }
          } else {
            if (group.groupId) {
              group.groupAgents
                .forEach(agent => {
                  const now = new Date()
                  const teamAddDate = `${now.getFullYear()}-${now.getMonth() + 1}`
                  const teamLead = ModalType.isCareerDevType(state.modalType) ? group.groupLead.EmployeeNum : ModalType.isSETType(state.modalType) ? '' : String(group.groupLead.AgentName === agent.AgentName)
                  const teamIdx = agent.Teams.findIndex(elem => elem.id === group.groupId);
                  const newState = produce(agent, draft => {
                    draft.Teams[teamIdx === -1 ? 0 : agent.Teams.length] = {
                      TeamName: group.groupName,
                      IsTeamLead: teamLead,
                      TeamAddDate: (draft.Teams[teamIdx] && draft.Teams[teamIdx].TeamAddDate ? draft.Teams[teamIdx].TeamAddDate : teamAddDate),
                      isCareerDev: ModalType.isCareerDevType(state.modalType),
                      isSET: ModalType.isSETType(state.modalType),
                      id: group.groupId
                    }
                  })
                  dispatch({
                    type: 'UPDATE_AGENT', payload: {
                      ...newState,
                      Ident: group.groupId
                    }
                  })
                  setGroup({
                    ...group
                  })
                })
            } else {
              group.groupAgents
                .forEach(agent => {
                  const now = new Date()
                  const teamAddDate = `${now.getFullYear()}-${now.getMonth() + 1}`
                  const teamLead = (leadType === 'cd' || leadType === 'set') ? leadStructure : String(group.groupLead.AgentName === agent.AgentName)
                  dispatch({
                    type: 'UPDATE_AGENT', payload: {
                      ...agent,
                      Teams: [
                        ...agent.Teams.filter(team => team.id !== group.groupId), //add teams not in this group
                        {
                          TeamName: group.groupName,
                          IsTeamLead: teamLead,
                          TeamAddDate: teamAddDate,
                          isCareerDev: ModalType.isCareerDevType(state.modalType),
                          isSET: ModalType.isSETType(state.modalType),
                          id: response[mutationTypeStr].Response
                        }]
                    }
                  })
                })
            }
          }
        },
        onError: err => {
          dispatch({
            type: 'TOGGLE_SNACKBAR', payload: {
              showSnackbar: !state.snackbar.showSnackbar,
              type: 'error',
              info: `Failed to create group: ${err.toString()}`
            }
          })
        }
      }
    )
  }
  const handleGroupDelete = (e) => {
    e.preventDefault(e)
    let officeID
    if (!ModalType.isCareerDevType(state.modalType) && !ModalType.isSETType(state.modalType)) {
      if (group && group.groupLead) {
        officeID = group.groupLead.Ident
      } else {
        officeID = group.groupAgents[0].Ident
      }
    }
    handleConfirmClose()
    const variables = {
      input: {
        TeamName: group.groupName,
        id: group.groupId,
        OfficeID: ModalType.EditGroup === state.modalType ? officeID : '',
        IsCareerDev: ModalType.isCareerDevType(state.modalType),
        IsSET: ModalType.isSETType(state.modalType),
      }
    }
    commitMutation(
      environment,
      {
        mutation: deleteTeamMutation,
        variables,
        onCompleted: (response, errors) => {
          if (!errors && response.deleteTeamMutation.Response) {
            if (ModalType.isCareerDevType(state.modalType) || ModalType.isSETType(state.modalType)) {
              dispatch({
                type: 'UPDATE_OFFICES', payload: {
                  updateType: 'remove',
                  evpOffice: group.groupName
                }
              })
            }
            if (!ModalType.isCareerDevType(state.modalType) && !ModalType.isSETType(state.modalType)) {
              group.groupAgents.forEach(agent => {
                dispatch({
                  type: 'UPDATE_AGENT', payload: {
                    ...agent,
                    Teams: agent.Teams.filter(team => team.TeamName !== group.groupName)
                  }
                })
              })
            }
            dispatch({
              type: 'TOGGLE_SNACKBAR', payload: {
                type: 'success',
                showSnackbar: !state.snackbar.showSnackbar,
                info: 'Group successfully deleted'
              }
            })
            dispatch({ type: 'TOGGLE_MODAL' })
          } else {
            dispatch({
              type: 'TOGGLE_SNACKBAR', payload: {
                type: 'error',
                showSnackbar: !state.snackbar.showSnackbar,
                info: `Failed to delete group: ${errors[0].message}`
              }
            })
          }
        },
        onError: err => {
          dispatch({
            type: 'TOGGLE_SNACKBAR', payload: {
              type: 'error',
              showSnackbar: !state.snackbar.showSnackbar,
              info: `Failed to delete group: ${err.toString()}`
            }
          })
        }
      }
    )
  }
  const handleAgentRemoveFromList = (e, values) => {
    const agent = selectAgent(agent => agent.EmployeeNum === values.id, state)
    const removedList = group.groupAgents.filter(agent => agent.EmployeeNum !== values.id)
    if (removedList.length === 0) {
      dispatch({
        type: 'TOGGLE_SNACKBAR', payload: {
          type: 'error',
          showSnackbar: !state.snackbar.showSnackbar,
          info: `Groups must have at least 1 member. To delete a group please contact an executive.`
        }
      })
      return
    }
    setRemovedAgents(values.id)
    setGroup({
      ...group,
      groupAgents: removedList,
      groupLeadList: (ModalType.isCareerDevType(state.modalType) || ModalType.isSETType(state.modalType)) ? group.groupLeadList : group.groupLeadList.filter(agent => agent.EmployeeNum !== values.id)
    })
    if (ModalType.isCareerDevType(state.modalType) || ModalType.isSETType(state.modalType)) {
      dispatch({
        type: 'UPDATE_AGENT', payload: {
          ...agent,
          Teams: removedList,
          removeAgent: true,
          Ident: ModalType.isCareerDevType(state.modalType) ? group.groupLead.EmployeeNum : ModalType.isSETType(state.modalType) ? `SET${group.groupName}` : group.groupId
        }
      })
    } else {
      dispatch({
        type: 'UPDATE_AGENT', payload: {
          ...agent,
          Teams: removedList
        }
      })
    }
  }
  const columns = [
    { field: 'id', headerName: 'ID', width: 110 },
    {
      field: 'agentName',
      headerName: 'Agent Name',
      width: 250
    },
    {
      field: 'remove',
      headerName: 'Remove',
      width: 180,
      renderCell: (cellValues) => {
        return (
          <Button
            variant='contained'
            onClick={(e) => {
              handleAgentRemoveFromList(e, cellValues);
            }}
          >
            <FontAwesomeIcon className='icons' icon={faTrashAlt} />
          </Button>
        );
      }
    }
  ]
  const renderTitle = state => {
    switch (state.modalType) {
      case ModalType.CreateCareerDevGroup:
        return 'Create New Career Dev Team'
      case ModalType.EditCareerDevGroup:
        return 'Edit Career Dev Team'
      case ModalType.CreateSET:
        return 'Create Special Executive Team'
      case ModalType.EditSET:
        return 'Edit Special Executive Team'
      case ModalType.CreateGroup:
        return 'Group Management'
      case ModalType.EditGroup:
        return 'Edit Group'
      default:
        return ''
    }
  }
  const groupLeadInput = group => {
    if (ModalType.isSETType(state.modalType)) {
      return (
        group && <TextField
          variant="outlined"
          label="SET - No lead required"
          onChange={handleGroupLeadChange}
          value=''
          error={errors.groupLead !== ""}
          helperText={errors.groupLead === "" ? "" : errors.groupLead}
          disabled
        />
      )
    } else {
      return (
        group && <TextField
          variant="outlined"
          label="Select a group lead"
          onChange={handleGroupLeadChange}
          value={group.groupLead ? group.groupLead.EmployeeNum : ''}
          error={errors.groupLead !== ""}
          defaultValue=""
          helperText={errors.groupLead === "" ? "" : errors.groupLead}
          required
          select
          disabled={state.role === Role.CareerDevManager ? true : false}
        >
          <MenuItem value="None">None</MenuItem>
          {group.groupLeadList?.map((agent, index) => {
            return (
              <MenuItem value={agent.EmployeeNum} key={index}>
                <em>{agent.AgentName}</em>
              </MenuItem>
            )
          })}
        </TextField>
      )
    }
  }
  const getGroupAgents = group => {
    if (ModalType.isEditType(state.modalType)) {
      return group.groupAgents.filter(agent => !removedAgents.includes(agent.EmployeeNum))
      .concat(addedAgents)
      .map(agent => { return { agentName: agent.AgentName, id: agent.EmployeeNum }  })
    } else {
      return group.groupAgents.map(agent => { return { agentName: agent.AgentName, id: agent.EmployeeNum }  })
    }
  }
  return (
    group &&
    <Modal
      open={state.showModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-title"
    >
      <Box className={classes.modal}>
        <div>
          <h3 id="modal-title" className='text-center pt-2'>{renderTitle(state)}</h3>
          <p className={classes.subtitle}>You must save your changes in order for them to take effect.</p>
        </div>
        <div id='editAgentBodyContainer' className='d-flex w-100 p-2'>
          <div id='currentAgentsContainer' className='w-50 pr-1'>
            <h5 className='text-center'>Manage Group</h5>
            <form onSubmit={handleGroupSave} autoComplete='off'>
              <div className='d-flex w-100'>
                <FormControl className='w-50 py-2'>
                  <TextField
                    variant="outlined"
                    onChange={handleGroupNameChange}
                    onBlur={handleGroupNameChange}
                    label='Group name'
                    defaultValue={group.groupName ? group.groupName : state.selectedTeamName}
                    required
                    helperText={errors.groupName}
                    inputProps={{
                      'data-testid': 'group-name'
                    }}
                    disabled={state.role === Role.CareerDevManager ? true : false}
                    {...(errors["groupName"] && { error: true })}
                  />
                </FormControl>
                <FormControl variant='outlined' className='w-50 py-2 pl-2' error={errors.groupLead !== ""}>
                  {groupLeadInput(group)}
                </FormControl>
              </div>
              <DataGridPro
                rows={getGroupAgents(group)}
                columns={columns}
                autoHeight={true}
                hideFooter
              />
            </form>
          </div>
          <div id='searchAgentsContainer' className='w-50 pl-1'>
            <h5 className='text-center'>Add Agents</h5>
            <AgentSearchBox handleSearch={handleSearch} />
            <AgentSearchResults selectAgent={handleAgentSelect} agentList={group.agentSearchList} />
          </div>
        </div>
        <div className={classes.footer}>
          <Button type='submit' variant='contained' color="primary" onClick={handleGroupSave}>
            Save Group
          </Button>
          <Button type='submit' variant='contained' color="default"
            onClick={handleCloseModal}>
            Close
          </Button>
          {ModalType.isEditType(state.modalType) && state.role !== Role.CareerDevManager ?
            <>
            <Button
              variant='contained'
              color='default'
              title='Delete Group'
              onClick={handleConfirmOpen}
            >
              Delete Group
            </Button>
            <Dialog
              open={confirmOpen}
              onClose={handleConfirmClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Delete this group?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Please ensure that you are deleting the correct group. Group deletion cannot be undone, and the group will need to be recreated manually if required.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleGroupDelete}>Delete</Button>
                <Button onClick={handleConfirmClose} autoFocus>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
            </>
          : null}
        </div>
      </Box>
    </Modal>
  )
}

const createTeamMutation = graphql`
  mutation GroupManagementModalCreateMutation(
    $input: CreateTeamInput!
  ) {
    createTeamMutation(input: $input) {
      Response
    }
  }
`

const editTeamMutation = graphql`
  mutation GroupManagementModalEditMutation(
    $input: EditTeamInput!
  ) {
    editTeamMutation(input: $input) {
      Response
    }
  }
`

const deleteTeamMutation = graphql`
  mutation GroupManagementModalDeleteMutation(
    $input: DeleteTeamInput!
  ) {
    deleteTeamMutation(input: $input) {
      Response
    }
  }
`

const agentSearchQuery = (state, query, officeId = null) => {
  if (!state.selectedOfficeIdent.includes('CM') && !state.selectedOfficeIdent.includes('SET')) {
    const office = selectOffice(state.selectedOfficeIdent, state)
    const agentsForSearch = office.Agents.filter(agent => agent.AgentName).filter(agent => agent.AgentName.toLowerCase().includes(query.toLowerCase())).map(agent => agent.EmployeeId)
    if (agentsForSearch.length === 0) return null
    return `
    query GroupManagementModalAgentSearchQuery {
      QueryAgent(query: "${query}", agentIDs: [${agentsForSearch}]) {
        EmployeeNum
        EmployeeName
        EmployeeId
        AffilDate
        IsTerminated
      }
    }
    `
  } else {
    return `
    query GroupManagementModalAgentSearchQuery {
      QueryAgent(query: "${query}") {
        EmployeeNum
        EmployeeName
        EmployeeId
        Ident
        AffilDate
        IsTerminated
      }
    }
  `
  }
}