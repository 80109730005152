import { Link } from 'react-router-dom';
import React, { useState } from 'react'
import {
    Divider,
    Drawer,
    IconButton,
    Toolbar,
    Typography,
    List,
    ListItem
  } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import {
    ChevronLeft,
    ChevronRight
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu'
import MuiAppBar from '@mui/material/AppBar';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ModalType, Role } from '../../constants';

export default function Header({ state, dispatch }) {

    const drawerWidth = 240;
    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }));
    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
        }),
        backgroundColor: '#522446 !important',
        color: '#fff !important',
        ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        })
        })
    }));

    let title
    if (state.role === Role.Manager || state.role === Role.CareerDevManager)
        title = `Agents for ${state.name}`
    if (state.role === Role.EVP || state.role === Role.CareerDevEVP)
        title = `Offices for ${state.name}`
    if (state.role === Role.Executive || state.role === Role.SuperAdmin)
        title = `Executive Rollup for ${state.name}`

    const useStyles = makeStyles({
        header: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#522446 !important',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '20px',
            marginBottom: '65px'
        },
        headerTitle: {
            color: "fff !important"
        },
        buttonGroup: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        pickers: {
            display: "flex",
            padding: '10px 20px',
            justifyContent: 'flex-end'
        },
        pickerItemL: {
            width: '50%%',
            backgroundColor: '#FFF',
            paddingLeft: '5px',
            paddingRight: '5px',
            borderTopLeftRadius: '5px',
            borderBottomLeftRadius: '5px'
        },
        pickerItemR: {
            width: '50%%',
            backgroundColor: '#FFF',
            paddingLeft: '5px',
            paddingRight: '5px',
            borderTopRightRadius: '5px',
            borderBottomRightRadius: '5px'
        },
        root: {
            flexGrow: 1,
        },
        headerContainer: {
            padding: 50,
            justifyContent: 'start',
            width: '100%',
            display: 'flex',
            backgroundColor: '#522446 !important',
            color: 'white'
        },
        headerText: {
            marginLeft: '50px !important',
            paddingTop: '10px !important',
            paddingBottom: '10px !important',
            marginTop: '10px !important',
            marginBottom: '10px !important',
            color: 'fff !important'
        },
        link: {
            textDecoration: 'none',
            color: 'inherit',
            '&:hover': {
                textDecoration: 'none',
                color: 'inherit'
            }
        }
    })
    const classes = useStyles()
    const theme = useTheme();
    const [sideOpen, setOpen] = useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <>
            <header className={classes.header}>
                <AppBar className='w-100 d-flex justify-content-between flex-row' position="fixed" open={sideOpen}>
                    <Toolbar>
                        <IconButton
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ color: '#fff !important', mr: 2, ...(sideOpen && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography className={classes.headerText} variant="h5" noWrap component="div" sx={{ color: '#fff' }}>
                            {title}
                        </Typography>
                    </Toolbar>
                    <div className={classes.pickers}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <span className={classes.pickerItemL}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    disabled={state.isLoading}
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    label="Starting date"
                                    value={state.startDate}
                                    onChange={() => undefined}
                                    onAccept={date => dispatch({ type: 'CHANGE_DATE', payload: { startDate: date.toISOString() } })}
                                    KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                    autoOk={true}
                                />
                            </span>
                            <span className={classes.pickerItemR}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    disabled={state.isLoading}
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    label="Ending date"
                                    value={state.endDate}
                                    onChange={() => undefined}
                                    onAccept={date => dispatch({ type: 'CHANGE_DATE', payload: { endDate: date.toISOString() } })}
                                    KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                    autoOk={true}
                                />
                            </span>
                        </MuiPickersUtilsProvider>
                    </div>
                </AppBar>
                <Drawer
                    sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                    }}
                    variant='persistent'
                    anchor='left'
                    open={sideOpen}
                >
                    <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                    </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>
                    {(state.role === Role.Executive || state.role === Role.SuperAdmin || state.role === Role.CareerDevEVP) ? 
                        <ListItem button key='newCD' onClick={() => dispatch({ type: 'TOGGLE_MODAL', payload: { modalType: ModalType.CreateCareerDevGroup, selectedOfficeIdent: 'CM' } })}>
                            New CD Team
                        </ListItem>
                    : null}
                    {(state.role === Role.Executive || state.role === Role.SuperAdmin) ? 
                        <ListItem button key='newSET' onClick={() => dispatch({ type: 'TOGGLE_MODAL', payload: { modalType: ModalType.CreateSET, selectedOfficeIdent: 'SET' } })}>
                            New SET
                        </ListItem>
                    : null}
                    {(state.role === Role.Executive || state.role === Role.SuperAdmin || state.role === Role.CareerDevEVP) ? 
                        <Divider /> : null}
                    <ListItem button key='home'>
                        <Link to='/home' id='navButtonH' className={classes.link}>Home</Link>
                    </ListItem>
                    <ListItem button key='reports'>
                        <Link to='/reports' id='navButtonR' className={classes.link}>Reports</Link>
                    </ListItem>
                    <ListItem button key='dashboards'>
                        <Link to='/dashboards' id='navButtonG' className={classes.link}>Dashboards</Link>
                    </ListItem>
                    <ListItem button key='logout' onClick={() => {window.sessionStorage.clear(); window.location.replace('/')}}>Log Out</ListItem>
                    </List>
                </Drawer>
            </header>
        </>
    )
}