import React from 'react'
import environment from '../../relay/Environment'
import { QueryRenderer } from 'react-relay'
import PropTypes from 'prop-types'
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Button } from '@material-ui/core'
import { Form, Toast } from 'react-bootstrap'
import graphql from 'babel-plugin-relay/macro'
import LoadingSpinner from '../LoadingSpinner'
import './style.css'

export default class AgentDetailModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modalIsOpen: false,
      goalType: 'Agent',
      showToast: false,
      toastMessage: '',
      toastClass: 'hidden',
      toastDelay: 3000,
      toastAutodismiss: true
    }

    this.handleCloseToast = this.handleCloseToast.bind(this)
    this.openToast = this.openToast.bind(this)
  }

  handleCloseToast = () => {
    this.setState({ showToast: false, toastMessage: '', toastClass: '', toastDelay: 3000, toastAutodismiss: true })
  }

  openToast = (toastMessage, toastClass) => {
    const specialToastConditions = { toastAutodismiss: true }
    if (toastClass === 'error') {
      specialToastConditions.toastAutodismiss = false
    }
    this.setState({ showToast: true, toastMessage: toastMessage, toastClass: toastClass, ...specialToastConditions })
  }

  render () {
    return (
      <div id='AgentDetailModalContainer' className='w-100 h-100'>
        {this.props.modalType === 'detail' &&
          <QueryRenderer
            environment={environment}
            query={graphql`
            query AgentDetailModalDetailQuery($agentID: String!) {
              agentDetails (agentID: $agentID) {
                edges {
                  node {
                    id
                    Ident
                    Address
                    CloseDate
                    SellPrice
                    VolumeCredit
                    Side,
                    SaleStatus
                  }
                }
              }
            }
          `}
            variables={{ agentID: this.props.searchID }}
            render={({ error, props }) => {
              if (props && props.agentDetails) {
                const transactionRows = []
                props.agentDetails.edges.map(detail => {
                  return transactionRows.push(
                    <Form id={detail.node.id} className='detailBlock w-100 d-flex' key={`trade${detail.node.id}`}>
                      <Form.Row className='detailRow w-100'>
                        <div className='w-50 p-1'>
                          <div className='d-flex w-100'>
                            <Form.Label className='mb-0 align-self-center col-sm-4'>
                              Trade ID:
                            </Form.Label>
                            <Form.Control className='col-sm-8' plaintext readOnly defaultValue={detail.node.Ident} />
                          </div>
                          <div className='d-flex w-100'>
                            <Form.Label className='mb-0 align-self-center col-sm-3'>
                              Address:
                            </Form.Label>
                            <Form.Control className='col-sm-9' plaintext readOnly defaultValue={detail.node.Address} />
                          </div>
                          <div className='d-flex w-100'>
                            <Form.Label className='mb-0 align-self-center col-sm-4'>
                              Close Date:
                            </Form.Label>
                            <Form.Control className='col-sm-8' plaintext readOnly defaultValue={detail.node.CloseDate} />
                          </div>
                        </div>
                        <div className='w-50 p-1'>
                          <div className='d-flex w-100'>
                            <Form.Label className='mb-0 align-self-center col-sm-4'>
                              Sell Price:
                            </Form.Label>
                            <Form.Control className='col-sm-8' plaintext readOnly defaultValue={detail.node.SellPrice} />
                          </div>
                          <div className='d-flex w-100'>
                            <Form.Label className='mb-0 align-self-center col-sm-4'>
                              Volume Credit:
                            </Form.Label>
                            <Form.Control className='col-sm-8' plaintext readOnly defaultValue={detail.node.VolumeCredit} />
                          </div>
                          <div className='d-flex w-100'>
                            <Form.Label className='mb-0 align-self-center col-sm-4'>
                              Agent Side:
                            </Form.Label>
                            <Form.Control className='col-sm-8' plaintext readOnly defaultValue={detail.node.Side} />
                          </div>
                        </div>
                      </Form.Row>
                    </Form>
                  )
                })
                return (
                  <>
                    <div className='modalHeaderContainer'>
                      <h3 className='headerTextLarge'>
                        {props.agentDetails ? `Transactions for ${this.props.agentName}` : 'Transaction List'}
                      </h3>
                    </div>
                    <p>Number of transactions that fell through in the last twelve months:&nbsp;
                        {
                          props.agentDetails.edges
                            .map(detail => detail.node.SaleStatus.trim())
                            .filter(status =>  (status !== "A" && status !== "" && status !== " "))
                            .length
                        }
                      </p>
                    <div id='transactionListContainer' className='w-100'>
                      {transactionRows}
                    </div>
                  </>
                )
              } else if (error) {
                return <div>There was an error: {error}</div>
              } else if (props && props.agentDetails === null) {
                return <div><h4 className='text-center'>No trades were found for this agent.</h4></div>
              }
              return (
                <LoadingSpinner />
              )
            }}
          />
        }
        {this.props.modalType === 'hidden' &&
          <QueryRenderer
            environment={environment}
            query={graphql`
            query AgentDetailModalHiddenQuery($officeID: String!) {
              hiddenAgents (officeID: $officeID) {
                edges {
                  node {
                    EmployeeName
                    Ident
                    AffilDate
                  }
                }
              }
            }
          `}
            variables={{ officeID: this.props.searchID }}
            render={({ error, props }) => {
              if (props && props.hiddenAgents && props.hiddenAgents.edges && props.hiddenAgents.edges.length > 0) {
                const unHideButton = (params) => {
                  return (
                    <Button variant='outlined' onClick={() => this.props.unHideAgent(params.row.id)}>Un-Hide</Button>
                  )
                }
                const columns = [
                  { field: 'id', headerName: 'ID', flex: 1 },
                  { field: 'agentName', headerName: 'Agent Name', flex: 1 },
                  { field: 'affilDate', headerName: 'Affiliation Date', flex: 1 },
                  { field: 'unHide', headerName: 'Un-Hide?', flex: 1, renderCell: unHideButton, disableClickEventBubbling: true }
                ];
                const agentRows = props.hiddenAgents.edges.map(detail => {
                    return { 
                      id: detail.node.Ident,
                      agentName: detail.node.EmployeeName,
                      affilDate: detail.node.AffilDate
                    }
                })
                return (
                  <>
                    <div className='modalHeaderContainer'>
                      <h3 className='headerTextLarge'>
                        Hidden Agents
                      </h3>
                      <Toast key='toast' onClose={this.state.handleCloseToast} show={this.props.showToast} delay={3000} autohide={this.state.toastAutodismiss} className={`toast ${this.props.toastClass}`}>
                        <Toast.Header key='toast-header'>
                          {this.props.toastMessage}
                        </Toast.Header>
                      </Toast>
                    </div>
                    <div id='agentListContainer' className='w-100'>
                      <DataGridPro
                        rows={agentRows}
                        columns={columns}
                        autoHeight={true}
                        disableSelectionOnClick={true}
                        pageSize={10}
                      />
                    </div>
                  </>
                )
              } else if (props && !props.hiddenAgents) {
                return <h3 className='p-4 w-100 text-center'>No Hidden Agents were found!</h3>
              } else if (error) {
                return <div>There was an error: {error}</div>
              }
              return (
                <LoadingSpinner />
              )
            }}
          />
        }
      </div>
    )
  }
}

AgentDetailModal.propTypes = {
  agentName: PropTypes.string,
  modalType: PropTypes.string,
  searchID: PropTypes.string,
  unHideAgent: PropTypes.func
}
