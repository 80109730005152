import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from "luxon"

const getHeaders = (indexes, startDate, endDate, prevStart, prevEnd) => {
  return indexes.map(index => (
    <React.Fragment key={`${index}-Column-Headers`}>
      <th scope='col' className='text-center'>
        {startDate} Thru {endDate} <br />
        Goal
      </th>
      <th scope='col' className='text-center'>
        {startDate} Thru {endDate} <br />
        Actual
      </th>
      <th scope='col' className='text-center'>
        {prevStart} Thru {prevEnd} <br />
        Actual
      </th>
      <th scope='col' className='text-center'>
        YoY %{' '}
      </th>
    </React.Fragment>
  ))
}

const getTitles = (indexes) => {
  return indexes.map(index => (
    <th colSpan="4" className="text-center" key={`${index}-Header`}>
      {index}
    </th>
  ))
}

const formatter = (key, value, round = false) => {
  if (key.endsWith('Volume')) {
    return '$' + (value / 1000000).toFixed(2) + 'M';
  } else {
    if (value) {
      return value.toFixed(1);
    } else {
      return 0;
    }
  }
}

function YOYReport(props) {
  const useStyles = makeStyles({
    mainTable: {
      borderSpacing: 0,
      border: '1px solid #D3DAE6',
      borderCollapse: 'separate',
      fontFamily: 'Arial, Helvetica, sans-serif'
    },
    table: {
      border: '1px solid black',
      borderCollapse: 'separate',
      borderSpacing: 0,
      stickyTable: {
        borderCollapse: 'separate',
        borderSpacing: 0,
        border: '1px solid black'
      },
      td: {
        border: '1px solid black',
        borderCollapse: 'separate',
        borderSpacing: 0,
      },
      stickyCol: {
        backgroundColor: '#fff',
        position: 'sticky',
        left: 0,
        border: '1px solid black'
      }
    },
    tableTopHeaders: {
      border: '2px solid #D3DAE6'
    },
    standardCellRow: {
      '& td': {
        border: '1px solid #D3DAE6',
        padding: '3px'
      },
      '& th': {
        border: '1px solid #D3DAE6',
        padding: '3px'
      }
    },
    boldCellRow: {
      fontWeight: 'bold',
      '& td': {
        border: '1px solid #D3DAE6',
        padding: '6px'
      },
      '& th': {
        border: '1px solid #D3DAE6',
        padding: '5px'
      }
    }
  })
  const classes = useStyles()
  const indexes = ['Booked Units', 'Closed Units', 'Booked Volume', 'Closed Volume']
  const currEndDate = DateTime.fromISO(props.state.lastDay).toFormat('D')
  const currStartDate = DateTime.fromISO(props.state.firstDay).toFormat('D')
  const prevEndDate = DateTime.fromISO(props.state.lastDay).minus({ years: 1 }).toFormat('D')
  const prevStartDate = DateTime.fromISO(props.state.firstDay).minus({ years: 1 }).toFormat('D')

  return (
    <div style={{ backgroundColor: '#fff', overflow: 'scroll' }}>
      {props.data && props.data.EVPs &&
        <>
          <table className='table table-bordered sticky-table'>
            <thead>
              <tr className={classes.boldCellRow}>
                <th className='sticky-col' />
                {getTitles(indexes)}
              </tr>
              <tr className={classes.boldCellRow}>
                <th scope='col' className='sticky-col'>
                  EVP/Office
                </th>
                {getHeaders(indexes, currStartDate, currEndDate, prevStartDate, prevEndDate)}
              </tr>
            </thead>
            <tbody>
              {/* section */}
              {props.data.EVPs.map(section => {
                return section.Offices.map((office, idx) => {
                  const lastLineCheck = idx + 1 === section.Offices.length;
                  if (lastLineCheck) {
                    return (
                      <>
                        <tr className={classes.standardCellRow} key={office.Name}>
                          <td className={classes.table}>
                            {office.Name.toUpperCase()}
                          </td>
                          {office.Reports.map(report => (
                              <React.Fragment key={`${report.Name}-${office.Name}`}>
                                <td>{formatter(report.Name, parseFloat(report.YTDGoal), true)}</td>
                                <td>{formatter(report.Name, parseFloat(report.YTDActual))}</td>
                                <td>{formatter(report.Name, parseFloat(report.YTDActualPrev))}</td>
                                <td className={report.YOYPerc <= 0 ? 'table-danger' : 'table-success'}>
                                  {report.YOYPerc !== null ? `${parseFloat(report.YOYPerc).toFixed(2)}%` : 'N/A'}
                                </td>
                              </React.Fragment>
                            ))}
                        </tr>
                        <tr className={lastLineCheck ? classes.boldCellRow : classes.standardCellRow} key={office.Name}>
                          <td className={classes.table}>
                            {section.Name}
                          </td>
                          {section.GrandTotals.map(report => (
                              <React.Fragment key={`${report.Name}-${office.Name}`}>
                                <td>{formatter(report.Name, parseFloat(report.YTDGoal), true)}</td>
                                <td>{formatter(report.Name, parseFloat(report.YTDActual))}</td>
                                <td>{formatter(report.Name, parseFloat(report.YTDActualPrev))}</td>
                                <td className={report.YOYPerc <= 0 ? 'table-danger' : 'table-success'}>
                                  {report.YOYPerc !== null ? `${parseFloat(report.YOYPerc).toFixed(2)}%` : 'N/A'}
                                </td>
                              </React.Fragment>
                            ))}
                        </tr>
                      </>
                    )
                  }
                  return (
                    <tr className={lastLineCheck ? classes.boldCellRow : classes.standardCellRow} key={office.Name}>
                      <td className={classes.table}>
                        {office.Name.toUpperCase()}
                      </td>
                      {office.Reports.map(report => (
                          <React.Fragment key={`${report.Name}-${office.Name}`}>
                            <td>{formatter(report.Name, parseFloat(report.YTDGoal), true)}</td>
                            <td>{formatter(report.Name, parseFloat(report.YTDActual))}</td>
                            <td>{formatter(report.Name, parseFloat(report.YTDActualPrev))}</td>
                            <td className={report.YOYPerc <= 0 ? 'table-danger' : 'table-success'}>
                              {report.YOYPerc !== null ? `${parseFloat(report.YOYPerc).toFixed(2)}%` : 'N/A'}
                            </td>
                          </React.Fragment>
                        ))}
                    </tr>
                  );
                })
              })}
              <tr className={classes.boldCellRow} key='YoYTotals'>
                <td className={classes.table}>Grand Totals</td>
                {props.data.GrandTotals.map(section => {
                  return (
                    <>
                      <td className='font-weight-bold'>{formatter(section.Name, parseFloat(section.YTDGoal), true)}</td>
                      <td className='font-weight-bold'>{formatter(section.Name, parseFloat(section.YTDActual))}</td>
                      <td className='font-weight-bold'>{formatter(section.Name, parseFloat(section.YTDActualPrev))}</td>
                      <td className={section.YOYPerc <= 0 ? 'font-weight-bold table-danger' : 'font-weight-bold table-success'}>
                        {section.YOYPerc !== null ? `${parseFloat(section.YOYPerc).toFixed(2)}%` : 'N/A'}
                      </td>
                    </>
                  )
                })}
              </tr>
            </tbody>
          </table>
        </>
      }
    </div>
  );
}

export default YOYReport;
