import config from '../utils/config'
import { validateAuth } from './auth';

async function fetchGraphQL(query, variables) {
  const checkUser = await validateAuth()
  if (checkUser) {
    const response = await fetch(config.GRAPHQL_URL, {
      method: 'POST',
      headers: {
        Authorization: `${window.sessionStorage.getItem('userToken')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: query,
        variables,
      }),
    });
  
    // Get the response as JSON
    return await response.json();
  }
}

export default fetchGraphQL;