import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import { Card, CardContent, CardMedia, Container, Grid, Typography } from '@material-ui/core'
import { useLocation } from "react-router-dom";
import dash from '../../assets/dashboard.png'
import goal from '../../assets/goal.png'
import reports from '../../assets/reports.png'
import StagingIndicator from '../../components/StagingIndicator';
import config from '../../utils/config';

export default function SplashScreen ({execName, role, office, saveLocation}) {
    const [card, toggleCard] = useState({goals: { raised: false }, dashboard: { raised: false }, reports: { raised: false }})
    const location = useLocation();
    useEffect(() => {
        saveLocation('/home');
      }, [location, saveLocation]);

    const useStyles = makeStyles({
         root: {
            flexGrow: 1,
        },
        header: {
            padding: 50,
            justifyContent: 'center',
            width: '100%',
            display: 'flex',
            backgroundColor: '#522446',
            color: 'white'
        },
        container: {
            marginTop: 50,
        },
        gridItem: {
            flexGrow: 1
        },
        image: {
            height: '200px'
        },
        link: {
            textDecoration: 'none',
            color: 'inherit'
        }
    })
    const handleCard = cardType => toggleCard({...card, [cardType]: { raised: !card[cardType].raised }})
    const classes = useStyles()
    return (
        <div className={classes.root}>
        {config.IS_STAGING === 'true' ? <StagingIndicator /> : null}
        <header className={classes.header}>
            <Typography variant="h4" component="h2">
                    Welcome to the BHHS Goals Portal Interface
            </Typography>
        </header>
        <Container className={classes.container} maxWidth="md">
            <Typography variant="h5" component="h2" className="text-center">
                Please select a workspace:
            </Typography>
            <Grid container spacing={3}>
                <Grid item md className={classes.gridItem}>
                    <Card raised={card.goals.raised}
                    onMouseOver={() => handleCard('goals')}
                    onMouseLeave={() => handleCard('goals')}
                    className={classes.card}>
                        <Link className={classes.link} to='/goals'>
                            <CardMedia className={classes.image} image={goal} alt='Goals Teaser' />
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="h3">
                                    Goals Interface
                                </Typography>
                            </CardContent>
                        </Link>
                    </Card>
                </Grid>
                <Grid item md className={classes.gridItem}>
                    <Card raised={card.dashboard.raised}
                    onMouseOver={() => handleCard('dashboard')}
                    onMouseLeave={() => handleCard('dashboard')}
                    className={classes.card}
                    >
                        <Link className={classes.link} to='/dashboards'>
                        <CardMedia className={classes.image} image={dash} alt='Dashboard Teaser' />
                        <CardContent>
                                <Typography gutterBottom variant="h6" component="h3">
                                    Dashboards
                                </Typography>
                            </CardContent>
                        </Link>
                    </Card>
                </Grid>
                <Grid item md className={classes.gridItem}>
                    <Card raised={card.reports.raised}
                    onMouseOver={() => handleCard('reports')}
                    onMouseLeave={() => handleCard('reports')}
                    className={classes.card}
                    >
                        <Link className={classes.link} to='/reports'>
                        <CardMedia className={classes.image} image={reports} alt='Reports Teaser' />
                        <CardContent>
                                <Typography gutterBottom variant="h6" component="h3">
                                    Reports
                                </Typography>
                            </CardContent>
                        </Link>
                    </Card>
                </Grid>
            </Grid>
        </Container>
        </div>
    )
}
