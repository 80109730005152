import React, { useState } from 'react'
import { DataGridPro, gridVisibleSortedRowIdsSelector } from "@mui/x-data-grid-pro"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FormControl, FormGroup, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import { ModalType, Role, TableViewType } from '../../constants'
import useColumns from '../../hooks/useColumns'
import CustomFooter from '../CustomFooter/CustomFooter'
import CustomToolbar from '../CustomToolbar/CustomToolbar'
import config from '../../utils/config'

library.add(faExclamationCircle)

export function OfficeTable(props) {
	let lastOffice = 'All Offices'
	let metaType
	if (props.displayType === 'Executive') {
		const evp = props.renderedEVP.Offices
		switch (props.renderedEVP.EVPName) {
			case config.EVP_SOUTH:
				const southOffice = evp.find(off => off.Ident === props.userSelections?.LastSouthOffice)
				lastOffice = southOffice ? southOffice.OfficeName : 'All Offices'
				metaType = 'LastSouthOffice'
				break
			case config.EVP_CENTRAL:
				const centOffice = evp.find(off => off.Ident === props.userSelections?.LastCentralOffice)
				lastOffice = centOffice ? centOffice.OfficeName : 'All Offices'
				metaType = 'LastCentralOffice'
				break
			case config.EVP_NORTH:
				const northOffice = evp.find(off => off.Ident === props.userSelections?.LastNorthOffice)
				lastOffice = northOffice ? northOffice.OfficeName : 'All Offices'
				metaType = 'LastNorthOffice'
				break
			case config.EVP_CD:
				const cdOffice = evp.find(off => off.Ident === props.userSelections?.LastCDOffice)
				lastOffice = cdOffice ? cdOffice.OfficeName : 'All Offices'
				metaType = 'LastCDOffice'
				break
			case config.EVP_SET:
				const setOffice = evp.find(off => off.Ident === props.userSelections?.LastNorthOffice)
				lastOffice = setOffice ? setOffice.OfficeName : 'All Offices'
				metaType = 'LastSETOffice'
				break
			default:
				lastOffice = 'All Offices'
		}
	} else if (props.displayType === 'EVP' || props.displayType === 'Manager') {
		const selectedOffice = props.data.find(off => off.Ident === props.userSelections?.LastOffice)
		lastOffice = selectedOffice ? selectedOffice.OfficeName : 'All Offices'
		metaType = 'LastOffice'
	}
	const aFilters = props.userSelections?.GridSettings?.Agent?.Filters?.map(filter => {
		return JSON.parse(filter)
	})
	const oFilters = props.userSelections?.GridSettings?.Office?.Filters?.map(filter => {
		return JSON.parse(filter)
	})
	let finalFilters = []
	if (aFilters && aFilters[0] && aFilters[0].items && oFilters && oFilters[0] && oFilters[0].items) {
		const hasHidden = aFilters[0].items.find(hit => hit.columnField === 'Hide')
		if (!hasHidden) {
			const addedHidden = aFilters[0].items.concat({ columnField: 'Hide', operatorValue: 'is', value: 'false', id: 101010 })
			finalFilters = addedHidden.concat(oFilters[0].items)
		} else {
			finalFilters = aFilters[0].items.concat(oFilters[0].items)
		}
	} else if (aFilters && aFilters[0] && aFilters[0].items) {
		const hasHidden = aFilters[0].items.find(hit => hit.columnField === 'Hide')
		if (!hasHidden) {
			const addedHidden = aFilters[0].items.concat({ columnField: 'Hide', operatorValue: 'is', value: 'false', id: 101010 })
			finalFilters = addedHidden
		} else {
			finalFilters = aFilters[0].items
		}
	} else if (oFilters && oFilters[0] && oFilters[0].items) {
		finalFilters = oFilters[0].items
	}
	const aSort = props.userSelections?.GridSettings?.Agent?.Sort?.map(sort => {
		return JSON.parse(sort)
	})
	const oSort = props.userSelections?.GridSettings?.Office?.Sort?.map(sort => {
		return JSON.parse(sort)
	})
	const finalSort = aSort && oSort ? aSort.concat(oSort) : []
	let dataAutoHeight = true
	const [officeName, setOffice] = useState(lastOffice)
	if ((officeName === 'All Offices' && props.data && props.data.length > 7) ||
			(officeName !== 'All Offices' && 
			props.data.filter(office => office.OfficeName === officeName).map(office => office.Agents.length > 7))) {
		dataAutoHeight = false
	}
	const [columns, setColumns] = useColumns((lastOffice !== 'All Offices' ? TableViewType.Office : TableViewType.Offices), props.state.role, props.state.CareerDevLeads, props.state)
	const [filterModel, setFilterModel] = useState({items: finalFilters})
	const [sortModel, setSortModel] = useState(finalSort.flat())
	const [pageSize, setPageSize] = useState(50)
	// styles follow
	const useStyles = makeStyles({
		root: {
			width: '100%',
			height: dataAutoHeight ? 'auto' : '600px'
		}
	})
	const classes = useStyles()

	if (props.data === null) {
		return (<p>No data is available.</p>)
	}

	const handleOfficePreferences = (officeId) => {
		const prefPayload = { [metaType]: officeId }
		props.updateOfficePref(prefPayload)
	}

	const handleGridPreferences = (settings, viewType, toolbarType) => {
		if (toolbarType === 'column') {
			if (viewType === 'office') {
				const currentColumns = {
					OfficeName: settings.OfficeName,
					OTotalSides: settings.OTotalSides,
					OVolume: settings.OVolume,
					OAvgSalePrice: settings.OAvgSalePrice,
					AgentCount: settings.AgentCount,
					AgentGoalTotal: settings.AgentGoalTotal,
					FinalGoalTotal: settings.FinalGoalTotal
				}
				const prefPayload = { gridType: viewType, gridSettings: currentColumns, updateType: 'column' }
				props.updateGridPref(prefPayload)
			} else if (viewType === 'agent') {
				const currentColumns = {
					Hide: settings.Hide,
					EmployeeNum: settings.EmployeeNum,
					AgentName: settings.AgentName,
					TeamName: settings.TeamName,
					TeamLead: settings.TeamLead,
					TeamAddDate: settings.TeamAddDate,
					IsTerminated: settings.IsTerminated,
					AffilDate: settings.AffilDate,
					Listings: settings.Listings,
					ATotalSides: settings.ATotalSides,
					AVolume: settings.AVolume,
					AAvgSalePrice: settings.AAvgSalePrice,
					AgentCount: settings.AgentCount,
					AgentGoal: settings.AgentGoal,
					FinalGoal: settings.FinalGoal,
					Notes: settings.Notes
				}
				const prefPayload = { gridType: viewType, gridSettings: currentColumns, updateType: 'column' }
				props.updateGridPref(prefPayload)
			}
		} else if (toolbarType === 'filter') {
			if (filterModel !== settings) {
				const prefPayload = { gridType: viewType, gridSettings: { Filters: JSON.stringify(settings) }, updateType: 'filter' }
				setFilterModel(settings)
				props.updateGridPref(prefPayload)
			}
		} else if (toolbarType === 'sort') {
			if (sortModel !== settings) {
				const prefPayload = { gridType: viewType, gridSettings: { Sort: JSON.stringify(settings) }, updateType: 'sort' }
				setSortModel(settings)
				props.updateGridPref(prefPayload)
			}
		}
	}

	/**
 * This functions takes in the whole agent and applies it to the reducer on Goals page.
 * Only a few props are needed for the agent goal save mutation which are applied here
 * based on the change on the row.
 * @todo: implement error handling in future story
 */
	const handleEdits = ({ id, field, value }) => {
		const office = props.data.find(doc => doc.OfficeName === officeName)
		const agent = office.Agents[id]
		let agentPayload = { ...agent }
		agentPayload.OfficeName = officeName
		let errored = false
		switch (field) {
			case 'Notes':
				errored = false
				agentPayload = { ...agentPayload, Notes: value }
				if (agentPayload.Notes === "") agentPayload.Notes = " "
				break
			case 'AgentGoal':
				if (parseInt(value).toString().length <= 3 && !value.toString().includes('-')) {
					errored = false
					agentPayload = { ...agentPayload, AgentGoal: String(value) }
				} else {
					errored = true
					props.dispatch({
						type: 'TOGGLE_SNACKBAR', payload: {
							type: 'error',
							showSnackbar: !props.state.snackbar.showSnackbar,
							info: 'Goal must be in abbreviated format (ex. 1.5, not 1500000) and not be negative'
						}
					})
				}
				break
			case 'FinalGoal':
				if (parseInt(value).toString().length <= 3 && !value.toString().includes('-')) {
					errored = false
					agentPayload = { ...agentPayload, FinalGoal: String(value) }
				} else {
					errored = true
					props.dispatch({
						type: 'TOGGLE_SNACKBAR', payload: {
							type: 'error',
							showSnackbar: !props.state.snackbar.showSnackbar,
							info: 'Goal must be in abbreviated format (ex. 1.5, not 1500000) and not be negative'
						}
					})
				}
				break
			case 'Hide':
				errored = false
				agentPayload = { ...agentPayload, Hide: value }
				break
			default:
				break
		}
		if (agentPayload.Notes === null) agentPayload.Notes = " "
		if (agentPayload.FinalGoal === null) agentPayload.FinalGoal = "0"
		if (!errored) {
			props.updateAgent(agentPayload)
		}
	}

	const toggleModal = (params, event) => {
		if (officeName === 'All Offices' || !event.target.innerText) return
		const text = event.target.innerText.trim()
		const isCorrectRole = props.state.role !== Role.Executive
		const showAgentModal = text === params.row.AgentName.trim()
		const showGroupModal = isCorrectRole && params.row.Teams.find(agent => agent.TeamName.trim() === text)

		if (showAgentModal) {
			props.toggleAgentModal(params.row.EmployeeNum, text)
		} else if (showGroupModal) {
			const teamIdx = params.row.Teams.findIndex(team => team.TeamName.trim() === text)
			const isCareerDevTeam = params.row.Teams[teamIdx].isCareerDev
			const isSET = params.row.Teams[teamIdx].isSET
			let officeIdent
			let officeId
			if (props.state.role !== Role.Manager || props.state.role !== Role.CareerDevManager) {
				const officeIdx = props.data.findIndex(office => office.OfficeName === officeName)
				officeIdent = props.data[officeIdx].Ident
				officeId = props.data[officeIdx].id
			} else if (props.state.role === Role.Manager || props.state.role === Role.CareerDevManager) {
				officeIdent = props.data[0].Agents[0].Ident
				officeId = props.data[0].Agents[0].id
			}
			props.dispatch({
				type: 'TOGGLE_MODAL',
				payload: {
					modalType: isCareerDevTeam ? ModalType.EditCareerDevGroup : isSET ? ModalType.EditSET : ModalType.EditGroup,
					selectedEmployeeNum: params.row.EmployeeNum,
					selectedOfficeIdent: officeIdent,
					selectedTeamName: text,
					selectedTeamID: officeId
				}
			})
		}
	}

	const handleDropdownChange = event => {
		setOffice(event.currentTarget.dataset.value)
		handleOfficePreferences(event.currentTarget.dataset.ident)
		if (event.currentTarget.dataset.value !== "All Offices") {
			setColumns(TableViewType.Office)
			setFilterModel({
				items: [{ columnField: 'Hide', operatorValue: 'is', value: 'false' }],
			})
		} else {
			setColumns(TableViewType.Offices)
			setFilterModel({ items: [{ columnField: 'TotalSides', operatorValue: '>=', value: 0 }] })
		}
	}

	const determineHidden = (type, column) => {
		if (type === 'Office') {
			return !props.userSelections?.GridSettings?.Office?.HiddenColumns.includes(column)
		} else if (type === 'Agent') {
			return !props.userSelections?.GridSettings?.Agent?.HiddenColumns.includes(column)
		}
	}

	let visibleRows, mounted = false
	const handleTableStateChange = (state) => {
		if (!mounted) {
			state.visibleRows = gridVisibleSortedRowIdsSelector(state)
			mounted = true
		}
		if (state.visibleRows === visibleRows || state.visibleRows === undefined) return // check to prevent infinite loop
		visibleRows = state.visibleRows
		const renderOverview = (officeName === "All Offices" || !props.data[props.data.findIndex(office => office.OfficeName === officeName)]?.Ident)
		const Ident = renderOverview ? props.data[0]?.Ident : props.data[props.data.findIndex(office => office.OfficeName === officeName)]?.Ident
		props.dispatch({
			type: 'CALCULATE_DYNAMIC_TOTAL',
			payload: {
				idsToFilterFor: gridVisibleSortedRowIdsSelector(state),
				viewType: renderOverview ? TableViewType.Offices : TableViewType.Office,
				Ident
			}
		})
	}

	const view = (officeName === 'All Offices' ? 'office' : 'agent')

	return (
		<div className={classes.root}>
			<CustomDropdown handleDropdownChange={handleDropdownChange} data={props.data} officeName={officeName} />
			<DataGridPro
				autoHeight={dataAutoHeight}
				className={classes.dataGrid}
				maxHeight={true}
				disableColumnMenu={false}
				pageSize={pageSize}
				rowsPerPageOptions={[10,25,50,100]}
				onPageSizeChange={(newSize) => setPageSize(newSize)}
				pagination={true}
				rows={createRows(props.data, officeName, props.state)}
				columns={columns}
				components={{
					Toolbar: CustomToolbar,
					Footer: CustomFooter
				}}
				onRowClick={(params, event) => toggleModal(params, event)}
				onCellEditCommit={handleEdits}
				filterModel={filterModel}
				onFilterModelChange={(model) => handleGridPreferences(model, view, 'filter')}
				onStateChange={(state) => handleTableStateChange(state)}
				initialState={{ 
					pinnedColumns: { left: ['AgentName'] },
					columns: {
						columnVisibilityModel: {
							Hide: determineHidden('Agent', 'Hide'),
							EmployeeNum: determineHidden('Agent', 'EmployeeNum'),
							TeamLead: determineHidden('Agent', 'TeamLead') && (props.state.role === Role.CareerDevManager || props.state.role === Role.CareerDevEVP),
							IsTerminated: false,
							AffilDate: determineHidden('Agent', 'AffilDate'),
							AgentName: determineHidden('Agent', 'AgentName'),
							TeamName: determineHidden('Agent', 'TeamName'),
							TeamAddDate: determineHidden('Agent', 'TeamAddDate'),
							ATotalSides: determineHidden('Agent', 'ATotalSides'),
							OTotalSides: determineHidden('Office', 'OTotalSides'),
							Listings: determineHidden('Agent', 'Listings'),
							AVolume: determineHidden('Agent', 'AVolume'),
							OVolume: determineHidden('Office', 'OVolume'),
							AAvgSalePrice: determineHidden('Agent', 'AAvgSalePrice'),
							OAvgSalePrice: determineHidden('Office', 'OAvgSalePrice'),
							AgentGoal: determineHidden('Agent', 'AgentGoal'),
							FinalGoal: determineHidden('Agent', 'FinalGoal'),
							Notes: determineHidden('Agent', 'Notes'),
							OfficeName: determineHidden('Office', 'OfficeName'),
							AgentCount: determineHidden('Office', 'AgentCount'),
							AgentGoalTotal: determineHidden('Office', 'AgentGoalTotal'),
							FinalGoalTotal: determineHidden('Office', 'FinalGoalTotal')
						}
					}
				}}
				onColumnVisibilityModelChange={(model) => handleGridPreferences(model, view, 'column')}
				sortModel={sortModel}
				onSortModelChange={(model) => handleGridPreferences(model, view, 'sort')}
				componentsProps={{
					footer: {
						state: props.state,
						viewType: officeName === 'All Offices' ? TableViewType.Offices : TableViewType.Office,
						officeId: officeName === 'All Offices' || !props.data.find(doc => doc.OfficeName === officeName)?.Ident ? props.data[0]?.Ident : props.data.find(doc => doc.OfficeName === officeName)?.Ident
					},
					toolbar: {
						viewType: officeName === 'All Offices' ? TableViewType.Offices : TableViewType.Office,
						role: props.state.role,
						data: props.data,
						dispatch: props.dispatch,
						officeName: officeName,
						state: props.state
					}
				}}
			/>
		</div>
	)
}

function CustomDropdown({ data, officeName, handleDropdownChange }) {
	return (
		<FormGroup className="MuiFormGroup-options" row>
			<FormControl variant="standard">
				<InputLabel>Office</InputLabel>
				<Select value={officeName ? officeName : "All Offices"} onChange={handleDropdownChange}>
					<MenuItem data-ident='' value="All Offices">All Offices</MenuItem>
					{data?.map((office, index) => {
						return (<MenuItem key={index} data-ident={office.Ident} value={office.OfficeName}>{office.OfficeName}</MenuItem>)
					})}
				</Select>
			</FormControl>
		</FormGroup>
	)
}

/**
 * Creates rows to display on table based on user selection.
 * @param {*} data
 * @param {*} groupData
 * @param {*} selectedOffice
 * @returns {array}
 */
function createRows(data, selectedOffice, state) {
	if (selectedOffice === 'All Offices') {
		return data.map((office, index) => {
			const AvgHouseSalePrice = (office.Volume > 0 && office.TotalSides > 0) ? office.Volume / office.TotalSides : 0
			const finalAgentCount = office.Agents.filter(doc => !doc.Hide)
			return {
				OfficeName: office.OfficeName,
				OVolume: office.Volume / 1000000,
				OTotalSides: Math.round(office.TotalSides),
				OAvgSalePrice: Math.round(AvgHouseSalePrice),
				AgentCount: finalAgentCount.length,
				id: index,
				FinalGoalTotal: office.FinalGoalTotal,
				AgentGoalTotal: office.AgentGoalTotal
			}
		})
	} else {
		return data.filter(office => office.OfficeName === selectedOffice).map(office => office.Agents.map((agent, index) => {
			const AvgSalePrice = (agent.Volume > 0 && agent.TotalSides > 0) ? agent.Volume / agent.TotalSides : 0
			const Teams = []
			if (state.role === Role.CareerDevManager) {
				Teams.push(...agent.Teams.filter(team => team.isCareerDev))
			} else if (state.role === Role.Manager || state.role === Role.EVP) {
				Teams.push(...agent.Teams.filter(team => !team.isCareerDev && !team.isSET))
			} else if (state.role === Role.CareerDevEVP) {
				Teams.push(...agent.Teams.filter(team => team.isCareerDev))
			} else {
				// adds both career dev and non-career dev teams (for Executives and Super Admins)
				Teams.push(...agent.Teams)
			}
			return {
				AffilDate: agent.AffilDate,
				AgentGoal: agent.AgentGoal,
				AgentName: agent.AgentName,
				EmployeeNum: agent.EmployeeNum,
				FinalGoal: agent.FinalGoal,
				Teams: Teams.filter(team => team.TeamName),
				Hide: agent.Hide,
				Listings: agent.Listings,
				Notes: agent.Notes,
				ATotalSides: Math.round(agent.TotalSides),
				AVolume: agent.Volume / 1000000,
				id: index,
				AAvgSalePrice: Math.round(AvgSalePrice),
				IsTerminated: agent.IsTerminated
			}
		})).flat()
	}
}
